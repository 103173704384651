import * as React from "react";
import { observer } from "mobx-react";
import * as storesMap from "stores";
import UploadStore from "stores/UploadStore";

const StoreContext = React.createContext();
StoreContext.displayName = "MobXStore";

export const StoreProvider = ({ children }) => {
  return (
    <StoreContext.Provider
      value={{ ...storesMap, uploadStore: new UploadStore() }}
    >
      {children}
    </StoreContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useStores = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (WrappedComponent) => {
  return React.forwardRef((props, ref) => {
    const stores = useStores();
    const Component = observer(WrappedComponent);
    return <Component ref={ref} {...props} {...stores} />;
  });
};
