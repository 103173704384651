import { IntegrationType } from "types/integration";
import {
  csv2Icon,
  ftpIcon,
  mariaDbIcon,
  microsoftD365Icon,
  mssqlIcon,
  mysqlIcon,
  postgresqlIcon,
  pythonIcon,
  quickbooksIcon,
  sageIcon,
  salesforceIcon,
  webhooksIcon,
  xeroIcon,
  yardiIcon,
} from "imageUrls";

export type IntegrationInfo = {
  type: IntegrationType;
  displayName: string;
  icon: string;
  dependenciesExist: boolean;
  allowedActions: string[];
  uploadMessage?: string;
};
export const SUPPORTED_INTEGRATIONS: Record<IntegrationType, IntegrationInfo> =
  {
    csv: {
      type: "csv",
      uploadMessage: "Upload a .csv file",
      displayName: "Csv file",
      icon: csv2Icon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    sql: {
      type: "sql",
      displayName: "MySql",
      icon: mysqlIcon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    mssql: {
      type: "mssql",
      displayName: "Mssql",
      icon: mssqlIcon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    mariadb: {
      type: "mariadb",
      displayName: "MariaDB",
      icon: mariaDbIcon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    postgresql: {
      type: "postgresql",
      displayName: "Postgresql",
      icon: postgresqlIcon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    salesforce: {
      type: "salesforce",
      displayName: "Salesforce",
      icon: salesforceIcon,
      dependenciesExist: true,
      allowedActions: ["upsert"],
    },
    quickbooks: {
      type: "quickbooks",
      displayName: "Quickbooks",
      icon: quickbooksIcon,
      dependenciesExist: true,
      allowedActions: ["insert", "upsert", "insert_if_not_exists"],
    },
    ftp: {
      type: "ftp",
      displayName: "FTP",
      icon: ftpIcon,
      dependenciesExist: true,
      allowedActions: ["send data"],
    },
    sage: {
      type: "sage",
      displayName: "Sage",
      icon: sageIcon,
      dependenciesExist: true,
      allowedActions: ["insert", "upsert", "insert_if_not_exists"],
    },
    webhooks: {
      type: "webhooks",
      displayName: "Webhooks",
      icon: webhooksIcon,
      dependenciesExist: false,
      allowedActions: ["send data"],
    },
    yardi: {
      type: "yardi",
      displayName: "Yardi",
      icon: yardiIcon,
      dependenciesExist: false,
      allowedActions: ["send data"],
    },
    python: {
      type: "python",
      displayName: "Python",
      icon: pythonIcon,
      dependenciesExist: false,
      allowedActions: ["send data"],
    },
    xero: {
      type: "xero",
      displayName: "Xero",
      icon: xeroIcon,
      dependenciesExist: false,
      allowedActions: ["send data"],
    },
    microsoft_d365: {
      type: "microsoft_d365",
      displayName: "Microsoft D365 Business Central",
      icon: microsoftD365Icon,
      dependenciesExist: false,
      allowedActions: ["upsert"],
    },
  };

const DATABASE_INTEGRATIONS_TYPES: IntegrationType[] = [
  "csv",
  "sql",
  "mssql",
  "mariadb",
  "postgresql",
  "ftp",
];

export const DATABASE_INTEGRATIONS_LIST = DATABASE_INTEGRATIONS_TYPES.map(
  (i) => SUPPORTED_INTEGRATIONS[i]
);
