import { useQuery, useMutation } from "react-query";
import queryClient from "queryClient";
import { toast } from "Utils/toast";

type TeamMember = {
  email: string;
  status: string;
  permission_level: string;
  in_personal_team: string;
  name: string;
  alias_of: string;
  invite_link: string;
};

type Team = {
  owner_email: string;
  currently_using: boolean;
  status: string;
  name: string;
};

export const useTeam = () => {
  return useQuery<TeamMember[]>(["teamMembers"], () =>
    fetch("/api/v2/team/members").then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error("Error while fetching team members"))
    )
  );
};

export const useUserTeams = () => {
  return useQuery<Team[]>(["teams"], () =>
    fetch("/api/v2/team/user-teams").then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error("Error while fetching teams of user"))
    )
  );
};

export const useSwitchTeam = () => {
  return useMutation<any, ErrorEvent, any>(
    () => {
      return fetch(`/api/v2/team/switch`, {
        credentials: "include",
        method: "POST",
      }).then((res) => {
        return res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to switch team"));
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["teams"]);
        toast.success("Switched team, redirecting to user's models");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useResendInvite = () => {
  return useMutation<unknown, ErrorEvent, TeamMember>(
    (member) => {
      return fetch(`/api/v2/team/resend-invite`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(member),
      }).then((res) => {
        return res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to switch team"));
      });
    },
    {
      onSuccess: () => {
        toast.success("Resent invite!");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useAcceptTeamInvite = () => {
  return useMutation<any, ErrorEvent, any>(
    () => {
      return fetch("/api/v2/team/accept-invite", {
        credentials: "include",
        method: "GET",
      }).then((res) => {
        return res.ok
          ? Promise.resolve()
          : Promise.reject(new Error("Failed to accept team invite"));
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["teams"]);
        toast.success("Accepted team invite!");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useInviteMember = () => {
  return useMutation<unknown, ErrorEvent, string>(
    (memberName) => {
      return fetch(`/api/v2/team/members`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ email: memberName }),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to invite member");
        }

        return data;
      });
    },
    {
      onSuccess: () => {
        toast.success("Successfully sent invite!");
        queryClient.invalidateQueries(["teamMembers"]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useUpdateMember = () => {
  const { data: members = [] } = useTeam();
  return useMutation<
    unknown,
    ErrorEvent,
    { email: string; permissionLevel: TeamMember["permission_level"] }
  >(
    ({ email, permissionLevel }) => {
      let updatedMembers = [...members];
      let originalPermissionLevel = "";
      let memberIndex = -1;
      for (var index in members) {
        if (members[index].email === email) {
          originalPermissionLevel = updatedMembers[index].permission_level;
          updatedMembers[index].permission_level = permissionLevel;
          memberIndex = Number(index);
          break;
        }
      }
      return fetch(`/api/v2/team/members`, {
        credentials: "include",
        method: "PATCH",
        body: JSON.stringify({
          email: email,
          permission_level: permissionLevel,
        }),
      }).then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          // resetting the permission back to original value in case of failure
          if (memberIndex >= 0) {
            updatedMembers[memberIndex].permission_level =
              originalPermissionLevel;
          }
        }

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to update member");
        }

        return data;
      });
    },
    {
      onSuccess: () => {
        toast.success("Role Updated!");
        queryClient.invalidateQueries(["teamMembers"]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteMember = () => {
  return useMutation<unknown, ErrorEvent, string>(
    (email) => {
      return fetch(`/api/v2/team/members`, {
        credentials: "include",
        method: "DELETE",
        body: JSON.stringify({ email: email }),
      }).then(async (res) => {
        if (res.ok) return res.statusText;
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to delete member");
        }

        return data;
      });
    },
    {
      onSuccess: () => {
        toast.success("Successfully deleted member!");
        queryClient.invalidateQueries(["teamMembers"]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};
