import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import intercom from "Utils/intercom";
import { useStores } from "hooks/use-stores";
import { useLocation } from "react-router-dom";

const Intercom = observer(() => {
  const { userStore } = useStores();
  const location = useLocation();

  useEffect(() => {
    intercom.hide();
  }, [location]);

  useEffect(() => {
    intercom.shutdown();
    intercom.update(userStore.profile.UserProfile);
  }, [userStore.profile.UserProfile]);
});

export default Intercom;
