export const ui_config: Record<
  string,
  {
    isCustomExport?: boolean;
    menuList?: boolean;
    csvFormat?: string;
  }
> = {
  "d1645b5d-c43b-4e61-80f4-494bd7908ef3": {
    menuList: true,
  },
  "0b40a2da-6a4a-44d7-b0ee-f3b68daf0b96": {
    menuList: true,
  },
  "4d4f9d88-01d3-4058-98eb-6cb74b711f96": {
    menuList: true,
  },
  "1c362bbd-61db-4aa5-994a-caad6cb75021": {
    menuList: true,
  },
  "011f6497-f355-47ab-a4fc-01469b82d096": {
    menuList: true,
  },
  "9dcb0468-7183-49eb-856e-26549580b8c4": {
    menuList: true,
    csvFormat: "csv2", //dineout
  },
  "ea5b69a5-b914-4e71-98b9-7b13b4823fbd": {
    menuList: true,
  },
  "07bfb30d-db28-47c6-9ea4-f263528b41a6": {
    menuList: true,
  },
  "e9d82871-297d-4f67-bcd5-1be6aae2887b": {
    menuList: true,
  },
  "0f209555-e9bf-428e-95a5-3c6614397b79": {
    menuList: true,
    csvFormat: "round_app",
  },
  "cac15a87-da00-4ac8-8d83-0b84032ef5c8": {
    menuList: true,
  },
  "d7dc12aa-f0e8-4baf-8953-65cc06e2cd06": {
    menuList: true,
  },
  "6ae05789-3600-4d33-8532-79ea2bb8ba2e": {
    menuList: true,
  },
  "c86172c7-e2a0-4e05-b54c-8a6551913d4d": {
    menuList: false,
    csvFormat: "coello",
    isCustomExport: true,
  },
  "ff2bbc35-46cc-49f2-b100-d91107145fb8": {
    isCustomExport: true,
    csvFormat: "247recon",
  },
  "740d28ef-262d-4d42-92a4-104f8910dfed": {
    isCustomExport: true,
    csvFormat: "acmservices",
  },
  "20754e73-77c3-40fc-9d3e-2a4829a39e91": {
    menuList: true,
  },
  "df907696-6dbd-4457-95ec-dbfb0dc9f261": {
    menuList: true,
  },
  "05c5e439-1f8f-4a6a-8ec3-9622b5164ea4": {
    menuList: true,
  },
  "330eee13-36b9-427a-8d66-4fdf53ed88e4": {
    isCustomExport: true,
    csvFormat: "clr",
  },
  "0e6601cc-d4ca-4a5e-b5d1-884d8ee1b79d": {
    isCustomExport: true,
    csvFormat: "linengineering",
  },
  "7d988909-fd3c-4478-aa31-dae855d9b7df": {
    menuList: true,
    isCustomExport: true,
    csvFormat: "chewzie",
  },
  "2719de02-95e1-4b19-8a05-75b7343e0f89": {
    isCustomExport: true,
    csvFormat: "247recon_old",
  },
  "8c0eb63c-0d51-4d5e-82f6-cf960cd292a6": {
    isCustomExport: true,
    csvFormat: "247recon_new",
  },
  "2438baf9-1027-40a8-b9cd-50382a7c15d6": {
    isCustomExport: true,
    csvFormat: "comfone",
  },
  "70fd7f26-f55b-46f0-bbc4-1f3d80428f45": {
    isCustomExport: true,
    csvFormat: "civerasoftware",
  },
  "66fd19da-ece5-4d6b-943b-b451131d22c2": {
    isCustomExport: true,
    csvFormat: "civera2",
  },
  "913d8218-f7aa-4c01-a266-045239da023f": {
    isCustomExport: true,
    csvFormat: "civerasoftware",
  },
  "c013aeb2-70d9-4f00-89ae-3b71e83d6825": {
    menuList: true,
  },
  "2da4c31b-4eb1-4160-ad8d-3f4d167a7deb": {
    isCustomExport: true,
    csvFormat: "autopoint",
  },
  "ec85106d-f870-4059-845f-0cb39e8c21b0": {
    isCustomExport: true,
    csvFormat: "craftitfl",
  },
  "4ea240fe-a8fe-4688-ad97-f48e2c914071": {
    isCustomExport: true,
    csvFormat: "mainspring_psa_po",
  },
  "96348c8e-a42f-45dc-a0fb-e66dad5b1301": {
    isCustomExport: true,
    csvFormat: "mainspring_bmw_po",
  },
  "3b77ecc3-38db-406f-a05e-432a284c4997": {
    isCustomExport: true,
    csvFormat: "mainspring_quote",
  },
  "61150956-31b7-4cde-b3fc-5e8d15a9dfb4": {
    isCustomExport: true,
    csvFormat: "mainspring_quote",
  },
  "a9a37fc3-571c-40d9-9bf1-4a34594e6bfb": {
    menuList: true,
  },
  "0bc8c5f1-cf1f-4d56-af41-ff4af3b738ad": {
    menuList: true,
  },
  "04a39217-2473-4940-a476-8f0cc74fe686": {
    menuList: true,
  },
  "bb64421e-3a4f-4013-ac9f-bcefa389ee69": {
    isCustomExport: true,
    csvFormat: "wtrm",
  },
  "af230298-5172-4550-b08c-e1ebc2f1e4b0": {
    isCustomExport: true,
    csvFormat: "maridian",
  },
  "23af6b22-b71f-4860-9dda-758b8bc003d5": {
    isCustomExport: true,
    csvFormat: "capdesk",
  },
  "656497eb-bd9e-4461-ac2c-e8cfae89c30b": {
    isCustomExport: true,
    csvFormat: "totalcsr",
  },
  "471ad49e-fcea-47af-8f6a-4aeb71471caf": {
    isCustomExport: true,
    csvFormat: "zionmarket",
  },
  "dd502902-d208-4fe9-a3ea-bd0c833c0465": {
    isCustomExport: true,
    csvFormat: "dhani",
  },
  "8038dc22-e7b1-49ce-9e14-6c6bb8c69b04": {
    isCustomExport: true,
    csvFormat: "grover",
  },
  "26652666-868b-4b83-87a2-708aebbca203": {
    isCustomExport: true,
    csvFormat: "kredx",
  },
  "82206eae-f6c4-4a39-8665-b929b207f150": {
    isCustomExport: true,
    csvFormat: "dillon",
  },
  "13f944c8-e624-46a6-bf84-b6c6f5d8a274": {
    menuList: true,
  },
  "78b8db01-d2e3-4820-a819-e57193d858e2": {
    isCustomExport: true,
    csvFormat: "mytcconcierge",
  },
  "5c2857c0-ad8f-49bc-969d-4e77bb5c69ac": {
    isCustomExport: true,
    csvFormat: "verticalmade",
  },
  "a202fb44-1157-477b-acc8-42b128c8ea18": {
    isCustomExport: true,
    csvFormat: "cox",
  },
  "b3812373-6799-4926-b1a1-961ac259a80e": {
    isCustomExport: true,
    csvFormat: "hbp",
  },
  "157e8fe9-4684-4c15-8d48-d7a104a5cfdd": {
    isCustomExport: true,
    csvFormat: "jmmb2",
  },
  "4b915137-7c24-4cf0-a23d-e5650a8896b7": {
    isCustomExport: true,
    csvFormat: "landing",
  },
  "223994d2-5c30-4432-8ed5-9a4f13706954": {
    isCustomExport: true,
    csvFormat: "dowchemicals",
  },
  "ec0920e3-9e88-4bc6-aecf-ad692f19af00": {
    isCustomExport: true,
    csvFormat: "sterlingmanagement",
  },
  "6bb140d5-e095-470f-887b-f832ff65a374": {
    isCustomExport: true,
    csvFormat: "msicredit",
  },
  "6e568d68-1858-41a8-b8f8-6fd3080c48ae": {
    isCustomExport: true,
    csvFormat: "fleximize",
  },
  "f988a9bb-fe5d-493e-9b9e-4c7a7a422bb3":{
    "isCustomExport": true,
    csvFormat: "stenn",
  }
};

export const pretrainedModelTypes = {
  pretrained_receipts: "Receipts",
  "purchase-order": "Purchase Orders",
  pretrained_table: "Tables",
  pretrained_invoice: "Invoices",
  pretrained_passport: "Passports",
  pretrained_license: "Driving License",
};
