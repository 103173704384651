import { captureException } from "Utils/logger";

const APP_ID = "nwkp4rux";
window.intercomSettings = {
  app_id: APP_ID,
};

function init() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.intercom.io/widget/" + APP_ID;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
}

async function update(profile) {
  if (!profile?.sub) return;

  await fetch(
    `https://customerstaging.nanonets.com/dashboard/intercom-hmac?user_id=${profile.sub}`
  )
    .then((resp) =>
      resp.ok
        ? resp.json()
        : Promise.reject(new Error("Failed to fetch intercom profile"))
    )
    .then((data) => {
      if (data.hmac_hash) {
        window.Intercom("update", {
          email: profile.email,
          name: profile.name,
          user_id: profile.sub,
          user_hash: data.hmac_hash,
        });
      } else {
        shutdown();
      }
    })
    .catch(captureException);

  fetch(
    `https://customerstaging.nanonets.com/dashboard/intercomapi/notes?user_id=${profile.sub}`,
    {
      method: "get",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then((response) =>
      response.ok
        ? response.json()
        : Promise.reject(new Error("Failed to fetch intercom notes"))
    )
    .then((data) => {
      try {
        let match = data.notes.some((a) => a.body === "<p>Verified</p>");
        if (!match) addNote(profile);
      } catch (error) {}
    })
    .catch(console.error);
}

function shutdown() {
  window.Intercom("shutdown");
}

function hide() {
  window.Intercom("hide");
}

function addNote(profile) {
  fetch("https://customerstaging.nanonets.com/dashboard/intercomapi/notes", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      user_id: profile.sub,
    }),
  }).catch(() => {
    console.error("failed to add verified note");
  });
}

const intercom = {
  init,
  update,
  shutdown,
  hide,
};

export default intercom;
