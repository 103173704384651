import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { trackPage } from "Utils/analytics";
import { useHistory, useLocation } from "react-router-dom";
import { useStores } from "hooks/use-stores";
import { useUser } from "hooks/user";

const Init = () => {
  const [sentIdentifyEvent, setSentIdentifyEvent] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { data: user } = useUser();

  const { userStore } = useStores();
  const { isAdminUserAliasedAsOtherUser, setUser } = userStore;

  useEffect(() => {
    userStore.setGlobalLoader(false);
  }, [location, userStore]);

  useEffect(() => {
    setUser(user ?? {});
  }, [setUser, user]);

  useEffect(() => {
    if (!user) return;
    if (isAdminUserAliasedAsOtherUser) return;

    // if this condition is true then we've already sent the identify event and dont want to send it again
    if (sentIdentifyEvent) return;

    const email = user.EmailId;
    const data = { name: user.UserProfile.name, email };

    if (window.smartlook) {
      window.smartlook("identify", email, data);
    }
    if (window.posthog) {
      window.posthog.identify(email);
      window.posthog.people.set({ email });
    }
    if (window.analytics) {
      window.analytics.identify(email, { email });
    }

    setSentIdentifyEvent(true);
  }, [sentIdentifyEvent, user, isAdminUserAliasedAsOtherUser]);

  useEffect(() => {
    const landingPageUrl = sessionStorage.getItem("landingPageUrl");
    sessionStorage.removeItem("landingPageUrl");

    if (landingPageUrl && location.pathname === "/") {
      history.push(landingPageUrl);
    }

    window.localStorage.removeItem("pricingRedirectionHash");

    // classify in hash was renamed to test
    if (window.location.href.indexOf("classify") > -1) {
      window.location.href = window.location.href.replace("classify", "test");
    }
  }, [history, location.pathname]);

  useEffect(() => {
    trackPage();
  }, []);

  return null;
};

export default observer(Init);
