import { IconButton } from "@material-ui/core";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useIsAdmin } from "hooks/user";
import { useModel } from "hooks/model";
import { useRouteMatch } from "react-router-dom";
import { SearchParams } from "types/misc";

const ModelOwner = () => {
  const isAdmin = useIsAdmin();
  const match = useRouteMatch<SearchParams>(
    "/:modelType(ic|mlc|od|ocr|similarity|custom)/:page/:modelId"
  );
  const modelId = match?.params.modelId || "";
  const { data: model } = useModel(modelId);

  return isAdmin && model?.email ? (
    <div
      style={{
        position: "fixed",
        top: 4,
        right: 8,
        background: "#F4F4F4",
        zIndex: 10,
        borderRadius: 24,
        paddingLeft: 12,
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: "#66788C",
          fontSize: 12,
        }}
      >
        {model.email}
      </span>
      <IconButton
        style={{
          pointerEvents: "auto",
        }}
        onClick={() => {
          copy(model.email);
        }}
      >
        <FileCopyIcon style={{ fontSize: 16 }} />
      </IconButton>
    </div>
  ) : null;
};

export default ModelOwner;
