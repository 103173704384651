import { useState, useEffect } from "react";
import { matchPath } from "react-router-dom";
import { Link, NavLink, useLocation, useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import {
  AccordionDetails,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  ListItemIcon,
  Radio,
  TextField,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import {
  AddCircleOutline as AddIcon,
  ExitToApp as ExitIcon,
  VpnKey as KeyIcon,
  HelpOutline as HelpIcon,
  ImportContactsOutlined,
  Explore,
  KeyboardArrowRight,
  Payment,
  Group,
  AccountCircleOutlined as AccountCircleIcon,
  VerifiedUser as VerifiedUserIcon,
  ContactSupport,
  Home,
  KeyboardArrowDown,
  DoubleArrow,
  WidgetsOutlined,
  VpnLock,
  Notifications,
  NotificationsNone,
  Close,
  ReportProblemOutlined,
  Delete,
  Mail,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons";

import ModelNav from "components/Navigation/ModelNav";
import ErrorBoundary from "components/ErrorBoundary";
import { hasAccess, hasSectionEditAccess } from "Utils/access";
import { useUrlSearchParams } from "hooks/misc";
import { useModelType } from "hooks/model";
import { useIsAdmin, useUser, useDeleteAccount } from "hooks/user";
import { useUserTeams, useSwitchTeam, useAcceptTeamInvite } from "hooks/team";
import { useStores } from "hooks/use-stores";
import { observer } from "mobx-react-lite";
import { personalTeamIcon, otherTeamIcon } from "imageUrls";
import SwitchTeamDialog from "../SwitchTeamDialog";

const Accordion = withStyles({
  root: {
    background: 0,
    boxShadow: "none",
    margin: 0,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
    minHeight: 0,
    display: "block",
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const CloseButton = withStyles({
  root: {
    position: "absolute",
    top: 4,
    right: 4,
  },
})(IconButton);

const myAccountRoutes = [
  "/admin",
  "/notification-settings",
  "/keys",
  "/billing",
  "/team",
];

const accrdionNames = {
  activeModel: "activeModel",
  myAccount: "myAccount",
  team: "team",
};

const AppNav = ({ classes }) => {
  const [expandedAccordion, setExpandedAccordion] = useState("");
  const [openSwitchTeam, setOpenSwitchTeam] = useState(false);
  const [teamWithPendingInvite, setTeamWithPendingInvite] = useState(null);
  const user = useUser();
  const originalEmailId = user.data?.UserProfile?.is_user_an_alias
    ? user.data.UserProfile.original_email
    : user.data.EmailId;
  const isAdmin = useIsAdmin();
  const location = useLocation();
  const deleteAccount = useDeleteAccount();
  const searchParams = useUrlSearchParams();
  const routeMatch1 = useRouteMatch("/models/:modelId");
  const routeMatch2 = useRouteMatch(
    "/(ic|mlc|od|ocr|similarity|custom)/:page/:modelId"
  );
  const modelId =
    searchParams.modelId ||
    routeMatch1?.params.modelId ||
    routeMatch2?.params.modelId;
  const modelType = useModelType(modelId);
  const { appStore } = useStores();

  const { data: teams = [] } = useUserTeams();
  const activeTeams = teams.filter((t) => t.status !== "pending");
  const currentlyUsedTeam = teams.find((t) => t.currently_using) || {};
  const [selectedTeam, setSelectedTeam] = useState(
    currentlyUsedTeam?.owner_email
  );
  const { mutate: switchTeam, isLoading: switchingTeam } = useSwitchTeam();
  const {
    mutate: acceptInvite,
    isLoading: acceptingInvite,
    isSuccess: acceptedInvite,
  } = useAcceptTeamInvite();

  const showNewTeamInviteBanner = teamWithPendingInvite && !acceptedInvite;

  const isDrawerExpanded = appStore.isDrawerExpanded;
  const handleChange = (id) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? id : "");
  };

  const [isAccountDeleteDialogOpen, setIsAccountDeleteDialogOpen] =
    useState(false);

  const openAccountDeleteDialog = () => {
    setIsAccountDeleteDialogOpen(true);
  };

  const closeAccountDeleteDialog = () => {
    setIsAccountDeleteDialogOpen(false);
  };

  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");

  useEffect(() => {
    setSelectedTeam(currentlyUsedTeam?.owner_email);
    const teamsWithPendingInvite = teams?.filter((t) => t.status === "pending");
    if (teamsWithPendingInvite?.length > 0) {
      setTeamWithPendingInvite(teamsWithPendingInvite[0]);
      setExpandedAccordion(accrdionNames.team);
      appStore.openDrawer();
    }
  }, [teams, currentlyUsedTeam?.owner_email, appStore]);

  useEffect(() => {
    var HW_config = {
      selector: "#changelog", // CSS selector where to inject the badge
      account: "J4Kdk7",
      trigger: "#changelog-trigger",
    };

    if (window !== undefined && window.Headway) {
      window.Headway.init(HW_config);
    }
  });

  useEffect(() => {
    if (modelType) {
      setExpandedAccordion(accrdionNames.activeModel);
    }
  }, [modelType]);

  useEffect(() => {
    const match = myAccountRoutes.some((route) =>
      matchPath(location.pathname, route)
    );

    if (match) {
      setExpandedAccordion(accrdionNames.myAccount);
    }
  }, [location]);

  const logout = () => {
    fetch("/logout", {
      method: "post",
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/";
        } else {
          this.setState({
            error: { type: "common", message: "Error while logging out" },
          });
        }
        return response.text();
      })
      .catch((error) => {
        this.setState({
          error: { type: "common", message: "Error connecting to server." },
        });
        this.setState({ progressbarvalue: 0 });
      })
      .finally(() => {
        document.cookie =
          "auth-session=;Path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        window.location.href = "/";
      });
  };

  const handleAccountDelete = () => {
    if (deleteConfirmationText === "DELETE ALL MY DATA") {
      deleteAccount.mutate(
        {},
        {
          onSuccess: () => {
            closeAccountDeleteDialog();
            logout();
          },
        }
      );
    }
  };

  const toggleDrawer = () => {
    appStore.toggleDrawer();
  };

  const closeSwitchTeamDialog = () => {
    setOpenSwitchTeam(false);
  };

  const acceptNewTeamInvite = () => {
    acceptInvite();
    setOpenSwitchTeam(true);
  };

  const switchTeamAccount = () => {
    setOpenSwitchTeam(false);
    switchTeam(
      {},
      {
        onSuccess: () => {
          window.location.href = "/";
        },
      }
    );
  };

  return (
    <div
      className={clsx(
        classes.drawer,
        !isDrawerExpanded && classes.drawerCollapsed
      )}
    >
      <div style={{ padding: 20 }}>
        <Link to="/" className={classes.navLogo}>
          <img
            src={process.env.PUBLIC_URL + "/assets/logos/nanonets-logo.png"}
            alt=""
            height="24"
          />
          <span
            className={classes.navLogoText}
            style={{
              ...(isDrawerExpanded ? {} : { display: "none" }),
            }}
          >
            Nanonets
          </span>
        </Link>
      </div>

      <div
        style={{
          flexGrow: 1,
          overflowX: "hidden",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div>
          <NavLink
            id="to:models"
            to="/models"
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
          >
            <div className={classes.listItem}>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <Home fontSize="inherit" />
              </ListItemIcon>

              <span className={classes.listItemText}>My Models</span>
            </div>
          </NavLink>

          <NavLink
            id="to:newModel"
            to="/"
            exact
            className={classes.navLink}
            activeClassName={classes.activeNavLink}
          >
            <div className={classes.listItem}>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <AddIcon fontSize="inherit" />
              </ListItemIcon>

              <span className={classes.listItemText}>New Model</span>
            </div>
          </NavLink>

          {user.data?.IsIntegrationsFeatureAllowed &&
            hasSectionEditAccess("Integrations") && (
              <NavLink
                id="to:integrations"
                to="/integrations"
                className={classes.navLink}
                activeClassName={classes.activeNavLink}
              >
                <div className={classes.listItem}>
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <WidgetsOutlined fontSize="inherit" />
                  </ListItemIcon>

                  <span className={classes.listItemText}>
                    External Integrations
                  </span>
                </div>
              </NavLink>
            )}
        </div>

        {modelType && (
          <Accordion
            expanded={expandedAccordion === accrdionNames.activeModel}
            onChange={handleChange(accrdionNames.activeModel)}
          >
            <AccordionSummary>
              <div id="activeModel" className={classes.navLink}>
                <div className={classes.listItem}>
                  <div className={classes.expandIndicator}>
                    {expandedAccordion === accrdionNames.activeModel ? (
                      <KeyboardArrowDown style={{ fontSize: 16 }} />
                    ) : (
                      <KeyboardArrowRight style={{ fontSize: 16 }} />
                    )}
                  </div>

                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Explore fontSize="inherit" />
                  </ListItemIcon>

                  <span className={classes.listItemText}>Active Model</span>
                </div>
              </div>
            </AccordionSummary>
            {isDrawerExpanded && (
              <AccordionDetails>
                <div style={{ paddingLeft: 24, flex: 1 }}>
                  <ErrorBoundary>
                    <ModelNav modelId={modelId} />
                  </ErrorBoundary>
                </div>
              </AccordionDetails>
            )}
          </Accordion>
        )}

        <div style={{ flex: 1 }}></div>

        {hasAccess("whats_new") && (
          <div className={classes.navLink} style={{ cursor: "pointer" }}>
            <div
              id="changelog-trigger"
              className={classes.listItem}
              style={{ overflow: "hidden" }}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <Notifications fontSize="inherit" />
              </ListItemIcon>

              <span className={classes.listItemText}>What's new</span>

              <div
                style={{ position: "absolute", top: -6, left: 8 }}
                id="changelog"
              />
            </div>
          </div>
        )}

        <a
          id="to:appDocumentation"
          href="https://app.nanonets.com/documentation"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          <div className={classes.listItem}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <ImportContactsOutlined fontSize="inherit" />
            </ListItemIcon>

            <span className={classes.listItemText}>Documentation</span>
          </div>
        </a>

        {hasAccess("support_requests") && (
          <NavLink
            id="to:supportRequests"
            to="/support-requests"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            <div className={classes.listItem}>
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <ContactSupport fontSize="inherit" />
              </ListItemIcon>

              <span className={classes.listItemText}>Support Requests</span>
            </div>
          </NavLink>
        )}

        <a
          id="to:support"
          href="https://support.nanonets.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.navLink}
        >
          <div className={classes.listItem}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <HelpIcon fontSize="inherit" />
            </ListItemIcon>

            <span className={classes.listItemText}>Help</span>
          </div>
        </a>

        <Accordion
          expanded={expandedAccordion === accrdionNames.myAccount}
          onChange={handleChange(accrdionNames.myAccount)}
        >
          <AccordionSummary>
            <div id="myAccount" className={classes.navLink}>
              <div className={classes.listItem}>
                <div className={classes.expandIndicator}>
                  {expandedAccordion === accrdionNames.myAccount ? (
                    <KeyboardArrowDown style={{ fontSize: 16 }} />
                  ) : (
                    <KeyboardArrowRight style={{ fontSize: 16 }} />
                  )}
                </div>

                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <AccountCircleIcon fontSize="inherit" />
                </ListItemIcon>

                <span className={classes.listItemText}>Account info</span>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              ...(isDrawerExpanded ? {} : { paddingLeft: 0, paddingRight: 0 }),
            }}
          >
            <div
              style={{
                flex: 1,
                ...(isDrawerExpanded
                  ? { paddingLeft: 16 }
                  : { paddingLeft: 0 }),
              }}
            >
              <div className={clsx(classes.listItem, classes.email)}>
                <Tooltip
                  title={
                    user.data?.UserProfile?.email.length > 20
                      ? user.data?.UserProfile?.email
                      : ""
                  }
                  placement="bottom-start"
                >
                  <span
                    className={classes.listItemText}
                    style={{ opacity: 0.5 }}
                  >
                    {originalEmailId.substring(0, 20)}
                    {originalEmailId.length > 20 ? "..." : ""}
                  </span>
                </Tooltip>
              </div>

              {isAdmin && (
                <NavLink
                  id="to:admin"
                  to="/admin"
                  exact
                  className={classes.navLink}
                  activeClassName={classes.activeNavLink}
                >
                  <div className={classes.listItem}>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <VerifiedUserIcon fontSize="inherit" />
                    </ListItemIcon>

                    <span className={classes.listItemText}>Admin</span>
                  </div>
                </NavLink>
              )}

              {user.data?.FeatureFlags?.file_assignment_emails && (
                <NavLink
                  id="to:notification-settings"
                  to="/notification-settings"
                  className={classes.navLink}
                  activeClassName={classes.activeNavLink}
                >
                  <div className={classes.listItem}>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <NotificationsNone fontSize="inherit" />
                    </ListItemIcon>

                    <span className={classes.listItemText}>Notifications</span>
                  </div>
                </NavLink>
              )}

              <NavLink
                id="to:keys"
                to="/keys"
                className={classes.navLink}
                activeClassName={classes.activeNavLink}
              >
                <div className={classes.listItem}>
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <KeyIcon fontSize="inherit" />
                  </ListItemIcon>

                  <span className={classes.listItemText}>API Keys</span>
                </div>
              </NavLink>

              {hasAccess("my_account.billing") && (
                <NavLink
                  id="to:billing"
                  to="/billing"
                  className={classes.navLink}
                  activeClassName={classes.activeNavLink}
                >
                  <div className={classes.listItem}>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <Payment fontSize="inherit" />
                    </ListItemIcon>

                    <span className={classes.listItemText}>Billing</span>
                  </div>
                </NavLink>
              )}

              {hasAccess("my_account.team") &&
                user.data?.PricingFeatureAllowed && (
                  <NavLink
                    id="to:team"
                    to="/team"
                    className={classes.navLink}
                    activeClassName={classes.activeNavLink}
                  >
                    <div className={classes.listItem}>
                      <ListItemIcon classes={{ root: classes.listItemIcon }}>
                        <Group fontSize="inherit" />
                      </ListItemIcon>

                      <span className={classes.listItemText}>Team</span>
                    </div>
                  </NavLink>
                )}
              {hasAccess("my_account.whitelabel") && (
                <NavLink
                  id="to:whitelabelDomain"
                  to="/whitelabel-domain"
                  className={classes.navLink}
                  activeClassName={classes.activeNavLink}
                >
                  <div className={classes.listItem}>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <VpnLock fontSize="inherit" />
                    </ListItemIcon>

                    <span className={classes.listItemText}>
                      Whitelabel Domain
                    </span>
                  </div>
                </NavLink>
              )}

              {!hasAccess("auth.auto_login") && (
                <div className={classes.navLink} style={{ cursor: "pointer" }}>
                  <div className={classes.listItem} onClick={logout}>
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <ExitIcon fontSize="inherit" />
                    </ListItemIcon>

                    <span className={classes.listItemText}>Logout</span>
                  </div>
                </div>
              )}

              <div className={classes.navLink} style={{ cursor: "pointer" }}>
                <div
                  className={classes.listItem}
                  onClick={openAccountDeleteDialog}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Delete fontSize="inherit" />
                  </ListItemIcon>

                  <span className={classes.listItemText}>Delete Account</span>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedAccordion === accrdionNames.team}
          onChange={handleChange(accrdionNames.team)}
        >
          <AccordionSummary>
            <div id="team" className={classes.navLink}>
              {expandedAccordion !== accrdionNames.team && (
                <div
                  style={{
                    border: showNewTeamInviteBanner
                      ? "2px solid rgba(246, 188, 68, 0.6)"
                      : "1px solid rgba(104, 72, 201, 0.16)",
                    borderRadius: 4,
                    margin: 4,
                  }}
                >
                  {showNewTeamInviteBanner && (
                    <div
                      style={{
                        background: "#EAE3FF",
                        padding: 6,
                        display: "flex",
                        justifyContent: "space-between",
                        color: "#6848C9",
                        fontWeight: 400,
                        fontSize: 12,
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px 4px 0px 0px",
                      }}
                      onClick={acceptNewTeamInvite}
                    >
                      <span>
                        {acceptingInvite
                          ? "Accepting team invite..."
                          : "Accept New Team invite!"}
                      </span>
                      <Mail style={{ fontSize: 24, color: "#6848C9" }} />
                    </div>
                  )}
                  <div
                    className={classes.listItem}
                    style={{
                      padding: 4,
                      height: 56,
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      border: "1px solid rgba(104, 72, 201, 0.16)",
                      background: "#ffffff",
                    }}
                  >
                    <ListItemIcon classes={{ root: classes.listItemIcon }}>
                      <img
                        src={
                          currentlyUsedTeam.owner_email === originalEmailId
                            ? personalTeamIcon
                            : otherTeamIcon
                        }
                        alt=""
                      />
                    </ListItemIcon>
                    {isDrawerExpanded && (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            fontSize: 12,
                            color: "#1C1D1C",
                          }}
                        >
                          {currentlyUsedTeam?.name?.substring(0, 15)}
                          {currentlyUsedTeam?.name?.length > 15 ? "..." : ""}
                        </div>
                        <ExpandMore style={{ cursor: "pointer" }} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: 4,
              display: "grid",
              ...(isDrawerExpanded ? {} : { padding: "4px 0" }),
            }}
          >
            {isDrawerExpanded && (
              <div style={{ display: "grid" }}>
                <div
                  style={{
                    flex: 1,
                    borderRadius: 4,
                    border: showNewTeamInviteBanner
                      ? "2px solid rgba(246, 188, 68, 0.6)"
                      : "1px solid rgba(104, 72, 201, 0.16)",
                  }}
                >
                  {showNewTeamInviteBanner ? (
                    <div
                      style={{
                        background: "#EAE3FF",
                        padding: 6,
                        display: "flex",
                        justifyContent: "space-between",
                        color: "#6848C9",
                        fontWeight: 400,
                        fontSize: 12,
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px 4px 0px 0px",
                      }}
                      onClick={acceptNewTeamInvite}
                    >
                      <span>
                        {acceptingInvite
                          ? "Accepting team invite..."
                          : "Accept New Team invite!"}
                      </span>
                      <Mail style={{ fontSize: 24, color: "#6848C9" }} />
                    </div>
                  ) : (
                    <div
                      style={{
                        borderBottom: "1px solid #E0E3E8",
                        padding: 12,
                        color: "#6D7175",
                        fontSize: 12,
                      }}
                    >
                      <span>
                        {originalEmailId?.substring(0, 20)}
                        {originalEmailId?.length > 20 ? "..." : ""}
                      </span>
                    </div>
                  )}

                  {activeTeams.length > 1 &&
                    activeTeams.map((team) => {
                      return (
                        <div
                          className={classes.team}
                          key={team.owner_email}
                          onClick={() => {
                            setSelectedTeam(team.owner_email);
                          }}
                        >
                          <Radio
                            checked={team.owner_email === selectedTeam}
                            style={{ color: "#546fff", fontSize: 18 }}
                          />
                          <img
                            src={
                              team.owner_email === originalEmailId
                                ? personalTeamIcon
                                : otherTeamIcon
                            }
                            alt=""
                          />
                          <div
                            style={{
                              display: "grid",
                              fontSize: 12,
                              color: "#1C1D1C",
                            }}
                          >
                            {team?.name?.substring(0, 15)}
                            {team?.name?.length > 15 ? "..." : ""}
                          </div>
                        </div>
                      );
                    })}
                  {activeTeams.length > 1 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={switchTeamAccount}
                        style={{
                          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16)",
                          color: "#ffffff",
                          borderColor: "#E0E3E8",
                          background:
                            selectedTeam !== currentlyUsedTeam.owner_email
                              ? "#546FFF"
                              : "rgba(84, 111, 255, 0.6)",
                          borderRadius: 6,
                          fontSize: 12,
                          fontWeight: 500,
                          height: 42,
                          width: 153,
                          margin: "8px 0px",
                        }}
                        startIcon={
                          switchingTeam ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                        disabled={
                          selectedTeam === currentlyUsedTeam.owner_email
                        }
                      >
                        <span>Switch to team</span>
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  className={classes.listItem}
                  style={{
                    padding: 4,
                    height: 56,
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    border: "1px solid rgba(104, 72, 201, 0.16)",
                    background: "#ffffff",
                  }}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <img
                      src={
                        currentlyUsedTeam.owner_email === originalEmailId
                          ? personalTeamIcon
                          : otherTeamIcon
                      }
                      alt=""
                    />
                  </ListItemIcon>
                  {isDrawerExpanded && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr auto",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "grid",
                          fontSize: 12,
                          color: "#1C1D1C",
                        }}
                      >
                        {currentlyUsedTeam?.name?.substring(0, 15)}
                        {currentlyUsedTeam?.name?.length > 15 ? "..." : ""}
                      </div>
                      <ExpandLess
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpandedAccordion("")}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>

        <Dialog open={isAccountDeleteDialogOpen}>
          <CloseButton onClick={closeAccountDeleteDialog}>
            <Close style={{ fontSize: 18 }} />
          </CloseButton>

          <div className="w-[30rem] p-8 text-center">
            <ReportProblemOutlined className="text-4xl text-red-700" />

            <p className="font-medium">Delete your account and data?</p>
            <p className="text-sm text-gray-600">
              All your models and data will be deleted permanently. This cannot
              be undone. Are you sure you want to proceed?
            </p>
            <p className="mt-6 text-xs">
              Please type{" "}
              <span className="font-semibold">"DELETE ALL MY DATA"</span> to
              proceed
            </p>

            <div className="mt-2">
              <TextField
                variant="outlined"
                fullWidth
                placeholder="DELETE ALL MY DATA"
                InputProps={{
                  classes: {
                    input: "text-sm",
                  },
                }}
                value={deleteConfirmationText}
                onChange={(e) => setDeleteConfirmationText(e.target.value)}
              />
            </div>

            <div className="mt-6">
              <Button
                color="secondary"
                variant="contained"
                disabled={
                  deleteConfirmationText !== "DELETE ALL MY DATA" ||
                  deleteAccount.isLoading
                }
                onClick={handleAccountDelete}
                startIcon={
                  deleteAccount.isLoading && (
                    <CircularProgress color="inherit" size={20} />
                  )
                }
              >
                {deleteAccount.isLoading
                  ? "Submitting your request..."
                  : "Delete my account and data"}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>

      <div
        style={{
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
          marginTop: 8,
          cursor: "pointer",
        }}
      >
        <div className={classes.navLink}>
          <div className={classes.listItem} onClick={toggleDrawer}>
            <div className={classes.emptyBox}></div>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <DoubleArrow
                fontSize="inherit"
                className={clsx(isDrawerExpanded && classes.collapseDrawerIcon)}
                style={{ opacity: 0.5 }}
              />
            </ListItemIcon>
          </div>
        </div>
      </div>

      <SwitchTeamDialog
        open={openSwitchTeam}
        onClose={closeSwitchTeamDialog}
        teamInfo={teamWithPendingInvite}
        switchTeam={switchTeamAccount}
      />
    </div>
  );
};

const styles = (theme) => ({
  email: {},
  emptyBox: {
    flex: 1,
  },
  drawer: {
    backgroundColor: "#f9f9f9",
    borderRight: "1px solid #e0e3e8",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "width 0.1s",
    width: 240,
    maxWidth: 240,
    overflow: "hidden",
  },
  navLogo: {
    display: "flex",
    alignItems: "center",
  },
  navLogoText: {
    color: "var(--blue)",
    marginLeft: 12,
    fontSize: 16,
  },
  expandIndicator: {
    display: "flex",
    position: "absolute",
    left: 6,
    top: "auto",
    color: "#66788C80",
  },
  navLink: {
    width: "100%",
    display: "block",
  },
  activeNavLink: {
    "& $listItem": {
      color: "var(--blue)",
    },
  },
  listItem: {
    width: "100%",
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    textDecoration: "none",
    paddingLeft: 16,
    paddingRight: 16,
    color: "#525252",
    position: "relative",
    "&:hover": {
      background: "rgba(84, 111, 255, 0.05)",
      color: "#546fff",
    },
  },
  listItemIcon: {
    fontSize: 20,
    minWidth: 0,
    padding: 8,
    color: "inherit",
  },
  listItemText: {
    margin: 0,
    padding: 8,
    fontSize: 12,
    fontFamily: "inherit",
    color: "inherit",
    whiteSpace: "nowrap",
    flex: 1,
  },
  drawerCollapsed: {
    width: 70,
    "& $listItemText": {
      display: "none",
    },
    "& $emptyBox": {
      display: "none",
    },
    "& $email": {
      display: "none",
    },
  },
  menuItem: {
    fontSize: 14,
  },
  rule: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginTop: 8,
  },
  collapseDrawerIcon: {
    transform: "rotate(180deg)",
  },
  team: {
    display: "grid",
    gridTemplateColumns: "24px 32px 1fr",
    gridGap: 8,
    padding: "6px",
    cursor: "pointer",
    alignItems: "center",
    borderRadius: "0px 0px 4px 4px",
  },
});

export default withStyles(styles)(observer(AppNav));
