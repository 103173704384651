import React from "react";
import { inject, observer } from "mobx-react";

function withUploadPrompt(WrappedComponent, _props) {
  _props = _props || {};

  class uploadCountCheck extends React.Component {
    // check if minimum number of images is uploaded to start training
    get isImageUploadChecked() {
      const model = this.props.AppStore.app;
      if (["od", "similarity", "custom"].indexOf(_props.modelType) !== -1) {
        return true;
      }

      if (model.categories && model.categories.length > 0) {
        return model.categories.every(
          (category) =>
            category.count >=
            this.props.AppStore.modelWiseLimits[_props.modelType]["images"]
        );
      } else {
        return null;
      }
    }

    componentDidMount() {
      this.props.AppStore.resetApp();
      const modelId = this.props.match.params.appId
        ? this.props.match.params.appId
        : this.props.match.params.modelId;
      this.props.AppStore.setAppId(modelId);
    }

    componentDidUpdate() {
      const modelId = this.props.match.params.appId
        ? this.props.match.params.appId
        : this.props.match.params.modelId;
      this.props.AppStore.setAppId(modelId);
    }

    render() {
      if (this.isImageUploadChecked === true) {
        return <WrappedComponent {...this.props} {..._props} />;
      } else if (this.isImageUploadChecked === false) {
        return <PromptModal {...this.props} {..._props} />;
      } else if (this.isImageUploadChecked === null) {
        return null;
      }
    }
  }

  return inject("AppStore")(observer(uploadCountCheck));
}

class PromptModal extends React.Component {
  constructor(props) {
    super(props);
    this.intervalId = null;
    this.state = {
      timer: 5,
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState(({ timer }) => ({
        timer: timer - 1,
      }));
    }, 1000);
  }

  componentDidUpdate() {
    if (this.state.timer === 0) {
      this.redirect();
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  redirect = () => {
    if (this.props.modelType === "mlc") {
      this.props.history.push(`/mlc/annotate/${this.props.match.params.appId}`);
    } else {
      this.props.history.push(`/IC/upload/${this.props.match.params.appId}`);
    }
  };

  render() {
    const minUploadImageCount = this.props.AppStore.modelWiseLimits[
      this.props.modelType
    ]["images"];
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        <div
          style={{
            padding: "20px 32px",
            maxWidth: 480,
            textAlign: "center",
            boxShadow: "0 0 8px 0 #0004",
            borderRadius: 8,
            margin: "auto",
          }}
        >
          <p>
            Please select {minUploadImageCount} images per category to continue.
            <br /> You will be redirected to upload page in{" "}
            <b>{this.state.timer}</b>...
          </p>
        </div>
      </div>
    );
  }
}

export default withUploadPrompt;
