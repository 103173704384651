import { lazy, Suspense } from "react";
import { Route, Redirect, Switch, RouteProps } from "react-router-dom";
import "lazysizes";
import { CircularProgress } from "@material-ui/core";

import { hasAccess } from "Utils/access";
import {
  useFeatureFlags,
  useIsAdmin,
  useIsLoggedIn,
  useUser,
} from "hooks/user";
import UploadPrompt from "components/UploadPrompt";
import { Loader } from "components/misc";
import { Alert } from "@material-ui/lab";

const Login = lazy(() => import("components/Auth/Login"));
const SignUp = lazy(() => import("components/Auth/SignUp"));
const SignUpSSO = lazy(() => import("components/Auth/SSO"));
const EmailVerify = lazy(() => import("components/Auth/EmailVerify"));
const Reset = lazy(() => import("components/Auth/Reset"));

const AnnotationView = lazy(
  () => import("components/OCR/Annotate/AnnotationView")
);
const PredictionView = lazy(() => import("components/OCR/Test/InferenceView"));
const AnalyzeOCR = lazy(() => import("components/OCR/Analyze/Analyze"));
const OCRCreate = lazy(() => import("components/OCR/Create/Create"));
const OCRUpload = lazy(() => import("components/OCR/Upload/Upload"));
const OcrLabels = lazy(() => import("components/OCR/Labels"));
const OCRModerate = lazy(() => import("components/OCR/Moderate/Moderate"));
const ManageSettings = lazy(() => import("components/ModelSettings"));
const OcrWorkflow = lazy(() => import("components/OCR/Workflow/Workflow"));

const CustomModels = lazy(() => import("components/CustomModels/Dashboard"));
const Admin = lazy(() => import("components/admin"));
const Pricing = lazy(() => import("components/Pricing/Pricing"));
const UploadPipelineConfig = lazy(
  () => import("components/UploadPipelineConfig/UploadPipelineConfig")
);

const page404 = lazy(() => import("components/404"));
const checkout = lazy(() => import("components/Checkout"));
const Airtable = lazy(() => import("components/Airtable/Airtable"));
const Home = lazy(() => import("components/Home"));
const Apps = lazy(() => import("components/Models/Apps"));
const Keys = lazy(() => import("components/Keys/Keys"));
const Profile = lazy(() => import("components/Profile/Profile"));
const Invoices = lazy(() => import("components/Billing"));
const Team = lazy(() => import("components/Team/Team"));
const Integrations = lazy(() => import("components/Integrations/Integrations"));
const WhitelabelDomain = lazy(
  () => import("components/WhitelabelDomain/WhitelabelDomain")
);
const SupportRequests = lazy(
  () => import("components/SupportRequests/SupportRequests")
);

const icCustomModel = lazy(
  () => import("components/IC/CustomModel/CustomModel")
);
const icLabelSettings = lazy(
  () => import("components/IC/CustomModel/LabelList")
);

const ModelChangelog = lazy(() => import("components/model/Changelog"));

const UploadLocal = lazy(() => import("components/IC/UploadLocal/UploadLocal"));
const ICconfirm = lazy(() => import("components/IC/Confirm/Confirm"));
const ICtest = lazy(() => import("components/IC/Test/Test"));
const IntegrateIC = lazy(() => import("components/IC/Integrate/Integrate"));

const MLCcreate = lazy(() => import("components/MLC/Create/Create"));
const MLCupload = lazy(() => import("components/MLC/Upload/Upload"));
const MLCconfirm = lazy(() => import("components/MLC/Confirm/Confirm"));
const MLCannotate = lazy(() => import("components/MLC/Annotate/Annotate"));
const MLCtest = lazy(() => import("components/MLC/Test/Test"));
const IntegrateMLC = lazy(() => import("components/MLC/Integrate/Integrate"));

const ODCreate = lazy(() => import("components/OD/Create/Create"));
const ODUpload = lazy(() => import("components/OD/Upload/Upload"));
const ODAnnotate = lazy(() => import("components/OD/Annotate/Annotate"));
const ODWaitScreen = lazy(() => import("components/OD/WaitScreen/WaitScreen"));
const ODTest = lazy(() => import("components/OD/Test/Test"));
const ODIntegrate = lazy(() => import("components/OD/Integrate/Integrate"));
const Analyze = lazy(() => import("components/Analyze/Analyze"));
const ManualReview = lazy(() => import("components/Analyze/ManualReview"));
const ModelVersioning = lazy(
  () => import("components/ModelVersioning/ModelVersioning")
);

const SIMCreate = lazy(() => import("components/SIM/Create/Create"));
const SIMUpload = lazy(() => import("components/SIM/Upload/Upload"));
const SIMTest = lazy(() => import("components/SIM/Test/Test"));
const SIMWaitScreen = lazy(
  () => import("components/SIM/WaitScreen/WaitScreen")
);
const SIMIntegrate = lazy(() => import("components/SIM/Integrate/Integrate"));

const CustomModelsTest = lazy(() => import("components/CustomModels/Test"));
const CustomModelsIntegrate = lazy(
  () => import("components/CustomModels/Integrate")
);
const CustomModelsCreate = lazy(() => import("components/CustomModels/Create"));
const CustomModelsView = lazy(() => import("components/CustomModels/View"));
const WorkflowSetup = lazy(() => import("components/workflow/Setup"));
const EmailNotificationSettings = lazy(
  () => import("components/EmailNotificationSettings")
);

const Loader2 = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Loader />
    </div>
  );
};

const PrivateRoute = (props: RouteProps) => {
  const isLoggedIn = useIsLoggedIn() || hasAccess("auth.auto_login");
  const user = useUser();

  return user.isLoading ? (
    <div
      data-testid="user-auth"
      className="flex h-full w-full items-center justify-center"
    >
      <CircularProgress />
    </div>
  ) : isLoggedIn ? (
    <Route {...props} />
  ) : (
    <Redirect to="/login" />
  );
};

const Routes = () => {
  const isAdmin = useIsAdmin();
  const isLoggedIn = useIsLoggedIn() || hasAccess("auth.auto_login");
  const { data: user } = useUser();
  const featureFlags = useFeatureFlags();

  return (
    <Suspense fallback={<Loader2 />}>
      <Switch>
        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/apps" render={() => <Redirect to="/models" />} />
        <PrivateRoute path="/models" component={Apps} exact />

        <PrivateRoute
          path="/models/:modelId/changelog"
          render={() =>
            featureFlags.isLoading ? (
              <Loader2 />
            ) : featureFlags.isError ? (
              <Alert severity="error" classes={{ root: "m-4 inline-flex" }}>
                An error occurred while fetching user access.
              </Alert>
            ) : featureFlags.data?.model_edit_history_feature_allowed ? (
              <ModelChangelog />
            ) : (
              <Alert severity="error" classes={{ root: "m-4 inline-flex" }}>
                Access denied
              </Alert>
            )
          }
        />

        <PrivateRoute path="/keys" component={Keys} />
        <PrivateRoute path="/profile" component={Profile} />
        {hasAccess("my_account.billing") && (
          <PrivateRoute path="/billing" component={Invoices} />
        )}
        {hasAccess("my_account.team") && (
          <PrivateRoute path="/team" component={Team} />
        )}
        <PrivateRoute path="/integrations" component={Integrations} />
        {hasAccess("my_account.whitelabel") && (
          <PrivateRoute
            path="/whitelabel-domain"
            component={WhitelabelDomain}
          />
        )}
        {hasAccess("my_account.support_requests") && (
          <PrivateRoute path="/support-requests" component={SupportRequests} />
        )}

        <PrivateRoute
          path="/workflow/:workflowStep"
          component={WorkflowSetup}
        />
        <PrivateRoute path="/ocr/create" component={OCRCreate} exact />
        <PrivateRoute path="/ocr/upload/:modelId" component={OCRUpload} />
        <PrivateRoute path="/ocr/labels/:modelId" component={OcrLabels} />
        <PrivateRoute
          path="/ocr/settings/:modelId"
          component={ManageSettings}
        />
        <PrivateRoute
          path="/ocr/confirm/:modelId"
          render={(props) => <ODWaitScreen {...props} modelType={"ocr"} />}
        />
        <PrivateRoute
          path="/ocr/test/:modelId/:pageId?"
          strict
          component={PredictionView}
        />
        <PrivateRoute
          path="/ocr/asynctest/:modelId/:pageId?"
          strict
          render={(props) => <PredictionView {...props} async={true} />}
        />
        <PrivateRoute
          path="/ocr/annotate/:modelId/:pageId?"
          strict
          component={AnnotationView}
        />
        <PrivateRoute
          path="/ocr/integrate/:modelId"
          // @ts-ignore
          render={(props) => <ODIntegrate {...props} modelType={"ocr"} />}
        />
        <PrivateRoute
          path="/ocr/analyze/:modelId"
          render={(props) => (
            <AnalyzeOCR {...props} modelType={"ocr"} type={"metrics"} />
          )}
        />
        <PrivateRoute
          path="/ocr/moderate/:modelId"
          render={(props) => <OCRModerate {...props} modelType={"ocr"} />}
        />
        <PrivateRoute
          path="/ocr/versions/:modelId"
          component={ModelVersioning}
        />
        <PrivateRoute
          path="/ocr/postprocess/:modelId"
          render={(props) => (
            <OcrWorkflow {...props} async={true} modelType={"ocr"} />
          )}
        />

        <PrivateRoute
          path="/IC/model/custom"
          render={() => <Redirect to="/IC/labels" />}
        />
        <PrivateRoute
          path="/IC/labels"
          component={
            user?.FeatureFlags?.ic_new_ui ? icLabelSettings : icCustomModel
          }
          exact
        />
        <PrivateRoute
          path="/IC/labels/:modelId"
          component={
            user?.FeatureFlags?.ic_new_ui ? icLabelSettings : icCustomModel
          }
        />
        <PrivateRoute
          path="/IC/confirmnew/:modelId"
          render={(props) =>
            user?.FeatureFlags?.ic_new_ui ? (
              <ODWaitScreen {...props} modelType={"ic"} />
            ) : (
              <ICconfirm {...props} />
            )
          }
        />
        <PrivateRoute
          path="/IC/extract/:modelId/:pageId?"
          strict
          component={user?.FeatureFlags?.ic_new_ui ? PredictionView : ICtest}
        />

        <PrivateRoute path="/IC/create" component={icCustomModel} exact />
        <PrivateRoute path="/IC/create/:appId" component={icCustomModel} />
        <PrivateRoute path="/IC/upload/:appId" component={UploadLocal} />
        <PrivateRoute path="/IC/confirm/:appId" component={ICconfirm} />
        <PrivateRoute path="/IC/test/:modelId" component={ICtest} />
        <PrivateRoute path="/IC/integrate/:appId" component={IntegrateIC} />
        <PrivateRoute
          path="/IC/analyze/:modelId"
          render={(props) => (
            <Analyze {...props} modelType={"ic"} type={"metrics"} />
          )}
        />
        <PrivateRoute
          path="/IC/predictions/:modelId"
          render={(props) => (
            <Analyze {...props} modelType={"ic"} type={"predictions"} />
          )}
        />
        <PrivateRoute
          path="/IC/review/:modelId"
          render={(props) => <ManualReview {...props} modelType={"ic"} />}
        />
        <PrivateRoute
          path="/IC/versions/:modelId"
          render={(props) => {
            const ComponentWithPrompt = UploadPrompt(ModelVersioning, {
              modelType: "ic",
            });
            return <ComponentWithPrompt {...props} />;
          }}
        />

        <PrivateRoute path="/mlc/create" component={MLCcreate} exact />
        <PrivateRoute path="/mlc/create/:appId" component={MLCcreate} />
        <PrivateRoute path="/mlc/upload/:appId" component={MLCupload} />
        <PrivateRoute path="/mlc/annotate/:appId" component={MLCannotate} />
        <PrivateRoute path="/mlc/confirm/:appId" component={MLCconfirm} />
        <PrivateRoute path="/mlc/test/:modelId" component={MLCtest} />
        <PrivateRoute path="/mlc/integrate/:appId" component={IntegrateMLC} />

        <PrivateRoute path="/OD/create" component={ODCreate} exact />
        <PrivateRoute path="/OD/create/:modelId" component={ODCreate} />
        <PrivateRoute path="/OD/upload/:modelId" component={ODUpload} />
        <PrivateRoute path="/OD/annotate/:modelId" component={ODAnnotate} />
        <PrivateRoute
          path="/OD/confirm/:modelId"
          render={(props) => <ODWaitScreen {...props} modelType={"od"} />}
        />
        <PrivateRoute path="/OD/test/:modelId" component={ODTest} />
        <PrivateRoute path="/OD/integrate/:modelId" component={ODIntegrate} />
        <PrivateRoute
          path="/OD/analyze/:modelId"
          render={(props) => (
            <Analyze {...props} modelType={"od"} type={"metrics"} />
          )}
        />
        <PrivateRoute
          path="/OD/predictions/:modelId"
          render={(props) => (
            <Analyze {...props} modelType={"od"} type={"predictions"} />
          )}
        />
        <PrivateRoute
          path="/OD/review/:modelId"
          render={(props) => <ManualReview {...props} modelType={"od"} />}
        />
        <PrivateRoute
          path="/OD/versions/:modelId"
          component={ModelVersioning}
        />

        <PrivateRoute path="/similarity/create" component={SIMCreate} exact />
        <PrivateRoute
          path="/similarity/create/:modelId"
          component={SIMCreate}
        />
        <PrivateRoute path="/similarity/upload" component={SIMUpload} exact />
        <PrivateRoute
          path="/similarity/upload/:modelId"
          component={SIMUpload}
        />
        <PrivateRoute
          path="/similarity/test/:modelId"
          render={(props) => <SIMTest {...props} modelType={"similarity"} />}
        />
        <PrivateRoute
          path="/similarity/integrate/:modelId"
          render={(props) => (
            // @ts-ignore
            <SIMIntegrate {...props} modelType={"similarity"} />
          )}
        />
        <PrivateRoute
          path="/similarity/confirm/:modelId"
          render={(props) => (
            <SIMWaitScreen {...props} modelType={"similarity"} />
          )}
        />

        {isAdmin && (
          <PrivateRoute
            path="/custom/create"
            render={(props) => <CustomModelsCreate {...props} />}
          />
        )}
        <PrivateRoute
          path="/custom/configure/:modelId"
          render={(props) => <CustomModelsView {...props} />}
        />
        <PrivateRoute
          path="/custom/test/:modelId/:pageId?"
          render={(props) => (
            <CustomModelsTest
              {...props}
              modelType={"custom"}
              screenType="test"
            />
          )}
        />
        <PrivateRoute
          path="/custom/integrate/:modelId"
          render={(props) => (
            // @ts-ignore
            <CustomModelsIntegrate {...props} modelType={"custom"} />
          )}
        />

        <PrivateRoute path="/pricing/:appId" component={Pricing} />
        <PrivateRoute path="/airtable/:id" component={Airtable} />
        <PrivateRoute path="/airtable" component={Airtable} />
        <PrivateRoute path="/checkout" component={checkout} />

        {isAdmin && <PrivateRoute path="/admin" component={Admin} exact />}
        {isAdmin && (
          <PrivateRoute path="/custom-models" component={CustomModels} />
        )}
        {isAdmin && (
          <PrivateRoute
            path="/admin/train/:appId"
            component={UploadPipelineConfig}
          />
        )}

        {user?.FeatureFlags?.file_assignment_emails && (
          <PrivateRoute
            path="/notification-settings"
            component={EmailNotificationSettings}
          />
        )}

        {isLoggedIn && (
          <Route
            path={["/login", "/signup", "/signupsso", "/email-verification"]}
          >
            <Redirect to="/" />
          </Route>
        )}
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/signupsso" component={SignUpSSO} />
        <Route path="/email-verification" component={EmailVerify} />
        <Route path="/reset" component={Reset} />
        <Route component={page404} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
