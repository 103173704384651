import {
  observable,
  configure,
  flow,
  action,
  computed,
  decorate,
  reaction,
} from "mobx";
import uuidv1 from "uuid/v1";
import userStore from "./mainStore";
import AppStore from "./AppStore";

import { constructURLEncodedFormData } from "Utils";
import logger from "Utils/logger";
import { hasAccess } from "Utils/access";

const USE_CASSANDRA = hasAccess("use_cassandra.annotate");

configure({ enforceActions: "observed" });

// Naming convention is annotation object is an image with multiple objects
// Object is one reactangle drawn inside image
export class AnnotationStore {
  constructor() {
    reaction(
      () => this.activeModel.categories,
      (categories) => {
        this.updateAnnotationCount(categories);
      }
    );

    reaction(
      () => AppStore.model.categories,
      (categories) => {
        this.updateAnnotationCount(categories);
      }
    );

    reaction(
      () => this.activeModel.table_categories,
      (categories) => {
        this.updateTableAnnotationCount(categories);
      }
    );
  }

  fabrics = {};
  signedUrls = {};
  annoLabels = {};
  ModalState = false;
  keysSortedByUploadTime = [];
  keysOfAnnotatedImages = [];
  keysOfUnAnnotatedImages = [];
  sortByState = "ascending"; // possible values ascending | descending
  imagesFilter = "all"; // possible values all | annotated | unannotated
  keysToDisplay = [];
  googleOcr = [];
  annotationCount = 0;
  actualAnnotationCount = 0;
  annotated_count = 0;
  pageStateMarker = "";
  dataByLabel = {};
  dataByHeaderLabel = {};

  fetchingAnnotation = false;

  current = new Map();
  // TODO: Convert into objects so no hardcoded strings
  // Possible states:
  // "drawing" - have only start
  // "drawn" - drawing is complete
  // "labeled" - label is assigned
  // "deleted" - label is removed
  // "null"
  // "moving" // to check the diff. between click and move

  activeModel = {
    categories: [],
    model_id: "",
    state: 1,
  };

  result = {
    JSON: "",
    currentImage:
      "https://s3-us-west-2.amazonaws.com/nanonets/uploadedfiles/0d906cb8-7992-46da-b475-bb296fbaa488/ImageSets/a2016a81-8445-4f97-b14d-cab0783c6b91.jpeg",
    annotateSnackbar: false,
    message: "You need to have more than 100 labels",
    output: [],
    dialog: false,
    loading: false,
  };
  activeImage = "";
  activeImageSize = null;

  labelCount = new Map();
  headerLabelCount = new Map();

  increment_total_annotation_count = action(() => {
    this.annotationCount += 1;
  });

  decrement_total_annotation_count = action(() => {
    this.annotationCount -= 1;
  });

  increment_annotated_count = action(() => {
    this.annotated_count += 1;
  });

  decrement_annotated_count = action(() => {
    this.annotated_count -= 1;
  });

  updateAnnotationCount = (categories) => {
    const annotationCountPerLabel = {};

    if (categories) {
      categories.forEach((category) => {
        annotationCountPerLabel[category.name] = category.count;
      });
    }
    this.dataByLabel = annotationCountPerLabel;
  };

  updateTableAnnotationCount = (tableCategories) => {
    const annotationCountPerLabel = {};

    if (tableCategories?.length > 0) {
      tableCategories[0].headers?.forEach((header) => {
        annotationCountPerLabel[header] =
          tableCategories[0].headers_count[header];
      });
    }
    this.dataByHeaderLabel = annotationCountPerLabel;
  };

  removeKey(keyToRemove) {
    let index1 = this.keysToDisplay.findIndex((key) => key === keyToRemove);
    if (index1 > -1) {
      this.keysToDisplay.splice(index1, 1);
    }

    let index2 = this.keysSortedByUploadTime.findIndex(
      (key) => key === keyToRemove
    );
    if (index2 > -1) {
      this.keysSortedByUploadTime.splice(index2, 1);
    }

    let index3 = this.keysOfAnnotatedImages.findIndex(
      (key) => key === keyToRemove
    );
    if (index3 > -1) {
      this.keysOfAnnotatedImages.splice(index3, 1);
    }

    let index4 = this.keysOfUnAnnotatedImages.findIndex(
      (key) => key === keyToRemove
    );
    if (index4 > -1) {
      this.keysOfUnAnnotatedImages.splice(index4, 1);
    }
    try {
      if (this.fabrics[keyToRemove]) {
        if (this.fabrics[keyToRemove].object) {
          for (let key in this.fabrics[keyToRemove].object) {
            this.updateDataByLabel(
              this.fabrics[keyToRemove].object[key],
              false
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  emptyResult() {
    this.result.output = [];
    this.result.JSON = "";
  }

  updateActiveImage(url, size) {
    this.activeImage = url;
    this.activeImageSize = size;
  }

  setImageSize(fabricId, size) {
    this.fabrics[fabricId].size = size;
  }

  get firstImageSrc() {
    return (
      annotationStore.keysSortedByUploadTime[0] &&
      annotationStore.fabrics[annotationStore.keysSortedByUploadTime[0]]
        .original
    );
  }

  setAnnotationKeys(keysSortedByUploadTime) {
    this.keysSortedByUploadTime = keysSortedByUploadTime;
  }

  clearOldAnnotations() {
    this.keysSortedByUploadTime = [];
    this.fabrics = {};
    this.annoLabels = {};
    this.keysOfAnnotatedImages = [];
    this.keysOfUnAnnotatedImages = [];
    this.keysToDisplay = [];
    this.annotationCount = 0;
    this.annotated_count = 0;
    this.actualAnnotationCount = 0;
    this.pageStateMarker = "";
  }

  fillLabelCount() {
    annotationStore.labelCount.clear();
    for (let fabricId in annotationStore.fabrics) {
      for (let key in annotationStore.fabrics[fabricId].object) {
        const label = annotationStore.fabrics[fabricId].object[key].label;
        const count = annotationStore.labelCount.get(label);
        if (count) {
          annotationStore.labelCount.set(label, count + 1);
        } else {
          annotationStore.labelCount.set(label, 1);
        }
      }
    }
  }

  fillHeaderLabelCount() {
    annotationStore.headerLabelCount.clear();
    for (let fabricId in annotationStore.fabrics) {
      for (let key in annotationStore.fabrics[fabricId].object) {
        const object = annotationStore.fabrics[fabricId].object[key];
        const cells = object.cells;

        if (object.type === "table" && cells?.length > 0) {
          cells.forEach((cell) => {
            const label = cell.label;
            if (label) {
              let count = annotationStore.headerLabelCount.get(label) || 0;
              if (cell.text) count += 1;
              annotationStore.headerLabelCount.set(label, count);
            }
          });
        }
      }
    }
  }

  get headerLabelAnnotationCount() {
    return function (label) {
      let labelcount = this.headerLabelCount.get(label) || 0;
      if (
        this.annotationCount > this.keysToDisplay.length &&
        this.dataByHeaderLabel[label]
      ) {
        // in case of pagination when all data is not present
        labelcount = this.dataByHeaderLabel[label] || 0;
      }
      return labelcount;
    };
  }

  updateFabrics(fabrics) {
    this.fabrics = fabrics;
  }

  insertNewFabricObject(fabric_id, original_filename, filename) {
    if (this.fabrics[fabric_id]) {
      console.log("Already present ..");
      return;
    }
    this.fabrics[fabric_id] = {
      position: Object.keys(this.fabrics).length,
    };
    this.fabrics[fabric_id].object = {};

    this.fabrics[fabric_id].filename = filename;
    this.fabrics[fabric_id].originalFilename = original_filename;
    this.fabrics[fabric_id].size = { width: 0, height: 0 };
  }

  get totalAnnosKeys() {
    return [...this.labelCount.keys()];
  }

  getOcrText(boundingBox, canvasWidth) {
    const fabricId = this.current.get("fabricId");

    let scaleRatio = 1;
    if (canvasWidth !== 0) {
      var { size, rotate } = this.fabrics[fabricId];
      scaleRatio =
        rotate === 90 || rotate === 270
          ? size.height / canvasWidth
          : size.width / canvasWidth;
    }
    let { xmin, xmax, ymin, ymax } = boundingBox;
    xmin *= scaleRatio;
    xmax *= scaleRatio;
    ymin *= scaleRatio;
    ymax *= scaleRatio;

    xmin = Math.min(xmin, xmax);
    xmax = Math.max(xmin, xmax);
    ymin = Math.min(ymin, ymax);
    ymax = Math.max(ymin, ymax);

    const boxes = this.googleOcr.filter((box) => {
      const x_center = box.left + box.width / 2;
      const y_center = box.top + box.height / 2;

      return (
        x_center >= xmin &&
        x_center <= xmax &&
        y_center >= ymin &&
        y_center <= ymax
      );
    });

    let text = "";

    if (boxes.length > 0) {
      xmin = Math.min.apply(
        Math,
        boxes.map((box) => box.left)
      );

      xmax = Math.max.apply(
        Math,
        boxes.map((box) => box.left + box.width)
      );

      ymin = Math.min.apply(
        Math,
        boxes.map((box) => box.top)
      );

      ymax = Math.max.apply(
        Math,
        boxes.map((box) => box.top + box.height)
      );

      text = boxes
        .map((box, i) => {
          let { ocrText: text } = box;
          const nextBox = boxes[i + 1];
          if (nextBox) {
            if (nextBox.top - (box.top + box.height) > -5) {
              text += " \n";
            } else {
              text += " ";
            }
          }

          return text;
        })
        .join("");
    }

    return {
      xmin,
      xmax,
      ymin,
      ymax,
      text,
    };
  }

  // Convert annotation json from rest api to client annotation format
  annotationsAdapter(serverAnnotations, width, paginate) {
    let fabrics = {};
    let keysOfAnnotatedImages = [];
    let keysOfUnAnnotatedImages = [];
    let allKeys = [];
    if (paginate) {
      fabrics = { ...this.fabrics };
      keysOfAnnotatedImages = [...this.keysOfAnnotatedImages];
      keysOfUnAnnotatedImages = [...this.keysOfUnAnnotatedImages];
      allKeys = [...this.keysToDisplay];
    }

    if (serverAnnotations.Data) {
      if (USE_CASSANDRA)
        serverAnnotations.Data.sort((a, b) => a.time_added - b.time_added);

      for (let i = 0; i < serverAnnotations.Data.length; i++) {
        let result = serverAnnotations.Data[i];

        if (
          !(result.time_added || result.filename || result.original_filename)
        ) {
          continue;
        }

        let fabricId = result.id;
        fabrics[fabricId] = fabrics[fabricId] || {};

        fabrics[fabricId] = {
          position: i + 1,
          time_added: result.time_added
            ? parseInt(result.time_added / 1000000)
            : Date.now(),
          id: result.id,
          assigned_member: result.assigned_member,
          pre_annotated: result.pre_annotated,
          is_annotated: result.is_annotated,
          filename: result.filename,
          originalFilename: result.original_filename,
          size: result.size,
          rotate: result.rotate,
          ...fabrics[fabricId],
        };

        if (
          serverAnnotations.signed_urls &&
          serverAnnotations.signed_urls[result.filename]
        ) {
          fabrics[fabricId] = {
            ...fabrics[fabricId],
            ...serverAnnotations.signed_urls[result.filename],
          };
        }
        let scaleRatio = 1;
        if (width > 0) {
          scaleRatio = result.size.width / width;
        }
        fabrics[fabricId].crop = this.getBoundingBox(
          { bndbox: result.roi_crop },
          scaleRatio,
          fabrics[fabricId]
        );

        fabrics[fabricId].object = {};

        if (result.object) {
          for (let j = 0; j < result.object.length; j++) {
            const object = result.object[j];
            const label = object.name;
            // console.log(object)
            if (
              object.type !== "table" &&
              (this.labelsMap[label] === null ||
                this.labelsMap[label] === undefined)
            ) {
              // not showing annotations corresponding to labels that don't belong the model
              continue;
            }
            // console.log("Skipped label check")

            const objectId = uuidv1();
            object.id = objectId;
            const boundingBox = this.getBoundingBox(
              object,
              scaleRatio,
              fabrics[fabricId]
            );
            fabrics[fabricId].object[objectId] = boundingBox;
          }
        }

        if (allKeys.indexOf(fabricId) === -1) {
          allKeys.push(fabricId);
        }

        if (Object.keys(fabrics[fabricId].object).length > 0) {
          if (keysOfAnnotatedImages.indexOf(fabricId) === -1) {
            keysOfAnnotatedImages.push(fabricId);
          }
        } else {
          if (keysOfUnAnnotatedImages.indexOf(fabricId) === -1) {
            keysOfUnAnnotatedImages.push(fabricId);
          }
        }
      }
    }
    this.keysOfAnnotatedImages = keysOfAnnotatedImages;
    this.keysOfUnAnnotatedImages = keysOfUnAnnotatedImages;
    this.keysSortedByUploadTime = allKeys;
    this.keysToDisplay = allKeys; // used in annotate screen
    for (var key in serverAnnotations.signed_urls) {
      this.signedUrls[key] = serverAnnotations.signed_urls[key];
    }
    return fabrics;
  }

  getBoundingBox = (object, scaleRatio, fabric) => {
    let { rotate, size } = fabric;
    let bndbox = object.bndbox;
    let cells = object.cells;
    let grid_cells = object.grid_cells;
    let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
      bndbox,
      fabric.size,
      fabric.rotate
    );

    xmax /= scaleRatio;
    ymax /= scaleRatio;
    xmin /= scaleRatio;
    ymin /= scaleRatio;

    if (cells) {
      cells = cells.map((cell) => {
        let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
          cell,
          size,
          (360 + rotate) % 360
        );
        return { ...cell, xmin, ymin, xmax, ymax };
      });
    }

    if (grid_cells) {
      grid_cells = grid_cells.map((cell) => {
        let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
          cell,
          size,
          (360 + rotate) % 360
        );
        return { ...cell, xmin, ymin, xmax, ymax };
      });
    }

    return {
      top: ymin,
      left: xmin,
      width: Math.abs(xmax - xmin),
      height: Math.abs(ymax - ymin),
      id: object.id,
      label: object.name,
      ocrText: object.ocr_text,
      type: object.type,
      cells,
      grid_cells,
    };
  };

  // Load annotations from rest api
  getAllAnnotations = flow(function* (
    width,
    loadAnnotationsSilently,
    paginate
  ) {
    // loadAnnotationsSilently is used to prevent the loader from appearing when the annotations are pulled in the background

    let modelId = AppStore.appId;
    if (!modelId) return;

    if (
      paginate &&
      USE_CASSANDRA &&
      annotationStore.annotationCount > 0 &&
      this.pageStateMarker === ""
    ) {
      return;
    }

    if (!loadAnnotationsSilently) {
      userStore.setGlobalLoader(true);
    }

    let url = `/api/v2/ObjectDetection/Model/${modelId}/Annotations/`;
    if (paginate) {
      url += "?paginate=true&limit=200";
      if (this.pageStateMarker !== "") {
        url += `&state=${this.pageStateMarker}`;
      }
      if (this.sortByState === "ascending") {
        url += `&getEarliestFirst=true`;
      }
    }
    try {
      let requestHandler;

      requestHandler = fetch(url, {
        credentials: "include",
        method: "GET",
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Error fetching annotations");
        }
        return res.json();
      });

      const fabrics = yield requestHandler.then((data) => data);
      // the asynchronous blocks will automatically be wrapped actions and can modify state
      this.annotationCount = fabrics.Count;
      this.actualAnnotationCount = fabrics.actualAnnotationCount;
      this.annotated_count = fabrics.annotated_count;
      if (
        paginate &&
        USE_CASSANDRA &&
        this.pageStateMarker !== "" &&
        fabrics.State === this.pageStateMarker
      ) {
        return;
      }
      this.pageStateMarker = fabrics.State;
      const processedFabrics = this.annotationsAdapter(
        fabrics,
        width,
        paginate
      );
      if (!processedFabrics) return;
      this.updateFabrics(processedFabrics);
      this.fillLabelCount();
      this.fillHeaderLabelCount();
      userStore.setGlobalLoader(false);
    } catch (error) {
      logger.captureException(error);
      this.state = "error";
      userStore.setGlobalLoader(false);
    }
  });

  // Load annotations from rest api
  getModel = flow(function* (model_id) {
    try {
      const model = yield fetch(
        "/api/v2/ObjectDetection/Model/" + AppStore.appId + "/",
        {
          credentials: "include",
          method: "GET",
        }
      )
        .then((d) => d.json())
        .then((data) => data); // yield instead of await
      // the asynchronous blocks will automatically be wrapped actions and can modify state
      this.activeModel = model;
    } catch (error) {
      this.state = "error";
    }
  });

  // New model. Why is this function in annotation flow?
  createModel = flow(function* (annotations) {
    // <- note the star, this a generator function!
    this.activeModel = {};
    const data = {
      categories: annotations.map((n) => n.label),
    };
    try {
      const model = yield fetch("/api/v2/ObjectDetection/Model/", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((d) => d.json())
        .then((data) => data); // yield instead of await
      // the asynchronous blocks will automatically be wrapped actions and can modify state
      this.activeModel = model;
      window.location.href = `/#/OD/upload/${model.model_id}`;
    } catch (error) {
      this.state = "error";
    }
  });

  updateModel = flow(function* (annotations) {
    const data = {
      categories: annotations.map((n) => n.label),
    };
    try {
      const model = yield fetch(
        `/api/v2/ObjectDetection/Model/${AppStore.appId}/`,
        {
          credentials: "include",
          method: "PATCH",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((d) => d.json())
        .then((data) => data); // yield instead of await
      // the asynchronous blocks will automatically be wrapped actions and can modify state
      window.location.href = `/#/OD/upload/${AppStore.appId}`;
      this.activeModel = model;
    } catch (error) {
      this.state = "error";
    }
  });

  // Derive labels from model
  get labels() {
    if (this.activeModel.categories) {
      return this.activeModel.categories.map((obj) => obj.name).sort();
    } else {
      return [];
    }
  }

  get labelsOptions() {
    return this.labels.map((a) => ({ label: a, value: a }));
  }

  get labelsMap() {
    let labelsMap = {};
    if (this.activeModel.categories) {
      this.activeModel.categories.forEach((obj, index) => {
        labelsMap[obj.name] = obj.count;
      });
    }
    return labelsMap;
  }

  get modalState() {
    return (
      this.current.get("state") === "drawn" ||
      this.current.get("state") === "moved"
    );
  }

  get showOCRTextBox() {
    return this.current.get("showOCR") === "show";
  }

  get showOCRTextHoverBox() {
    return this.current.get("showOCRTextHoverBox");
  }

  get ocrText() {
    return this.current.get("ocrText");
  }

  getAnnotations = (canvas, type) => {
    return canvas
      .getObjects()
      .filter(
        (obj) => obj.type === type || (obj.data && obj.data.type === type)
      );
  };

  /**
   * convert annotation from UI format to server format
   */
  parseAnnotationWithRotation(fabricId) {
    const { size, rotate, object, crop } = this.fabrics[fabricId];
    let updatedCrop = crop ? { ...crop } : {};
    const arr = Object.keys(object).map((objectId) => {
      let _object = object[objectId];
      let { grid_cells, cells, top, left, width, height, label, ocrText } =
        _object;

      const obj = { ..._object };
      obj.name = label;
      obj.ocr_text = ocrText;
      obj.bndbox = this.rotatebndbox(
        { xmin: left, ymin: top, xmax: left + width, ymax: top + height },
        rotate === 90 || rotate === 270
          ? { width: size.height, height: size.width }
          : size,
        (360 - rotate) % 360
      );

      if (cells) {
        obj.cells = cells.map((cell) => {
          let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
            cell,
            rotate === 90 || rotate === 270
              ? { height: size.width, width: size.height }
              : size,
            (360 - rotate) % 360
          );
          return { ...cell, xmin, ymin, xmax, ymax };
        });
      }

      if (grid_cells) {
        obj.grid_cells = grid_cells.map((cell) => {
          let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
            cell,
            rotate === 90 || rotate === 270
              ? { height: size.width, width: size.height }
              : size,
            (360 - rotate) % 360
          );
          return { ...cell, xmin, ymin, xmax, ymax };
        });
      }

      return obj;
    });
    if (crop && crop.width !== 0) {
      updatedCrop = this.rotatebndbox(
        {
          xmin: crop.left,
          ymin: crop.top,
          xmax: crop.left + crop.width,
          ymax: crop.top + crop.height,
        },
        rotate === 90 || rotate === 270
          ? { width: size.height, height: size.width }
          : size,
        (360 - rotate) % 360
      );
    }
    return {
      object: arr,
      rotate: this.fabrics[fabricId].rotate,
      roi_crop: updatedCrop,
    };
  }

  getUpdatedCropInfo(canvas, fabricId) {
    const { size, rotate } = this.fabrics[fabricId];
    const scaleRatio =
      rotate === 90 || rotate === 270
        ? this.fabrics[fabricId].size.height / canvas.width
        : this.fabrics[fabricId].size.width / canvas.width;
    let rois = this.getAnnotations(canvas, "roi");
    this.fabrics[fabricId].crop = {};
    let crop = {};
    if (rois.length > 0) {
      let { id, top, left, width, height, scaleX, scaleY } = rois[0];
      top = Math.round(top * scaleRatio);
      left = Math.round(left * scaleRatio);
      width = Math.round(width * scaleX * scaleRatio);
      height = Math.round(height * scaleY * scaleRatio);
      // update crop in UI
      this.fabrics[fabricId].crop = {
        id,
        top,
        left,
        width,
        height,
      };

      crop = this.rotatebndbox(
        {
          xmin: left,
          ymin: top,
          xmax: left + width,
          ymax: top + height,
        },
        rotate === 90 || rotate === 270
          ? { width: size.height, height: size.width }
          : size,
        (360 - rotate) % 360
      );
    }
    return crop;
  }

  parseAnnotation(canvas, fabricId) {
    const { size, rotate } = this.fabrics[fabricId];
    const scaleRatio =
      rotate === 90 || rotate === 270
        ? this.fabrics[fabricId].size.height / canvas.width
        : this.fabrics[fabricId].size.width / canvas.width;
    let objects = this.getAnnotations(canvas, "annotation");
    this.fabrics[fabricId].object = {};

    const arr = objects.map((object) => {
      let {
        id,
        top,
        left,
        width,
        height,
        scaleX,
        scaleY,
        label,
        ocrText,
        type,
        cells,
      } = object;

      top = Math.round(top * scaleRatio);
      left = Math.round(left * scaleRatio);
      width = Math.round(width * scaleX * scaleRatio);
      height = Math.round(height * scaleY * scaleRatio);

      // update annotation list in UI
      this.fabrics[fabricId].object[object.id] = {
        id,
        label,
        ocrText,
        top,
        left,
        width,
        height,
        type,
        cells,
      };

      const obj = {};
      obj.name = label;
      obj.ocr_text = ocrText;
      obj.type = type;
      obj.cells = cells;
      // undoing the rotation and saving in DB
      obj.bndbox = this.rotatebndbox(
        {
          xmin: left,
          ymin: top,
          xmax: left + width,
          ymax: top + height,
        },
        rotate === 90 || rotate === 270
          ? { width: size.height, height: size.width }
          : size,
        (360 - rotate) % 360
      );
      return obj;
    });

    this.fillLabelCount();
    this.fillHeaderLabelCount();
    return {
      object: arr,
      rotate: this.fabrics[fabricId].rotate,
      roi_crop: this.getUpdatedCropInfo(canvas, fabricId, true),
    };
  }

  updateAnnotatedStateOfImage(canvas, fabricId) {
    const { object } = this.fabrics[fabricId];
    const newObjects = this.getAnnotations(canvas, "annotation");
    if (Object.keys(object).length === 0 && newObjects.length !== 0) {
      // added annos to the image
      const index = this.keysOfUnAnnotatedImages.indexOf(fabricId);
      this.keysOfUnAnnotatedImages.splice(index, 1);
      if (this.keysOfAnnotatedImages.indexOf(fabricId) === -1) {
        this.keysOfAnnotatedImages.push(fabricId);
      }
    } else if ((Object.keys(object).length !== 0) & (newObjects.length === 0)) {
      // removed annos from the image
      const index = this.keysOfAnnotatedImages.indexOf(fabricId);
      this.keysOfAnnotatedImages.splice(index, 1);
      if (this.keysOfUnAnnotatedImages.indexOf(fabricId) === -1) {
        this.keysOfUnAnnotatedImages.push(fabricId);
      }
    }
  }

  updateCanvasRotation(fabricId, rotationDiff) {
    if (rotationDiff === undefined) {
      rotationDiff = 0;
    }

    rotationDiff = (360 + rotationDiff) % 360;

    let { rotate = 0, size, object, crop } = this.fabrics[fabricId];
    let newRotation = (360 + rotate + rotationDiff) % 360;
    this.fabrics[fabricId].rotate = newRotation;

    const _size =
      rotate === 90 || rotate === 270
        ? { height: size.width, width: size.height }
        : size;
    for (var objectId in object) {
      let obj = { ...object[objectId] };
      let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
        {
          xmin: obj.left,
          ymin: obj.top,
          xmax: obj.left + obj.width,
          ymax: obj.top + obj.height,
        },
        _size,
        rotationDiff
      );
      obj.top = ymin;
      obj.left = xmin;
      obj.height = ymax - ymin;
      obj.width = xmax - xmin;
      this.fabrics[fabricId].object[objectId] = obj;
      if (obj.cells) {
        obj.cells.forEach((cell) => {
          let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
            cell,
            _size,
            rotationDiff
          );
          cell.xmin = xmin;
          cell.xmax = xmax;
          cell.ymin = ymin;
          cell.ymax = ymax;
        });
      }

      if (obj.grid_cells) {
        obj.grid_cells.forEach((cell) => {
          let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
            cell,
            _size,
            rotationDiff
          );
          cell.xmin = xmin;
          cell.xmax = xmax;
          cell.ymin = ymin;
          cell.ymax = ymax;
        });
      }
    }
    if (crop && crop.width !== 0) {
      let { xmin, ymin, xmax, ymax } = this.rotatebndbox(
        {
          xmin: crop.left,
          ymin: crop.top,
          xmax: crop.left + crop.width,
          ymax: crop.top + crop.height,
        },
        _size,
        rotationDiff
      );
      this.fabrics[fabricId].crop = {
        top: ymin,
        left: xmin,
        height: ymax - ymin,
        width: xmax - xmin,
      };
    }
    // calling this to reorient google annotations based on current rotation
    this.setFabricId(fabricId);
    return this.fabrics[fabricId];
  }

  rotatebndbox = (bndbox, size, rotate) => {
    if (!bndbox || bndbox === null) {
      return { xmin: 0, ymin: 0, xmax: 0, ymax: 0 };
    }
    let { xmin, xmax, ymin, ymax } = bndbox;
    const boxHeight = ymax - ymin,
      boxWidth = xmax - xmin;
    if (rotate === 90) {
      xmin = ymin;
      ymin = size.width - xmax;
      xmax = xmin + boxHeight;
      ymax = ymin + boxWidth;
    } else if (rotate === 180) {
      xmax = size.width - xmin;
      ymax = size.height - ymin;
      xmin = xmax - boxWidth;
      ymin = ymax - boxHeight;
    } else if (rotate === 270) {
      const temp = ymin;
      ymin = xmin;
      xmin = size.height - boxHeight - temp;
      xmax = xmin + boxHeight;
      ymax = ymin + boxWidth;
    }
    return { xmin, xmax, ymin, ymax };
  };

  makeSaveAnnotationRequest(fabricId, body) {
    fetch(
      "/api/v2/ObjectDetection/Model/" +
        AppStore.appId +
        "/Annotations/" +
        fabricId,
      {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  // undo rotation and save
  save_annotation_after_rotate(fabricId) {
    const body = this.parseAnnotationWithRotation(fabricId);
    this.makeSaveAnnotationRequest(fabricId, body);
  }

  // Save new annotation & update the counter
  save_annotation(canvas, fabricId) {
    this.updateAnnotatedStateOfImage(canvas, fabricId);
    const body = this.parseAnnotation(canvas, fabricId);
    this.makeSaveAnnotationRequest(fabricId, body);
  }

  updateDataByLabel(oldAnnotation, newAnnotation) {
    if (oldAnnotation) {
      let label = oldAnnotation.label;
      if (this.dataByLabel[label]) {
        this.dataByLabel[label] -= 1;
        console.log("deleting label");
      }
      for (let index in oldAnnotation.cells) {
        const { label } = oldAnnotation.cells[index];
        if (this.dataByHeaderLabel[label]) {
          this.dataByHeaderLabel[label] -= 1;
        }
      }
    }
    if (newAnnotation) {
      let label = newAnnotation.label;
      if (this.dataByLabel[label]) {
        this.dataByLabel[label] += 1;
      } else {
        this.dataByLabel[label] = 1;
      }
      for (let index in newAnnotation.cells) {
        const { label } = newAnnotation.cells[index];
        if (this.dataByHeaderLabel[label]) {
          this.dataByHeaderLabel[label] += 1;
        } else {
          this.dataByHeaderLabel[label] = 1;
        }
      }
    }
  }

  // Save new annotation & update the counter
  saveAnnotation(fabricId, annoId, data, width) {
    if (!this.fabrics[fabricId]) {
      this.insertNewFabricObject(fabricId, "", "");
    }
    if (!this.fabrics[fabricId].object) {
      this.fabrics[fabricId].object = { [annoId]: {} };
    }
    this.updateDataByLabel(this.fabrics[fabricId].object[annoId], data);
    this.fabrics[fabricId].object[annoId] = data;

    const body = this.parseAnnotationWithRotation(fabricId);
    this.makeSaveAnnotationRequest(fabricId, body);
  }

  // Delete new annotation & update the counter
  deleteAnnotation(fabricId, annoId, width) {
    if (!fabricId || !annoId) return;

    if (!this.fabrics[fabricId].object) {
      this.fabrics[fabricId].object = { [annoId]: {} };
    }
    this.updateDataByLabel(this.fabrics[fabricId].object[annoId], false);
    delete this.fabrics[fabricId].object[annoId];

    const body = this.parseAnnotationWithRotation(fabricId);
    this.makeSaveAnnotationRequest(fabricId, body);
  }

  // get Image Url
  getImageUrl(fabricId) {
    try {
      return this.fabrics[fabricId].filename;
    } catch (error) {
      return "";
    }
  }

  setResultJSON(json, output) {
    annotationStore.setLoader(false);
    if (
      output &&
      output.message === "Success" &&
      output.result &&
      output.result.length > 0 &&
      output.result[0].prediction.length > 0
    ) {
      this.result.output = [output];
    }
    this.result.JSON = json;
    return;
  }

  setSnackbar(message, state) {
    this.result.annotateSnackbar = state;
    this.result.message = message;
    return;
  }

  setDialog(message, state) {
    this.result.dialog = state;
    this.result.message = message;
    return;
  }

  setLoader(state) {
    this.result.loading = state;
  }

  // Load annotations from rest api
  getResultofImage = flow(function* (url) {
    try {
      annotationStore.setLoader(true);
      const postData = constructURLEncodedFormData({ urls: [url] });
      const data = yield fetch(
        "/api/v2/ObjectDetection/Model/" + AppStore.appId + "/LabelUrls/",
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          credentials: "include",
          method: "POST",
          body: postData,
        }
      )
        .then((d) => d.json())
        .then((data) => data); // yield instead of await
      // the asynchronous blocks will automatically be wrapped actions and can modify state

      if (url !== this.activeImage) {
        // this condition is triggered when the user clicks on a different image before the prediction for the current clicked image is completed
        // returning because the results for the previously clicked image will render on top of the last clicked image
        return;
      }
      annotationStore.setResultJSON(JSON.stringify(data, null, 4), data);
    } catch (error) {
      annotationStore.setLoader(false);
    }
  });

  // Create new rectangle object
  newObject(annoId, start, fabricId) {
    this.current.set("id", annoId);
    this.current.set("fabricId", fabricId);
    this.current.set("start", start);
    this.current.set("state", "drawing");
    this.current.set("ocrText", "");
  }

  setFetchingAnnotation = action((state) => {
    this.fetchingAnnotation = state;
  });

  setFabricId(fabricId) {
    this.current.set("fabricId", fabricId);
    // fetch text annotations of image
    if (!fabricId) return;
    this.googleOcr = [];
    this.setFetchingAnnotation(true);
    const url = `/api/v2/ObjectDetection/Model/${AppStore.appId}/Annotations/${fabricId}`;
    fetch(url)
      .then((response) => {
        this.setFetchingAnnotation(false);
        if (!response.ok) {
          throw new Error("Failed to fetch annotation by id");
        }
        return response.json();
      })
      .then((data) => {
        const fabrics = this.annotationsAdapter(data, 0, true);
        this.updateFabrics(fabrics);
        if (
          data.TextAnnotations &&
          data.TextAnnotations.length > 0 &&
          data.TextAnnotations[0].text_annotations
        ) {
          this.googleOcr = data.TextAnnotations[0].text_annotations.map(
            (object) => {
              object.id = uuidv1();
              const boundingBox = this.getBoundingBox(
                object,
                1,
                this.fabrics[fabricId]
              );
              return boundingBox;
            }
          );
        }
      })
      .catch(logger.captureException);
  }

  updateDialogPos(screenX, screenY, width, height) {
    const elem =
      document.compatMode === "CSS1Compat"
        ? document.documentElement
        : document.body;
    if (elem.clientHeight - screenY < 250) {
      screenY = elem.clientHeight - 250;
    }
    this.current.set("dialogX", screenX);
    this.current.set("dialogY", screenY);
    if (width && height) {
      this.current.set("width", width);
      this.current.set("height", height);
    }
  }

  navigateError(message, newPage) {
    this.result.annotateSnackbar = true;
    this.result.message = message;
    this.setDialog(message, true);
    userStore.setGlobalLoader(false);
    setTimeout(() => {
      let modelType = window.location.href.split("/")[4];
      window.location.href = `/#/${modelType}/${newPage}/${AppStore.appId}`;
    }, 4000);
  }

  testUpload = async ([file]) => {
    if (!file) {
      return;
    }
    annotationStore.setLoader(true);
    const that = this;
    const url = `/api/v2/ObjectDetection/Model/${AppStore.appId}/LabelFile/`;
    var form = new FormData();
    form.append("file", file);
    try {
      const response = await fetch(url, {
        method: "POST",
        body: form,
        credentials: "same-origin",
      });
      const resp = await response.json();
      if (file.preview !== this.activeImage) {
        // this condition is triggered when the user uploads another image before the prediction for the current uploaded image is completed
        // returning because the results for the previously uploaded image will render on top of the last uploaded image
        return;
      }
      if (resp.code !== 200) {
        that.setSnackbar(resp.message, true);
      }
      that.setResultJSON(JSON.stringify(resp, null, 4), resp);
    } catch (error) {
      annotationStore.setLoader(false);
    }
  };

  resetObject() {
    this.current.set("id", "");
    this.current.set("start", null);
    this.current.set("state", null);
    this.current.set("label", "");
    this.current.set("fabricId", null);
    this.current.set("ocrText", "");
  }

  // Set state
  setState(state) {
    this.current.set("state", state);
  }

  setCurrentId(annoId, fabricId, label, ocrText) {
    this.current.set("id", annoId);
    this.current.set("fabricId", fabricId);
    this.current.set("label", label);
    this.current.set("state", "moving");
    this.current.set("ocrText", ocrText);
  }

  setEnd(end) {
    this.current.set("end", end);
    this.current.set("state", "drawn");
  }

  setLabel(label) {
    this.current.set("label", label);
    this.current.set("state", "labeled");
  }

  setOcrText(ocrText) {
    this.current.set("ocrText", ocrText);
  }

  setShowOCRTextHoverBox(flag) {
    this.current.set("showOCRTextHoverBox", flag);
  }

  setShowOCRTextInputBox(value, ocrText) {
    this.current.set("showOCR", value);
  }

  setCanvasSize(fabricId, height, width) {
    if (this.fabrics[fabricId]) {
      this.fabrics[fabricId].canvasSize = { height, width };
    }
  }

  changeImagesFilter(value) {
    this.imagesFilter = value;
    this.reorderedKeysToDisplay(true);
  }

  changeSortState(value) {
    this.sortByState = value;
    this.reorderedKeysToDisplay(true);
  }

  reorderedKeysToDisplay(updateFabric) {
    if (this.sortByState === "descending") {
      if (this.imagesFilter === "all") {
        this.keysToDisplay = this.keysSortedByUploadTime
          .slice()
          .sort((a, b) => this.fabrics[b].position - this.fabrics[a].position);
      } else if (this.imagesFilter === "annotated") {
        this.keysToDisplay = this.keysOfAnnotatedImages
          .slice()
          .sort((a, b) => this.fabrics[b].position - this.fabrics[a].position);
      } else {
        this.keysToDisplay = this.keysOfUnAnnotatedImages
          .slice()
          .sort((a, b) => this.fabrics[b].position - this.fabrics[a].position);
      }
    } else {
      if (this.imagesFilter === "annotated") {
        this.keysToDisplay = this.keysOfAnnotatedImages
          .slice()
          .sort((a, b) => this.fabrics[a].position - this.fabrics[b].position);
      } else if (this.imagesFilter === "unannotated") {
        this.keysToDisplay = this.keysOfUnAnnotatedImages
          .slice()
          .sort((a, b) => this.fabrics[a].position - this.fabrics[b].position);
      } else {
        this.keysToDisplay = this.keysSortedByUploadTime;
      }
    }
  }

  updateFabricAnnotations(fabricId, boxes) {
    if (!this.fabrics[fabricId]) {
      console.log("Fabric id doesnt exist..");
      this.insertNewFabricObject(fabricId, "", "");
    }
    console.log(this.fabrics[fabricId]);
    this.fabrics[fabricId].object = {};
    boxes.forEach((box) => {
      let data = {
        width: box.xmax - box.xmin,
        top: box.ymin,
        left: box.xmin,
        height: box.ymax - box.ymin,
        label: box.label,
        ocrText: box.ocr_text,
        cells: box.cells,
        grid_cells: box.grid_cells,
        type: box.type,
        id: box.id,
      };
      this.fabrics[fabricId].object[box.id] = data;
    });
  }
}

decorate(AnnotationStore, {
  annotationCount: observable,
  annotated_count: observable,
  actualAnnotationCount: observable,
  fabrics: observable,
  annoLabels: observable,
  ModalState: observable,
  fetchingAnnotation: observable,
  keysSortedByUploadTime: observable,
  keysOfAnnotatedImages: observable,
  keysOfUnAnnotatedImages: observable,
  current: observable,
  activeModel: observable,
  result: observable,
  activeImage: observable,
  activeImageSize: observable,
  labelCount: observable,
  headerLabelCount: observable,
  sortByState: observable,
  imagesFilter: observable,
  keysToDisplay: observable,
  dataByLabel: observable,
  dataByHeaderLabel: observable,
  signedUrls: observable,
  headerLabelAnnotationCount: computed,
  ocrText: computed,
  firstImageSrc: computed,
  totalAnnosKeys: computed,
  labels: computed,
  labelsOptions: computed,
  labelsMap: computed,
  modalState: computed,
  showOCRTextBox: computed,
  showOCRTextHoverBox: computed,
  annotationsAdapter: action,
  setImageSize: action,
  setFabricId: action,
  setFetchingAnnotation: action,
  removeKey: action,
  emptyResult: action,
  updateActiveImage: action,
  setAnnotationKeys: action,
  clearOldAnnotations: action,
  fillLabelCount: action,
  fillHeaderLabelCount: action,
  updateFabrics: action,
  insertNewFabricObject: action,
  updateCanvasRotation: action,
  save_annotation: action,
  save_annotation_after_rotate: action,
  saveAnnotation: action,
  deleteAnnotation: action,
  getImageUrl: action,
  setResultJSON: action,
  setSnackbar: action,
  setDialog: action,
  setLoader: action,
  newObject: action,
  updateDialogPos: action,
  setOcrText: action,
  setShowOCRTextHoverBox: action,
  navigateError: action,
  testUpload: action,
  resetObject: action,
  setState: action,
  setCurrentId: action,
  setEnd: action,
  setLabel: action,
  setShowOCRTextInputBox: action,
  setCanvasSize: action,
  updateAnnotatedStateOfImage: action,
  changeImagesFilter: action,
  changeSortState: action,
  reorderedKeysToDisplay: action,
  getAnnotations: action,
  updateFabricAnnotations: action,
  updateAnnotationCount: action,
  updateTableAnnotationCount: action,
});

const annotationStore = new AnnotationStore();

export default annotationStore;
