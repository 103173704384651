import React from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import { useStores } from "hooks/use-stores";

const ProTrialDialog = () => {
  const classes = useStyles();
  const { appStore } = useStores();

  return (
    <div>
      <Dialog open={appStore.isProTrialStartedDialogOpen}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={appStore.closeProTrialStartedDialog}
        >
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
        <div style={{ padding: 40 }}>
          <div style={{ maxWidth: 420, textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/media/party-blower.svg`}
              alt=""
              height="80"
            />
            <p>
              <strong>Your Pro Plan has begun!</strong>
            </p>
            <p style={{ color: "#66788C", fontSize: 12 }}>
              Your Pro Plan has started. We’re excited to have you on board, if
              there’s absolutely anything we can help with, feel free to reach
              out.
            </p>
          </div>
        </div>
      </Dialog>

      <Dialog open={appStore.isProTrialCancelledDialogOpen}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={appStore.closeProTrialCancelledDialog}
        >
          <CloseIcon style={{ fontSize: 18 }} />
        </IconButton>
        <div style={{ padding: 40 }}>
          <div style={{ maxWidth: 420, textAlign: "center" }}>
            <img
              src={`${process.env.PUBLIC_URL}/media/cancel-2.svg`}
              alt=""
              height="80"
            />
            <p>
              <strong>Pro Plan discontinued for your model</strong>
            </p>
            <p style={{ color: "#66788C", fontSize: 12 }}>
              You won't be able to access the pro plan features on your model.
              Continue building models on your free account and Upgrade whenever
              you need to!
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles({
  closeButton: {
    position: "absolute",
    top: 4,
    right: 4,
  },
});

export default observer(ProTrialDialog);
