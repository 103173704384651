import { FC } from "react";
import { Alert } from "@material-ui/lab";
import { Button } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { accessDeniedIcon } from "imageUrls";
import { toast as toastify, TypeOptions } from "react-toastify";

type Props = {
  onClose: any;
  message: string | React.ReactNode;
};
const CustomToastForAccessDenied: FC<Props> = ({ message, onClose }) => {
  return (
    <div
      style={{
        width: 360,
        height: 200,
        borderRadius: 8,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <img
            src={accessDeniedIcon}
            alt=""
            style={{ marginBottom: 12, height: 28 }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Close
            style={{ color: "#94A1AF", fontSize: 20, cursor: "pointer" }}
            onClick={onClose}
          />
        </div>
      </div>

      <div
        style={{
          color: "#1C1D1C",
          fontSize: 14,
          fontWeight: 600,
          width: "100%",
          textAlign: "center",
        }}
      >
        Access denied for this operation
      </div>
      <div
        style={{
          color: "#354454",
          fontSize: 12,
          fontWeight: 400,
          marginTop: 8,
          marginBottom: 16,
          width: "100%",
          textAlign: "center",
        }}
      >
        {message}
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          onClick={onClose}
          style={{
            color: "#354454",
            fontWeight: 500,
            fontSize: 12,
            width: 92,
            height: 40,
            padding: 12,
            borderRadius: 4,
            background: "#ffffff",
            border: "1px solid #E0E3E8",
          }}
        >
          Okay, got it
        </Button>
      </div>
    </div>
  );
};

const createToastHandler =
  (type: Exclude<TypeOptions, "default">) =>
  (message: string | React.ReactNode) =>
    toastify((props) =>
      String(message).startsWith(
        "Sorry your role doesn't have permission to perform that operation"
      ) ? (
        <CustomToastForAccessDenied
          onClose={props.closeToast}
          message={message}
        />
      ) : (
        <Alert severity={type} onClose={props.closeToast}>
          {message}
        </Alert>
      )
    );

export const toast = {
  success: createToastHandler("success"),
  error: createToastHandler("error"),
  info: createToastHandler("info"),
  warning: createToastHandler("warning"),
};
