import { QueryClient } from "react-query";

const options = {
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
};

const queryClient = new QueryClient(options);

export default queryClient;
