import { makeStyles, IconButton, Dialog, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { switchTeamIcon } from "imageUrls";

const SwitchTeamDialog = ({ open, onClose, teamInfo, switchTeam }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.mainDiv}>
        <div className={classes.closeButton}>
          <IconButton onClick={onClose}>
            <Close style={{ fontSize: 18 }} />
          </IconButton>
        </div>
        <div className={classes.body}>
          <img src={switchTeamIcon} alt="" />
          <div
            style={{
              marginTop: 40,
              marginBottom: 8,
              fontSize: 18,
              fontWeight: 600,
              color: "#1C1D1C",
            }}
          >
            You're in a Team!
          </div>
          <div style={{ fontSize: 12 }}>
            You've accepted the invite to{" "}
            <span style={{ color: "#546FFF" }}>{teamInfo?.name}</span> (owned by{" "}
            {teamInfo?.owner_email}).
          </div>
          <div>Click on button below to start colloborating</div>
          <div
            style={{
              fontSize: 14,
              color: "#6D7175",
              fontWeight: 400,
              marginTop: 30,
              marginBottom: 12,
            }}
          >
            {teamInfo?.name}
          </div>
          <Button onClick={switchTeam} variant="contained" color="primary">
            View Team Account
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
const useStyles = makeStyles({
  mainDiv: {
    padding: 12,
    width: 600,
    height: 420,
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  body: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    color: "#6D7175",
    fontSize: 14,
    fontWeight: 400,
    flexDirection: "column",
    justifyContent: "center",
  },
  inviteInfo: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
export default SwitchTeamDialog;
