import { cloneDeep } from "lodash";
import { CustomResponse } from "types/inference";
import { getTimeInt } from "Utils";
import { v1 } from "uuid";

type BoundingBox = { xmin: number; xmax: number; ymin: number; ymax: number };

type Size = { height: number; width: number };

type Box1 = BoundingBox & {
  type: "" | "table" | "derived";
  cells?: BoundingBox[];
  grid_cells?: BoundingBox[];
};

export const rotateBox = <T extends BoundingBox>(
  box: T,
  size: Size,
  rotateBy: number // clockwise rotation
) => {
  if (!rotateBy || !size) {
    return box;
  }

  let { xmin, xmax, ymin, ymax } = box;
  const { width = 0, height = 0 } = size;
  const prev_ymin = ymin;
  const prev_ymax = ymax;
  const prev_xmin = xmin;
  const prev_xmax = xmax;

  if (rotateBy === 90) {
    ymin = prev_xmin;
    ymax = prev_xmax;
    xmin = height - prev_ymax;
    xmax = height - prev_ymin;
  } else if (rotateBy === 180) {
    ymin = height - prev_ymax;
    ymax = height - prev_ymin;
    xmin = width - prev_xmax;
    xmax = width - prev_xmin;
  } else if (rotateBy === 270) {
    ymin = width - prev_xmax;
    ymax = width - prev_xmin;
    xmin = prev_ymin;
    xmax = prev_ymax;
  }

  return { ...box, xmin, xmax, ymin, ymax };
};

export const rotateBoxes = <T extends Box1>(
  boxes: T[],
  size: Size,
  rotationDiff = 0
) => {
  if (!rotationDiff || !size) {
    return boxes;
  }

  const newBoxes = cloneDeep(boxes);

  newBoxes?.forEach((box, i) => {
    const boundingBox = rotateBox(box, size, rotationDiff);
    newBoxes[i] = {
      ...newBoxes[i],
      ...boundingBox,
    };

    if (box.type === "table") {
      box?.grid_cells?.forEach((cell, i, cells) => {
        const boundingBox = rotateBox(cell, size, rotationDiff);
        cells[i] = {
          ...cells[i],
          ...boundingBox,
        };
      });

      box?.cells?.forEach((cell, i, cells) => {
        const boundingBox = rotateBox(cell, size, rotationDiff);
        cells[i] = {
          ...cells[i],
          ...boundingBox,
        };
      });
    }
  });

  return newBoxes;
};

export const getBoundingBoxText = (
  inputBox: BoundingBox,
  ocrBoxes: (BoundingBox & {
    ocr_text?: string;
    text?: string;
  })[]
) => {
  const box = {
    xmin: Number(inputBox.xmin),
    xmax: Number(inputBox.xmax),
    ymin: Number(inputBox.ymin),
    ymax: Number(inputBox.ymax),
  };
  let { xmin, xmax, ymin, ymax } = box;
  let text = "";

  if (ocrBoxes && ocrBoxes.length > 0) {
    const boxes = ocrBoxes.filter((box) => {
      const x_center = (box.xmin + box.xmax) / 2;
      const y_center = (box.ymin + box.ymax) / 2;

      return (
        x_center >= xmin &&
        x_center <= xmax &&
        y_center >= ymin &&
        y_center <= ymax
      );
    });

    if (boxes.length > 0) {
      xmin = Math.min(...boxes.map((box) => box.xmin));
      xmax = Math.max(...boxes.map((box) => box.xmax));
      ymin = Math.min(...boxes.map((box) => box.ymin));
      ymax = Math.max(...boxes.map((box) => box.ymax));

      text = boxes
        .map((box, i) => {
          let text = box.ocr_text || box.text || "";
          const nextBox = boxes[i + 1];
          if (nextBox) {
            if (nextBox.ymin > box.ymin && nextBox.ymin - box.ymax > -5) {
              text += " \n";
            } else {
              text += " ";
            }
          }

          return text;
        })
        .join("");
    }
  }

  return {
    xmin,
    xmax,
    ymin,
    ymax,
    text,
  };
};

export const getBoxes = (result: {
  id: string;
  prediction: any[];
  predicted_boxes: any[];
  moderated_boxes: any[];
  day_since_epoch: number;
  hour_of_day: number;
  updated_at: string;
}) => {
  const {
    id,
    moderated_boxes,
    predicted_boxes,
    day_since_epoch,
    hour_of_day,
    prediction,
    updated_at,
  } = result;

  let uploadedAt;
  try {
    uploadedAt = getTimeInt(id);
  } catch (e) {
    uploadedAt = (day_since_epoch * 24 + hour_of_day) * 3600 * 1000;
  }

  let updatedAt;
  try {
    updatedAt = getTimeInt(updated_at);
  } catch (e) {
    updatedAt = uploadedAt;
  }

  let boxes = [];
  if (updatedAt > uploadedAt) {
    boxes = moderated_boxes || [];
  } else {
    // Custom models have predicted_boxes as [], hence using prediction
    boxes = (predicted_boxes?.length > 0 ? predicted_boxes : prediction) || [];
  }
  return boxes;
};

export const assignIdsToBoxes = (result: {
  prediction: any[];
  predicted_boxes: any[];
  moderated_boxes: any[];
  custom_response: CustomResponse[] | null;
}) => {
  const assignIds = (
    boxes: {
      id: string;
      cells: any[];
      grid_cells: any[];
    }[]
  ) => {
    boxes?.forEach((box) => {
      if (!box.id || box.id.length < 36) box.id = v1();

      if (!box.grid_cells) {
        box.grid_cells = cloneDeep(box.cells);
      }

      box?.cells?.forEach((cell) => {
        if (!cell.id || cell.id.length < 36) cell.id = v1();
      });

      box?.grid_cells?.forEach((cell) => {
        if (!cell.id || cell.id.length < 36) cell.id = v1();
      });
    });
  };

  assignIds(result?.prediction);
  assignIds(result?.predicted_boxes);
  assignIds(result?.moderated_boxes);

  result?.custom_response?.forEach((item) => {
    if (!item.id || item.id.length < 36) item.id = v1();

    item?.elements?.forEach((element) => {
      if (!element.id || element.id.length < 36) element.id = v1();
    });

    item?.extras?.forEach((extra) => {
      if (!extra.id || extra.id.length < 36) extra.id = v1();

      extra?.options?.forEach((option) => {
        option?.elements?.forEach((element) => {
          if (!element.id || element.id.length < 36) element.id = v1();
        });
      });
    });
  });

  return result;
};
