import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "App";
import Providers from "Providers";
import Intercom from "components/Intercom";
import { HashRouter as Router } from "react-router-dom";
import posthog from "posthog-js";
posthog.init("h0AAz0T8UM_liYI4aQREM7VdMDGRLF8B1YbZCeuBUww", {
  api_host: "https://events.nanonets.com",
});

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_MOCK_SERVER === "true"
) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

ReactDOM.render(
  <Router>
    <Providers>
      <App />
      <Intercom />
    </Providers>
  </Router>,
  document.getElementById("root")
);
