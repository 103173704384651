import { Provider as MobXProvider } from "mobx-react";
import { observer } from "mobx-react-lite";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "queryClient";
import {
  MuiThemeProvider,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { annotationStore, appStore, userStore } from "stores";
import { StoreProvider } from "hooks/use-stores";

import theme from "./styles/theme";

const Providers = observer(({ children }) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <MobXProvider
              appStore={appStore}
              AppStore={appStore}
              userStore={userStore}
              UserStore={userStore}
              annotationStore={annotationStore}
            >
              <StoreProvider>{children}</StoreProvider>
            </MobXProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
});

export default Providers;
