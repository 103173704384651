import {
  observable,
  configure,
  action,
  decorate,
  runInAction,
  computed,
} from "mobx";
import AppStore from "./AppStore";
// import moment from "moment";
import Batch from "batch";
import * as Sentry from "@sentry/react";
import logger from "Utils/logger";
import { SUPPORTED_INTEGRATIONS } from "Utils/integrations";
import { toast } from "Utils/toast";

configure({ enforceActions: "observed" });

// would normally go in src/stores/...
export class UserStore {
  userLoaded = false;
  user = {};
  isAdmin = false;
  isAdminUserAliasedAsOtherUser = false;
  profile = {};
  models = [];
  state = "pending";
  path = "/";
  labels = ["Label 1"];
  uploads = new Map();
  uploadStats = new Map();
  alreadyUploaded = [];
  successKeys = [];
  globalLoader = true;
  openTrainDialog = false;
  isDrawerVisible = true;
  isHeaderVisible = true;
  integrations = [];
  userExports = {};
  tablesByIntegration = {};

  get auth() {
    return this.profile.EmailId ? true : false;
  }

  setUser = action((user) => (this.user = user));

  showDrawer = () => {
    this.isDrawerVisible = true;
  };
  hideDrawer = () => {
    this.isDrawerVisible = false;
  };

  showHeader = () => {
    this.isHeaderVisible = true;
  };

  hideHeader = () => {
    this.isHeaderVisible = false;
  };

  setOpenTrainDialog(open) {
    this.openTrainDialog = open;
  }

  setSnackbar(msg, state) {
    this.globalSnackBar.message = msg;
    this.globalSnackBar.open = state;
  }

  setGlobalLoader(state) {
    this.globalLoader = state;
  }

  clearUploadStore() {
    this.alreadyUploaded = [];
    this.successKeys = [];
    this.uploads.clear();
    this.uploadStats.clear();
  }

  addUploads(files, source, existingUploads) {
    // This method is used to store images from Upload Screen and annotations from Upload Annotations Screen
    files.forEach((file) => {
      const key = [...Array(30)]
        .map(() => Math.random().toString(36)[3])
        .join("");
      this.uploads.set(key, file);
      this.uploadStats.set(key, "queue");
    });
    userStore.startUpload(source, existingUploads);
  }

  setStatus(key, status) {
    this.uploadStats.set(key, status);
  }

  updateOldUploads(data) {
    this.alreadyUploaded = data;
  }

  addSuccessKey(key) {
    this.successKeys.push(key);
  }

  getOldUploads = async () => {
    try {
      const response = await fetch(
        "/api/v2/ObjectDetection/Model/" + AppStore.appId + "/Annotations/",
        {
          credentials: "include",
          method: "GET",
        }
      );
      const resp = await response.json();
      if (!resp.code) {
        userStore.setGlobalLoader(false);
        this.updateOldUploads(resp.Data);
      } else {
        this.setSnackbar(
          "Model is not found. You will be redirected to your models soon.",
          true
        );
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    } catch (error) {
      logger.captureException(error);
      userStore.setGlobalLoader(false);
    }
  };

  startUpload = async (source, existingUploads) => {
    // the source and existingUploads are used when the request is coming from UploadAnnotations screen
    const that = this;
    let url = `/ObjectDetectionUpload/?appId=${AppStore.appId}`;
    const batch = new Batch();
    userStore.setGlobalLoader(false);
    batch.concurrency(5);
    this.uploadStats.forEach((stat, key, map) => {
      if (
        stat.status === "uploaded" ||
        stat.status === "error" ||
        stat.status === "uploading"
      )
        return;
      // set the stat to uploading and then try uploading
      map.set(key, { status: "uploading", message: "uploading" });
      batch.push(async (done) => {
        var form = new FormData();
        form.append("file", that.uploads.get(key));
        try {
          const response = await fetch(
            source === "annotations"
              ? `/api/v2/ObjectDetection/Model/${AppStore.appId}/Annotations/${
                  that.uploads.get(key).dataId
                }/Upload`
              : url,
            {
              method: "POST",
              body: form,
              credentials: "same-origin",
            }
          );
          let respData = await response.json();
          if (response.status >= 400) {
            if (source === "annotations") {
              if (respData.errors && respData.errors.length > 0) {
                that.setStatus(key, {
                  status: "error",
                  message: respData.errors[0].reason || "Interal Error",
                });
                return done(null, key);
              }
            }
            that.setStatus(key, respData.upload_status_files[0]);
            done(null, key);
          } else {
            that.setStatus(key, { status: "uploaded", message: "uploaded" });
            if (source === "annotations") {
              // adding keys only if the image has no existing annotations
              const dataId = that.uploads.get(key).dataId;
              const fabric = existingUploads[dataId];
              if (Object.keys(fabric.object).length === 0) {
                that.addSuccessKey(key);
              }
            } else {
              that.addSuccessKey(key);
            }
            done(null, key);
          }
        } catch (error) {
          logger.captureException(error);
          that.setStatus(key, { status: "error", message: "Interal Error" });
          done(null, key);
        }
      });
    });

    batch.on("progress", function (response) {
      if (response && response.error !== null) {
        console.log(response);
      }
    });

    batch.end(function (err, users) {});
  };

  reset = action(() => {
    this.user = {};
  });

  getUser = action((fetchSilently) => {
    this.state = "pending";
    if (!fetchSilently) this.setGlobalLoader(true);
    fetch(`/user/jsonprofile/`, {
      credentials: "include",
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch user profile"))
      )
      .then((user) => {
        runInAction(() => {
          this.state = "done";
          this.user = user;
          this.profile = user;

          Sentry.setUser({ email: user.UserProfile.email });
          if (
            user.UserProfile &&
            user.UserProfile.email.endsWith("@nanonets.com") &&
            user.UserProfile.identities &&
            user.UserProfile.identities[0].isSocial
          ) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
          if (
            user.UserProfile &&
            user.UserProfile.is_user_an_alias &&
            user.UserProfile.original_email.endsWith("@nanonets.com")
          ) {
            this.isAdminUserAliasedAsOtherUser = true;
          }
          this.setGlobalLoader(false);
        });
      })
      .catch(() => {
        runInAction(() => {
          this.user = {};
          this.profile = {};
          this.state = "error";
          this.setGlobalLoader(false);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.userLoaded = true;
        });
      });
  });

  getModels = (email) => {
    let formData = new FormData();
    formData.set("email", email);

    return fetch("/models", {
      method: "post",
      credentials: "include",
      body: formData,
    }).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error("Failed to fetch user models"))
    );
  };

  getUserExports = () => {
    fetch("/api/v2/externalIntegrations/all-user-exports", {
      method: "GET",
      credentials: "include",
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch user model export infos"))
      )
      .then((data) => {
        runInAction(() => {
          this.userExports = data;
        });
      })
      .catch(logger.captureException);
  };

  getIntegrations = (fetchDeps) => {
    this.setGlobalLoader(true);
    fetch("/api/v2/externalIntegrations", {
      method: "GET",
      credentials: "include",
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch integrations"))
      )
      .then((data) => {
        runInAction(() => {
          this.integrations = data;
        });
        this.setGlobalLoader(false);
        if (fetchDeps) this.getTablesForDBIntegrations();
      })
      .catch(logger.captureException);
  };

  updateIntegrations = action((integrations) => {
    this.integrations = integrations;
  });

  updateIntegration = action((index, integration) => {
    this.integrations[index] = integration;
  });

  getTablesForDBIntegrations() {
    this.dBIntegrations.forEach((i) => {
      if (
        SUPPORTED_INTEGRATIONS[i.type] &&
        SUPPORTED_INTEGRATIONS[i.type].dependenciesExist
      ) {
        fetch(`/api/v2/externalIntegrations/${i.id}/tables`, {
          credentials: "include",
          method: "GET",
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error("Error occured while getting tables");
            }
            return res.json();
          })
          .then((data) => {
            runInAction(() => {
              data.forEach((table) => {
                this.tablesByIntegration[i.id] = {
                  [table]: {},
                };
                this.getColumnsForTable(i.id, table);
              });
            });
          })
          .catch((err) => {
            logger.captureException(err);
          });
      }
    });
  }

  getColumnsForTable(integrationId, table) {
    fetch(
      `/api/v2/externalIntegrations/${integrationId}/tableschema?table_name=${table}`,
      {
        credentials: "include",
        method: "GET",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error("Error occured while getting table schema");
        }
        return res.json();
      })
      .then((data) => {
        runInAction(() => {
          this.tablesByIntegration[integrationId][table] = data;
        });
      })
      .catch((err) => {
        logger.captureException(err);
      });
  }

  get dBIntegrations() {
    return this.integrations.filter(
      (integration) =>
        ["sql", "mssql", "mariadb", "postgresql"].indexOf(integration.type) !==
        -1
    );
  }

  // get onboarding() {
  //   const accountCreatedAt = this.user.UserProfile.created_at;
  //   const daysSinceAccountCreated = moment().diff(
  //     moment(accountCreatedAt),
  //     "days"
  //   );
  //   return daysSinceAccountCreated < 7;
  // }

  get canCreateNewModel() {
    if (this.isAdmin || !this.user.PricingFeatureAllowed) {
      return true;
    }
    if (this.user.DoesAccountHavePaidModels) {
      return true;
    }
    if (this.user.Apps.length < this.user.NumberOfFreeModels) {
      return true;
    }
    return false;
  }

  get modelsByIntegration() {
    let map = {};
    let modelsMap = {};
    this.user.Apps.forEach((app) => {
      modelsMap[app.app_id] = app;
    });

    for (let modelId in this.userExports) {
      this.userExports[modelId] &&
        this.userExports[modelId].forEach((linkedExport) => {
          if (linkedExport.integration_id) {
            if (!map[linkedExport.integration_id]) {
              map[linkedExport.integration_id] = [];
            }
            const appsFound = map[linkedExport.integration_id].filter(
              (linkedApp) => linkedApp.app_id === modelId
            );
            if (appsFound.length === 0) {
              map[linkedExport.integration_id].push(modelsMap[modelId]);
            }
          }
        });
    }
    return map;
  }

  updateOnboardingFlags = (flags, onSuccess, onError) => {
    fetch("/user/onboarding", {
      method: "PUT",
      body: JSON.stringify(flags),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to save changes");
        return response.json();
      })
      .then((data) => {
        this.setOnboardingFlags(data);
        onSuccess && onSuccess(data);
      })
      .catch((error) => {
        onError && onError(error);
      });
  };

  setOnboardingFlags = (flags) => {
    this.user = {
      ...this.user,
      OnboardingFlags: flags,
    };
  };

  updateWhiteLabeledDomain = () => {
    fetch("/api/v2/miscroutes/whitelabeleddomains", {
      method: "POST",
      body: JSON.stringify({ domain: this.user.WhitelabeledDomain }),
    })
      .then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while saving changes");
        }

        return data;
      })
      .then(() => {
        AppStore.openAlert({
          message: "Successfully saved changes",
          level: "success",
        });
      })
      .catch((error) => toast.error(error.message));
  };

  setWhiteLabeledDomain = (domain) => {
    this.user = {
      ...this.user,
      WhitelabeledDomain: domain,
    };
  };
}

decorate(UserStore, {
  isAdmin: observable,
  userLoaded: observable,
  user: observable,
  profile: observable,
  models: observable,
  state: observable,
  path: observable,
  labels: observable,
  uploads: observable,
  uploadStats: observable,
  alreadyUploaded: observable,
  successKeys: observable,
  globalLoader: observable,
  openTrainDialog: observable,
  isDrawerVisible: observable,
  isHeaderVisible: observable,
  integrations: observable,
  userExports: observable,
  tablesByIntegration: observable,
  // onboarding: computed,
  canCreateNewModel: computed,
  modelsByIntegration: computed,
  dBIntegrations: computed,
  showDrawer: action,
  hideDrawer: action,
  showHeader: action,
  hideHeader: action,
  setSnackbar: action,
  setGlobalLoader: action,
  clearUploadStore: action,
  addUploads: action,
  setStatus: action,
  updateOldUploads: action,
  addSuccessKey: action,
  getOldUploads: action,
  startUpload: action,
  setOpenTrainDialog: action,
  auth: computed,
  getModels: action,
  updateIntegrations: action,
  updateIntegration: action,
  updateOnboardingFlags: action,
  setOnboardingFlags: action,
  setWhiteLabeledDomain: action,
});

const userStore = new UserStore();

export default userStore;
