import { ModelType2 } from "types/Model";

// model states and their KEY
const MODEL_STATE = {
  CREATED: 0,
  UPLOADED: 1,
  ANNOTATED: 2,
  TRAINING_IN_QUEUE: 3,
  TRAINING_IN_PROGRESS: 4,
  TRAINED: 5,
  RETRAINING_IN_PROGRESS: 6,
};

export type ModelRoute = {
  name: string;
  to: (modelId: string) => string;
  minModelStateRequired: number;
  groupName: string;
};

const stagesList: Record<ModelType2, ModelRoute[]> = {
  ic: [
    {
      name: "Manage Labels",
      to: (modelId: string) => `/ic/labels/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Upload Images",
      to: (modelId: string) => `/ic/upload/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Training Session",
      to: (modelId: string) => `/ic/confirm/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Build",
    },
    {
      name: "Model Versions",
      to: (modelId: string) => `/ic/versions/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Extract Data",
    },
    {
      name: "Extract Data",
      to: (modelId: string) => `/ic/extract/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/ic/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Usage Stats",
      to: (modelId: string) => `/ic/analyze/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Review",
      to: (modelId: string) => `/ic/review/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
  ],
  mlc: [
    {
      name: "Add Labels",
      to: (modelId: string) => `/mlc/create/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Upload Images",
      to: (modelId: string) => `/mlc/upload/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Annotate",
      to: (modelId: string) => `/mlc/annotate/${modelId}`,
      minModelStateRequired: MODEL_STATE.UPLOADED,
      groupName: "Build",
    },
    {
      name: "Model Metrics",
      to: (modelId: string) => `/mlc/confirm/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Extract Data",
    },
    {
      name: "Test",
      to: (modelId: string) => `/mlc/test/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/mlc/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
  ],
  od: [
    {
      name: "Upload Images",
      to: (modelId: string) => `/od/upload/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Annotate",
      to: (modelId: string) => `/od/annotate/${modelId}`,
      minModelStateRequired: MODEL_STATE.UPLOADED,
      groupName: "Build",
    },
    {
      name: "Model Metrics",
      to: (modelId: string) => `/od/confirm/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Model Versions",
      to: (modelId: string) => `/od/versions/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Extract Data",
    },
    {
      name: "Test",
      to: (modelId: string) => `/od/test/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/od/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Usage Stats",
      to: (modelId: string) => `/od/analyze/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Review",
      to: (modelId: string) => `/od/review/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
  ],
  ocr: [
    {
      name: "Manage Labels",
      to: (modelId: string) => `/ocr/labels/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Show Examples",
      to: (modelId: string) => `/ocr/annotate/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Training Session",
      to: (modelId: string) => `/ocr/confirm/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Build",
    },
    {
      name: "Model Versions",
      to: (modelId: string) => `/ocr/versions/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINING_IN_QUEUE,
      groupName: "Extract Data",
    },
    {
      name: "Extract Data",
      to: (modelId: string) => `/ocr/test/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Python Postprocessing",
      to: (modelId: string) => `/ocr/postprocess/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/ocr/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Usage Stats",
      to: (modelId: string) => `/ocr/analyze/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Import",
      to: (modelId: string) => `/workflow/import/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "AI Model",
      to: (modelId: string) => `/workflow/ai-model/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "Data Actions",
      to: (modelId: string) => `/workflow/enhance-data/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "Final Results",
      to: (modelId: string) => `/workflow/final-results/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "Approvals",
      to: (modelId: string) => `/workflow/approvals/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "Export",
      to: (modelId: string) => `/workflow/export/?modelId=${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Workflow",
    },
    {
      name: "Model Settings",
      to: (modelId: string) => `/ocr/settings/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Settings",
    },
    {
      name: "Model Changelog",
      to: (modelId: string) => `/models/${modelId}/changelog`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "",
    },
  ],
  similarity: [
    {
      name: "Upload Images",
      to: (modelId: string) => `/similarity/upload/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Train",
      to: (modelId: string) => `/similarity/confirm/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Test",
      to: (modelId: string) => `/similarity/test/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/similarity/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
  ],
  custom: [
    {
      name: "Configure",
      to: (modelId: string) => `/custom/configure/${modelId}`,
      minModelStateRequired: MODEL_STATE.CREATED,
      groupName: "Build",
    },
    {
      name: "Test",
      to: (modelId: string) => `/custom/test/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
    {
      name: "Integrate",
      to: (modelId: string) => `/custom/integrate/${modelId}`,
      minModelStateRequired: MODEL_STATE.TRAINED,
      groupName: "Extract Data",
    },
  ],
};

const stateToStageMap = {
  ic: {
    0: 1, // CREATE SCREEN
    1: 1, // UPLOAD SCREEN
    2: 1, // UPLOAD SCREEN
    3: 2, // CONFIRM SCREEN
    4: 2, // CONFIRM SCREEN
    5: 4, // TEST SCREEN AND NEXT
    6: 4, // WHEN RETRAINING IS IN PROGRESS ALL SCREENS SHOULD BE ACCESSIBLE
  },
  mlc: {
    0: 1, // CREATE SCREEN
    1: 2, // ANNOTATE SCREEN
    2: 2, // ANNOTATE SCREEN
    3: 3, // CONFIRM SCREEN
    4: 3, // CONFIRM SCREEN
    5: 4, // TEST SCREEN AND NEXT
    6: 4, // WHEN RETRAINING IS IN PROGRESS ALL SCREENS SHOULD BE ACCESSIBLE
  },
  od: {
    0: 0, // UPLOAD SCREEN
    1: 1, // ANNOTATE SCREEN
    2: 1, // ANNOTATE SCREEN
    3: 2, // CONFIRM SCREEN
    4: 2, // CONFIRM SCREEN
    5: 4, // TEST SCREEN AND NEXT
    6: 4, // WHEN RETRAINING IS IN PROGRESS ALL SCREENS SHOULD BE ACCESSIBLE
  },
  ocr: {
    0: 0, // UPLOAD SCREEN
    1: 1, // ANNOTATE SCREEN
    2: 1, // ANNOTATE SCREEN
    3: 2, // CONFIRM SCREEN
    4: 2, // CONFIRM SCREEN
    5: 4, // TEST SCREEN AND NEXT
    6: 4, // WHEN RETRAINING IS IN PROGRESS ALL SCREENS SHOULD BE ACCESSIBLE
  },
  similarity: {
    0: 0, // CREATE SCREEN
    1: 0, // UPLOAD SCREEN
    2: 0, // ANNOTATE SCREEN
    3: 1, // CONFIRM SCREEN
    4: 1, // CONFIRM SCREEN
    5: 2, // TEST SCREEN AND NEXT
    6: 2, // WHEN RETRAINING IS IN PROGRESS ALL SCREENS SHOULD BE ACCESSIBLE
  },
  custom: {
    0: 1,
    5: 3,
  },
};

export { stagesList, stateToStageMap, MODEL_STATE };
