export const dataSources = {
  cassandra: "cassandra",
  elasticSearch: "elasticSearch",
};

export const outputFormatNames = {
  csv: "CSV",
  zipped_csv: "CSV per file",
  xml: "XML",
  xlsx: "MS Excel",
  original_file: "Original File",
};
