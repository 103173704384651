import { observer } from "mobx-react-lite";
import * as Sentry from "@sentry/react";
import { Integrations as SentryIntegrations } from "@sentry/tracing";
import { Route } from "react-router-dom";
import "lazysizes";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { hasAccess } from "Utils/access";
import { useStores } from "hooks/use-stores";
import { useIsLoggedIn } from "hooks/user";

import Init from "components/AppInit";
import AppLoader from "components/AppLoader";
import ModelOwner from "./components/ModelOwner";
import Navigation from "./components/Navigation";
import ProTrialDialog from "components/OCR/ProTrialDialog";
import ErrorBoundary from "components/ErrorBoundary";
import Routes from "Routes";

const isProduction = process.env.NODE_ENV === "production";

const environment = [
  "app.nanonets.com",
  "eu.nanonets.com",
  "us.nanonets.com",
].includes(window.location.host)
  ? "production"
  : ["staging.nanonets.com"].includes(window.location.host)
  ? "staging"
  : "development";

if (isProduction && hasAccess("sentry")) {
  Sentry.init({
    dsn: "https://73412da517e64050a30710e83e60ea04@o564997.ingest.sentry.io/5706177",
    integrations: [new SentryIntegrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment,
  });
}

const App = observer(() => {
  const isLoggedIn = useIsLoggedIn() || hasAccess("auth.auto_login");

  const { appStore, userStore } = useStores();

  return (
    <>
      <div className="flex h-screen">
        {isLoggedIn && userStore.isDrawerVisible && (
          <div className="shrink-0">
            <ErrorBoundary>
              <Navigation />
            </ErrorBoundary>
          </div>
        )}

        <div className="relative h-full flex-1 overflow-auto">
          <ErrorBoundary>
            <Routes />
            <Route component={Init} />
            <AppLoader />
            <ProTrialDialog />
            <ModelOwner />
          </ErrorBoundary>
        </div>
      </div>

      <Snackbar
        open={appStore.alert.open}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          appStore.closeAlert();
        }}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={appStore.closeAlert}
          severity={appStore.alert.level}
        >
          {appStore.alert.message}
        </Alert>
      </Snackbar>

      <ToastContainer
        icon={false}
        closeButton={false}
        closeOnClick={false}
        hideProgressBar
        className="grid !w-auto"
        toastClassName="!p-0 !min-h-0 justify-self-end"
        bodyClassName="!p-0"
      />
    </>
  );
});

export default App;
