import * as React from "react";
import queryClient from "queryClient";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { groupBy, sortBy, uniq, uniqBy } from "lodash";
import { getTimeInt, modelTypes } from "Utils";
import { useDBListFields } from "./labels";
import { useIsPerDocumentProcessing, useModel, useModelType } from "./model";
import { toast } from "Utils/toast";
import { getFileCounts } from "./misc";
import { dataSources } from "components/OCR/utils";
import { buildRawEsQuery, parseESResponse, reviewStatus } from "Utils/filter";
import {
  exportRunHistoryKeys,
  inferenceKeys,
  inferenceFilesCountKeys,
  validationErrorsKeys,
  fileActivityLogKey,
} from "./queryKeys";
import { rotateBox, rotateBoxes } from "Utils/ocr";
import { FilesQueryConfig, RawOcrResponse, SignedUrls } from "types/ocr";
import { v1 } from "uuid";
import { ModelType2 } from "types/Model";
import {
  CassandraInferences,
  FileLevelInference,
  PageLevelInferenceResponse,
  PageLevelInference,
  InferenceInfo,
  InferenceObjectCell,
  ICInferences,
  PageLevelInferenceIC,
  InferenceObject,
  InferenceUpdateResponse,
} from "types/inference";
import moment from "moment";
import {
  convertInferenceToOcrResult,
  convert_PageLevelInference2_to_PageLevelInference,
} from "Utils/inference";

const convertFileInferenceToPageInferences = (
  fileResult: FileLevelInference,
  filterPageLevel: boolean
): PageLevelInferenceResponse[] => {
  const moderated_boxes = constructFileLevelModeratedBoxes(fileResult);
  const pageResults = fileResult.page_details
    .filter((page) => page.id !== "00000000-0000-0000-0000-000000000000")
    .map((page) => {
      const inferenceResult: PageLevelInference = {
        assigned_member: "",
        ...fileResult,
        ...page,
        request_file_id: fileResult.id,
        filepath: page.file_path,
        id: page.id,
        original_file_name: fileResult.input,
        cost: 0,
        custom_response: null,
      };
      if (filterPageLevel) {
        inferenceResult.moderated_boxes = moderated_boxes?.filter(
          (box) => box.page === page.page
        );
        inferenceResult.predicted_boxes =
          inferenceResult.predicted_boxes?.filter(
            (box) => box.page === page.page
          );
      } else {
        inferenceResult.moderated_boxes = moderated_boxes;
      }
      return {
        message: fileResult.message,
        result: [inferenceResult],
        signed_urls: fileResult.signed_urls,
      };
    });

  return pageResults;
};

export const getFilePageLevelDataForCsvExport = async (
  modelId: string,
  fileId: string
) => {
  return fetch(
    `/api/v3/Inferences/Model/${modelId}/FileLevelInferences/${fileId}`
  )
    .then((res) =>
      res.ok ? res.json() : Promise.reject(new Error("Failed to fetch result"))
    )
    .then((data) => {
      const convertedData = convertFileInferenceToPageInferences(data, true);
      const processedData = convertedData.map((data) => {
        let result = data?.result?.[0];
        const signed_urls = data.signed_urls?.[result?.url] || {};
        const original_file_url =
          data?.signed_urls?.[result?.file_url]?.original || "";
        return convertInferenceToOcrResult(
          result,
          signed_urls,
          original_file_url
        );
      });
      return processedData;
    });
};

const constructFileLevelModeratedBoxes = (fileResult: FileLevelInference) => {
  const nonUpdatedPages = fileResult.page_details
    .filter((page) => page.id !== "00000000-0000-0000-0000-000000000000")
    .filter((page) => {
      let uploadedAt = 0;
      try {
        uploadedAt = getTimeInt(page.id);
      } catch (e) {
        uploadedAt =
          (page.day_since_epoch * 24 + page.hour_of_day) * 3600 * 1000;
      }
      let updatedAt;
      try {
        updatedAt = getTimeInt(page.updated_at);
      } catch (e) {
        updatedAt = uploadedAt;
      }
      return updatedAt <= uploadedAt;
    })
    .map((page) => page.page);

  const nonUpdatedBoxes = fileResult.predicted_boxes.filter((box) =>
    nonUpdatedPages.includes(box.page)
  );
  return [...fileResult.moderated_boxes, ...nonUpdatedBoxes];
};

let timeout1 = 0;

export const usePageInference = (modelId: string, pageId: string) => {
  const inferneceQuery = useImageLevelInference_1(modelId, pageId);
  const DBListQuery = useDBListFields(modelId, true);

  const data = React.useMemo(() => {
    if (inferneceQuery.data) {
      const boxes = inferneceQuery.data?.moderated_boxes || [];
      const dbListTableHeaders = DBListQuery.data?.map((v) => v.name) || [];

      boxes?.forEach((box) => {
        const columnNumbers = uniq(box.cells?.map((cell) => cell.col));
        const rowNumbers = uniq(box.cells?.map((cell) => cell.row));
        const rowCount = rowNumbers.length;

        let labelWiseCells: Record<string, boolean> = {};
        dbListTableHeaders?.forEach((header) => {
          labelWiseCells[header] = false;
        });

        box.cells?.forEach((cell) => {
          if (labelWiseCells[cell.label] !== undefined) {
            labelWiseCells[cell.label] = true;
          }
        });

        dbListTableHeaders?.forEach((header, index) => {
          const found = labelWiseCells[header];
          if (!found) {
            for (var i = 0; i < rowCount; i++) {
              const cell: InferenceObjectCell = {
                id: v1(),
                col_span: 1,
                row_span: 1,
                col: Math.max(...columnNumbers) + index + 1,
                row: i + 1,
                text: "",
                score: 100,
                xmin: 0,
                ymin: 0,
                xmax: 0,
                ymax: 0,
                label: header,
                failed_validation: "",
                validation_message: "",
                verification_status: "",
                row_label: "",
                status: "",
                label_id: "",
              };
              box.cells = [...(box.cells || []), cell];
            }
          }
        });
      });

      inferneceQuery.data.moderated_boxes = boxes;
    }

    return inferneceQuery.data;
  }, [DBListQuery.data, inferneceQuery.data]);

  return { ...inferneceQuery, data };
};

const useImageLevelInference_1 = (modelId: string, pageId: string) => {
  const modelQuery = useModel(modelId);

  return useQuery<InferenceInfo>({
    queryKey: ["imageLevelInference", { modelId, pageId }],
    queryFn: () => {
      if (modelQuery.data?.is_public) {
        // do not fetch public model result
        throw new Error("Access denied");
      }

      return fetch(
        `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences/${pageId}`
      )
        .then((res) =>
          res.ok
            ? res.json()
            : Promise.reject(new Error("Failed to fetch result"))
        )
        .then((data: PageLevelInferenceResponse) => {
          let result = data?.result?.[0];
          if (result?.id === "00000000-0000-0000-0000-000000000000") {
            return Promise.reject(new Error("Error while fetching result"));
          } else {
            const signed_urls = data.signed_urls?.[result?.url] || {};
            // set ids to boxes and rotate boxes to upright position
            const original_file_url =
              data?.signed_urls?.[result?.file_url]?.original || "";
            return convertInferenceToOcrResult(
              result,
              signed_urls,
              original_file_url
            );
          }
        });
    },
    enabled: !!(modelId && pageId),
  });
};

export const useFileInfo = (modelId: string, fileId: string) => {
  const model = useModel(modelId);

  return useQuery<FileLevelInference>({
    queryKey: inferenceKeys.file(modelId, fileId),
    queryFn: () => {
      if (model.data?.is_public) {
        // do not fetch public model result
        throw new Error("Access denied");
      }

      return fetch(
        `/api/v3/Inferences/Model/${modelId}/FileLevelInferences/${fileId}`
      ).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch result"))
      );
    },
    enabled: !!(modelId && fileId),
  });
};

const useFileLevelInferences = (modelId: string, fileId: string) => {
  const fileQuery = useFileInfo(modelId, fileId);

  const data = React.useMemo(() => {
    if (!fileQuery.data) return;

    const data = convertFileInferenceToPageInferences(fileQuery.data, false);

    return data.map((data) => {
      let result = data?.result?.[0];
      const signed_urls = data.signed_urls?.[result?.url] || {};
      const original_file_url =
        data?.signed_urls?.[result?.file_url]?.original || "";
      // set ids to boxes and rotate boxes to upright position
      return convertInferenceToOcrResult(
        result,
        signed_urls,
        original_file_url
      );
    });
  }, [fileQuery.data]);

  return { ...fileQuery, data };
};

export const usePageIdsFromFile = (modelId: string, fileId: string) => {
  const inferencesQuery = useFileLevelInferences(modelId, fileId);
  return inferencesQuery.data?.map(({ id }) => id) || [];
};

const useFileInference = (modelId: string, fileId: string, page: number) => {
  const inferencesQuery = useFileLevelInferences(modelId, fileId);
  return {
    ...inferencesQuery,
    data: inferencesQuery.data?.[page],
  };
};

export const useICInference = (
  modelId: string,
  fileOrPageId: string,
  modelType: ModelType2
) => {
  const query = useQuery<InferenceInfo | undefined>({
    queryKey: [
      "icinference",
      {
        modelId,
        fileOrPageId,
      },
    ],
    queryFn: () => {
      const timestamp = getTimeInt(fileOrPageId);
      const currentBatchDay = Math.floor(timestamp / 86400000);
      const startDayOfInterval = Math.floor(timestamp / 86400000);
      const currentBatchHour = 23;
      const url = `/api/v2/Inferences/Model/${modelId}?start_day_interval=${startDayOfInterval}&current_batch_day=${currentBatchDay}&current_batch_hour=${currentBatchHour}&score_lower_limit=0.1`;
      return fetch(url)
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Error while fetching predictions");
        })
        .then((data: ICInferences) => {
          const all_images = [...(data?.predictions || [])];

          const result = all_images.find((r) => {
            return r?.predictions?.[0]?.timestamp === fileOrPageId;
          });
          const signed_urls = data?.signed_urls?.[result?.input || ""] || {};
          return IcInferenceToOcrInference(result, signed_urls);
        });
    },
    enabled: !!(modelId && modelType === modelTypes.ic),
  });
  return query;
};

const IcInferenceToOcrInference = (
  result: PageLevelInferenceIC | undefined,
  signed_urls: SignedUrls
) => {
  if (result) {
    const { input: original_file_name, input: url } = result;
    const ocrBoxes: InferenceObject[] = result.predictions.map((icBox, i) => {
      return {
        ...icBox,
        id: i.toString(),
        ocr_text: "",
        type: "",
        label_id: "",
        validation_status: "",
      };
    });
    const obj: PageLevelInference = {
      approval_status: "",
      assigned_member: "",
      id: result?.predictions?.[0]?.timestamp,
      cost: 0,
      current_stage_id: "",
      day_since_epoch: 0,
      original_file_name: (original_file_name || url)?.split("/").pop() || "",
      custom_response: null,
      file_url: "",
      filepath: "",
      hour_of_day: 0,
      is_moderated: false,
      model_id: "",
      no_of_fields: 0,
      predicted_boxes: ocrBoxes,
      moderated_boxes: ocrBoxes,
      prediction: ocrBoxes,
      rotation: 0,
      source: "",
      status: "success",
      raw_ocr: ocrBoxes,
      updated_at: "",
      url: signed_urls.original,
      ...result,
      ...signed_urls,
    };
    return convertInferenceToOcrResult(obj, signed_urls, "");
  }
};

export const useInference = (
  modelId: string,
  fileOrPageId: string,
  page = 0
) => {
  const isDocumentLevel = useIsPerDocumentProcessing(modelId);
  // const modelType = useModelType(modelId);
  // const user = useUser();
  const pageQuery = usePageInference(
    modelId,
    isDocumentLevel ? "" : fileOrPageId
  );
  const fileQuery = useFileInference(
    modelId,
    isDocumentLevel ? fileOrPageId : "",
    page
  );
  // const ICInference = useICInference(modelId, fileOrPageId, modelType);

  // if (
  //   modelType === modelTypes.ic &&
  //   !user?.data?.FeatureFlags.document_classification
  // ) {
  //   return ICInference;
  // }
  return isDocumentLevel ? fileQuery : pageQuery;
};

export const useInferences = (
  modelId: string,
  config: FilesQueryConfig,
  modelType: ModelType2
) => {
  const cassandraResults = useCassandraResults(modelId, config);
  const elasticSearchResults = useElasticSearchResults(modelId, config);
  // const icResults = useICResults(modelId, config, modelType);
  // const { data: user } = useUser();
  // if (modelType === modelTypes.ic) {
  //   if (user?.FeatureFlags.document_classification) {
  //     return cassandraResults;
  //   }
  //   return icResults;
  // }

  return config.dataSource === dataSources.cassandra
    ? cassandraResults
    : elasticSearchResults;
};

export const useCassandraResults = (
  modelId: string,
  config: FilesQueryConfig
) => {
  const {
    sortedBy,
    sortOrder,
    dataSource,
    filters,
    page,
    rowsPerPage,
    startDate,
    endDate,
  } = config;
  const { data: model } = useModel(modelId);
  const currentBatchDay = Math.floor(endDate / 86400000);
  const startDayOfInterval = Math.floor(startDate / 86400000);
  const currentBatchHour = 23;

  const query = useQuery<InferenceInfo[]>(
    [
      "imageLevelInferences",
      {
        currentBatchDay,
        currentBatchHour,
        dataSource,
        modelId,
        startDayOfInterval,
      },
    ],
    () => {
      if (model?.is_public) {
        // do not fetch public model result
        throw new Error("Access denied");
      }

      const url = `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences?start_day_interval=${startDayOfInterval}&current_batch_day=${currentBatchDay}&current_batch_hour=${currentBatchHour}`;
      return fetch(url)
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Error while fetching predictions");
        })
        .then((data: CassandraInferences) => {
          const all_images = [
            ...(data?.moderated_images || []),
            ...(data?.unmoderated_images || []),
          ];

          return all_images.map((result) => {
            const signed_urls = data?.signed_urls?.[result.url] || {};
            const original_file_url =
              data?.signed_urls?.[result?.file_url]?.original || "";
            const obj =
              convert_PageLevelInference2_to_PageLevelInference(result);

            return convertInferenceToOcrResult(
              obj,
              signed_urls,
              original_file_url
            );
          });
        });
    },
    {
      enabled: !!(
        modelId &&
        currentBatchDay &&
        startDayOfInterval &&
        (dataSource === dataSources.cassandra ||
          model?.type === "classification")
      ),
    }
  );

  const data = React.useMemo(() => {
    if (!query.data) return {};

    // filter
    let results: InferenceInfo[] = [];
    results = query.data.filter((result) => {
      const reviewFilter = filters?.find((f) => f.id === "is_moderated");
      if (!reviewFilter?.action) {
        return true;
      }

      switch (reviewFilter.action) {
        case reviewStatus.rejected:
          return result.approval_status === "rejected";
        case reviewStatus.approved:
          return result.is_moderated === true;
        case reviewStatus.pending:
          return result.is_moderated === false && result.approval_status === "";
        default:
          return true;
      }
    });

    // sorting
    results = sortBy(results, sortedBy);

    if (sortOrder === "DESC") {
      results.reverse();
    }

    // pagination
    const _filesById = groupBy(results, "request_file_id");
    let fileIds = Object.keys(_filesById);

    if (rowsPerPage >= 0) {
      const startIndex = (page - 1) * rowsPerPage;
      fileIds = fileIds.slice(startIndex, startIndex + rowsPerPage);
    }

    const filesToDisplay: Record<string, InferenceInfo[]> = {};

    fileIds.forEach((key) => {
      filesToDisplay[key] = _filesById[key];
    });

    const isPending = Object.values(filesToDisplay).some((pages) =>
      pages.some((page) => page.status === "pending")
    );

    // disabling refetch when rowsPerPage === -1 because payload may be huge in some cases
    if (isPending && rowsPerPage !== -1) {
      clearTimeout(timeout1);
      timeout1 = window.setTimeout(query.refetch, 5000);
    }

    return filesToDisplay;
  }, [
    filters,
    page,
    query.data,
    query.refetch,
    rowsPerPage,
    sortOrder,
    sortedBy,
  ]);

  return { ...query, data };
};

export const useElasticSearchResults = (
  modelId: string,
  config: FilesQueryConfig
) => {
  const { dataSource, filters, page, query, rowsPerPage, sortedBy, sortOrder } =
    config;

  const { data: model } = useModel(modelId);

  const inferencesQuery = useQuery<InferenceInfo[]>(
    [
      "imageLevelInferences",
      {
        dataSource,
        filters,
        modelId,
        page,
        query,
        rowsPerPage,
        sortedBy,
        sortOrder,
      },
    ],
    () => {
      if (model?.is_public) {
        // do not fetch public model result
        throw new Error("Access denied");
      }

      const url = `/api/v2/Inferences/Model/${modelId}/RawQueryImageLevelInferences?search_complete_document=true`;

      const rawQueryJson = buildRawEsQuery({
        searchText: query,
        page,
        rowsPerPage,
        filters,
        sortedBy,
        sortOrder,
        modelId,
      });

      return fetch(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(rawQueryJson),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error("Error while fetching response");
        })
        .then((data) => {
          const { err, parsedJson } = parseESResponse(data);

          if (err) throw err;
          return parsedJson;
        })
        .then((data) => {
          return data.all_images.map((result) => {
            const { original_file_name, url } = result;
            const signed_urls = data?.signed_urls?.[url] || {};

            let obj = {
              ...result,
              ...signed_urls,
              filename: (original_file_name || url)?.split("/").pop() || "",
              signed_urls,
            };
            const original_file_url =
              data?.signed_urls?.[result?.file_url]?.original || "";
            return convertInferenceToOcrResult(
              obj,
              signed_urls,
              original_file_url
            );
          });
        });
    },
    {
      enabled: !!(modelId && dataSource === dataSources.elasticSearch),
    }
  );

  const data = React.useMemo(() => {
    if (!inferencesQuery.data) return {};

    return groupBy(inferencesQuery.data, "request_file_id");
  }, [inferencesQuery.data]);

  return { ...inferencesQuery, data };
};

export const useICResults = (
  modelId: string,
  config: FilesQueryConfig,
  modelType: ModelType2
) => {
  const { sortedBy, sortOrder, page, rowsPerPage, startDate, endDate } = config;
  const { data: model } = useModel(modelId);
  const currentBatchDay = Math.floor(endDate / 86400000);
  const startDayOfInterval = Math.floor(startDate / 86400000);
  const currentBatchHour = 23;
  const query = useQuery<InferenceInfo[]>({
    queryKey: [
      "imageLevelInferences",
      {
        currentBatchDay,
        currentBatchHour,
        modelId,
        startDayOfInterval,
      },
    ],
    queryFn: () => {
      if (model?.is_public) {
        // do not fetch public model result
        throw new Error("Access denied");
      }

      const url = `/api/v2/Inferences/Model/${modelId}?start_day_interval=${startDayOfInterval}&current_batch_day=${currentBatchDay}&current_batch_hour=${currentBatchHour}&score_lower_limit=0.1`;
      return fetch(url)
        .then((res) => {
          if (res.ok) return res.json();
          throw new Error("Error while fetching predictions");
        })
        .then((data: ICInferences) => {
          const all_images = [...(data?.predictions || [])];

          let ocrInferences: InferenceInfo[] = [];
          all_images.forEach((result) => {
            const { input: url } = result;
            const signed_urls = data?.signed_urls?.[url] || {};
            const ocrInference = IcInferenceToOcrInference(result, signed_urls);
            if (ocrInference) {
              ocrInferences.push(ocrInference);
            }
          });
          return ocrInferences;
        });
    },
    enabled: !!(
      modelId &&
      currentBatchDay &&
      startDayOfInterval &&
      modelType === modelTypes.ic
    ),
  });

  const data = React.useMemo(() => {
    if (!query.data) return {};

    let results: InferenceInfo[] = query.data;

    // sorting
    results = sortBy(results, sortedBy);

    if (sortOrder === "DESC") {
      results.reverse();
    }

    // pagination
    const _filesById = groupBy(results, "request_file_id");
    let fileIds = Object.keys(_filesById);

    const startIndex = (page - 1) * rowsPerPage;

    if (rowsPerPage >= 0) {
      fileIds = fileIds.slice(startIndex, startIndex + rowsPerPage);
    }

    const filesToDisplay: Record<string, InferenceInfo[]> = {};

    fileIds.forEach((key) => {
      filesToDisplay[key] = _filesById[key];
    });

    return filesToDisplay;
  }, [page, query.data, rowsPerPage, sortOrder, sortedBy]);

  return { ...query, data };
};

export const useUpdateImageLevelInference = () => {
  return useMutation<
    InferenceUpdateResponse,
    ErrorEvent,
    { modelId: string; result: InferenceInfo }
  >(
    ({ modelId, result }) => {
      let { rotation, size, moderated_boxes } = result;

      if (size && rotation) {
        if (rotation === 90 || rotation === 270) {
          size = {
            height: size.width,
            width: size.height,
          };
        }

        // rotate boxes to its original(as it as) position
        moderated_boxes = rotateBoxes(moderated_boxes, size, rotation);
      }

      const payload = { ...result, moderated_boxes };

      return fetch(`/api/v2/Inferences/Model/${modelId}/ImageLevelInference`, {
        method: "PATCH",
        body: JSON.stringify(payload),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while updating result");
        }

        return data;
      });
    },
    {
      onMutate: async ({ modelId, result }) => {
        await queryClient.cancelQueries([
          "imageLevelInference",
          { modelId, pageId: result.id },
        ]);

        queryClient.setQueryData<InferenceInfo>(
          ["imageLevelInference", { modelId, pageId: result.id }],
          (prevResult) => ({ ...prevResult, ...result })
        );
      },
      onSuccess: (data, { modelId, result }) => {
        queryClient.invalidateQueries(
          inferenceKeys.file(modelId, result.request_file_id)
        );

        queryClient.invalidateQueries(
          validationErrorsKeys.file(modelId, result.request_file_id)
        );

        queryClient.invalidateQueries(
          fileActivityLogKey(modelId, result.request_file_id)
        );

        // const nonDerivedBoxesInUI = result?.moderated_boxes.filter(
        //   (box) => box.type !== "derived"
        // );

        // const derivedBoxesFromServer =
        //   data?.moderated_boxes?.filter((box) => box.type === "derived") || [];

        // const moderated_boxes = [
        //   ...nonDerivedBoxesInUI,
        //   ...derivedBoxesFromServer,
        // ];

        // queryClient.setQueryData<InferenceInfo>(
        //   ["imageLevelInference", { modelId, pageId: result.id }],
        //   (r) => {
        //     return {
        //       ...r,
        //       ...result,
        //       moderated_boxes: moderated_boxes ?? [],
        //     };
        //   }
        // );

        // queryClient.setQueriesData<InferenceInfo[] | undefined>(
        //   ["imageLevelInferences", { modelId }],
        //   (prevData) => {
        //     return prevData
        //       ? prevData?.map((r) => {
        //           if (r.id === result.id) {
        //             return {
        //               ...r,
        //               ...result,
        //               moderated_boxes: moderated_boxes ?? [],
        //             };
        //           }
        //           return r;
        //         })
        //       : prevData;
        //   }
        // );
      },
      onError: (error, { modelId, result }) => {
        queryClient.invalidateQueries([
          "imageLevelInference",
          { modelId, pageId: result.id },
        ]);
        toast.error(error?.message);
      },
    }
  );
};

export const useDeleteInference = () => {
  return useMutation<any, ErrorEvent, { modelId: string; fileId: string }>(
    ({ modelId, fileId }) => {
      return fetch(
        `/api/v2/Inferences/Model/${modelId}/InferenceRequestFiles/${fileId}`,
        { method: "DELETE" }
      ).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while deleting file");
        }

        return data;
      });
    },
    {
      onSuccess: (data, { modelId, fileId }) => {
        toast.success("File deleted");

        queryClient
          .getQueriesData<InferenceInfo>(["imageLevelInference", { modelId }])
          .forEach(([queryKey, data]) => {
            if (data?.request_file_id === fileId) {
              queryClient.removeQueries(queryKey);
            }
          });

        queryClient.setQueriesData<InferenceInfo[] | undefined>(
          ["imageLevelInferences", { modelId }],
          (prevData) => {
            return prevData?.filter((r) => r.request_file_id !== fileId);
          }
        );
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useUpdateICLabel = () => {
  return useMutation<
    any,
    ErrorEvent,
    { modelId: string; fileId: string; label: string }
  >(
    ({ modelId, fileId, label }) => {
      return fetch(
        `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences/UpdateLabel/${fileId}?selected_label=${label}`,
        {
          method: "POST",
          headers: {
            selected_label: label,
          },
          body: JSON.stringify({ selected_label: label }),
        }
      ).then(async (res) => {
        const data = await res.json();
        if (res.ok) {
          invalidateImageLevelInference(modelId, fileId);
          invalidateModelInferences(modelId);
          return data;
        } else {
          return Promise.reject(
            new Error(
              data?.errors[0]?.reason ||
                data?.message ||
                "An error occurred while updating label"
            )
          );
        }
      });
    },
    {
      onSuccess: () => {
        toast.success("Label updated successfully");
      },
      onError: (err: any) => {
        toast.error(err.message);
      },
    }
  );
};

export const invalidateImageLevelInference = (
  modelId: string,
  request_file_id: string
) => {
  queryClient
    .getQueriesData<InferenceInfo>(["imageLevelInference", { modelId }])
    .forEach(([queryKey, data]) => {
      if (data?.request_file_id === request_file_id) {
        queryClient.invalidateQueries(queryKey);
      }
    });
};

export const useRetryUpload = (modelId: string) => {
  return useMutation<any, ErrorEvent, { modelId: string; fileIds: string[] }>(
    ({ modelId, fileIds }) => {
      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/RetryPrediction`, {
        method: "POST",
        body: JSON.stringify({ file_ids: fileIds }),
      }).then((res) =>
        res.ok
          ? Promise.resolve()
          : Promise.reject(new Error("Error while reuploading files"))
      );
    },
    {
      onSuccess: (data, { fileIds }) => {
        fileIds?.forEach((fileId: string) => {
          invalidateImageLevelInference(modelId, fileId);
          queryClient.invalidateQueries(inferenceKeys.file(modelId, fileId));
        });
        toast.success("Reuploaded files successfully");
        queryClient.invalidateQueries(["imageLevelInferences", { modelId }]);
      },
    }
  );
};

export const invalidateModelInferences = (modelId: string) => {
  queryClient.invalidateQueries(["imageLevelInference", { modelId }]);
};

export const useAssignMemberToResults = () => {
  return useMutation<
    any,
    ErrorEvent,
    { modelId: string; email: string; fileIds: string[] }
  >(
    ({ modelId, email, fileIds }) => {
      const url = `/api/v2/team/members/model/${modelId}/assign/inferences`;
      const payload: {
        inferences: {
          day_since_epoch: number;
          id: string;
          hour_of_day: number;
        }[];
        member: string;
      } = {
        inferences: [],
        member: email,
      };

      let results: InferenceInfo[] = [];

      fileIds.forEach((fileId) => {
        queryClient
          .getQueriesData<InferenceInfo[]>([
            "imageLevelInferences",
            { modelId },
          ])
          .forEach(([_, data]) => {
            data?.forEach((result) => {
              if (result.request_file_id === fileId) {
                results.push(result);
              }
            });
          });
      });

      results = uniqBy(results, (v) => v.id);
      results.forEach((result) => {
        const { day_since_epoch, hour_of_day, id } = result;
        payload.inferences.push({
          day_since_epoch,
          id,
          hour_of_day,
        });
      });

      return fetch(url, { method: "post", body: JSON.stringify(payload) }).then(
        async (res) => {
          const data = await res.json();

          if (res.status === 403) {
            throw new Error(data?.errors?.[0]?.message);
          }

          if (!res.ok) {
            throw new Error("Failed to assign members");
          }

          return data;
        }
      );
    },
    {
      onSuccess: (data, { modelId, email, fileIds }) => {
        toast.success(`Assigned selected files to ${email}`);

        queryClient.setQueriesData<InferenceInfo[] | undefined>(
          ["imageLevelInferences", { modelId }],
          (prevData) => {
            return prevData?.map((result) => {
              if (fileIds.includes(result.request_file_id)) {
                return { ...result, assigned_member: email };
              }
              return result;
            });
          }
        );

        fileIds.forEach((fileId) =>
          queryClient.invalidateQueries(fileActivityLogKey(modelId, fileId))
        );
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useVerifyFile = (showAlert: boolean) => {
  const queryClient = useQueryClient();

  return useMutation<any, ErrorEvent, { modelId: string; fileId: string }>(
    ({ fileId, modelId }) => {
      const url = `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences/Verify/${fileId}`;

      return fetch(url, { method: "post" }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to verify file");
        }

        return data;
      });
    },
    {
      onSuccess: (data, { fileId, modelId }) => {
        showAlert && toast.success("File verified");
        invalidateImageLevelInference(modelId, fileId);
        queryClient.invalidateQueries(inferenceFilesCountKeys.all(modelId));
        queryClient.invalidateQueries(["imageLevelInferences", { modelId }]);
        queryClient.invalidateQueries(exportRunHistoryKeys.file(fileId));
        queryClient.invalidateQueries(fileActivityLogKey(modelId, fileId));
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useUnVerifyFile = () => {
  const queryClient = useQueryClient();

  return useMutation<any, ErrorEvent, { modelId: string; fileId: string }>(
    ({ fileId, modelId }) => {
      const url = `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences/UnVerify/${fileId}`;

      return fetch(url, { method: "post" }).then((res) => {
        if (res.ok) return res.json();
        throw new Error("Failed to unverify file");
      });
    },
    {
      onSuccess: (data, { fileId, modelId }) => {
        invalidateImageLevelInference(modelId, fileId);
        queryClient.invalidateQueries(inferenceFilesCountKeys.all(modelId));
        queryClient.invalidateQueries(["imageLevelInferences", { modelId }]);
        queryClient.invalidateQueries(fileActivityLogKey(modelId, fileId));
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useOcrTexts = (result: InferenceInfo) => {
  const { rotation, size, url } = result || {};
  const _rotation = (360 - rotation) % 360;

  const query = useQuery<RawOcrResponse[]>(
    ["ocrTexts", url],
    () => {
      return fetch(`/api/v2/RawOcrResponse?s3_image_path=nanonets/${url}`).then(
        (res) => {
          if (!res.ok) throw new Error("Failed to get ocr text");
          return res.json();
        }
      );
    },
    {
      enabled: !!url,
    }
  );

  const data = React.useMemo(
    () =>
      query.data?.map((box) => {
        const xmin = Math.min(...box.vertices.map((v) => v[0]));
        const xmax = Math.max(...box.vertices.map((v) => v[0]));
        const ymin = Math.min(...box.vertices.map((v) => v[1]));
        const ymax = Math.max(...box.vertices.map((v) => v[1]));

        let bndbox = {
          xmin,
          ymin,
          xmax,
          ymax,
        };
        bndbox = rotateBox(bndbox, size, _rotation);

        return {
          ...bndbox,
          ocr_text: box.text,
        };
      }),
    [query.data, _rotation, size]
  );

  return { ...query, data };
};

export const useInferenceCounts = (
  modelId: string,
  { filters, query }: FilesQueryConfig
) => {
  const _filters = filters.filter((f) => f.id !== "is_moderated");

  return useQuery(
    [...inferenceFilesCountKeys.all(modelId), { filters: _filters, query }],
    () => {
      const url = `/api/v2/Inferences/Model/${modelId}/RawQueryImageLevelInferences?search_complete_document=true`;
      return getFileCounts(url, query, filters, modelId);
    }
  );
};

export const useDownloadExportFiles = () => {
  return useMutation<
    any,
    ErrorEvent,
    {
      modelId: string;
      outputFormat: "csv" | "xml" | "xlsx" | "zipped_csv";
      exportType: "all" | "selected_fields";
      startDate?: Date;
      endDate?: Date;
      fileInfosSelected?: { checked: boolean; value: any }[];
      fieldsSelected?: { checked: boolean; value: any }[];
      tableHeadersSelected?: { checked: boolean; value: any }[];
      useUnlabelledTableHeaders?: boolean;
      selectedFileIds?: string[];
      isOrderingEnabled: boolean;
      labelsOrder: string[];
    }
  >(
    ({
      modelId,
      outputFormat,
      exportType,
      startDate,
      endDate,
      fileInfosSelected = [],
      fieldsSelected = [],
      tableHeadersSelected = [],
      useUnlabelledTableHeaders = false,
      selectedFileIds = [],
      isOrderingEnabled,
      labelsOrder,
    }) => {
      let filename = `results.${outputFormat}`;
      return fetch(`/api/v2/Inferences/Model/${modelId}/export`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          output_format: outputFormat,
          export_type: exportType,
          date_range: {
            from: startDate,
            to: endDate,
          },
          file_infos_selected: fileInfosSelected
            .filter((v) => v.checked)
            .map((v) => v.value),
          fields_selected: fieldsSelected
            .filter((v) => v.checked)
            .map((v) => v.value),
          table_headers_selected: tableHeadersSelected
            .filter((v) => v.checked)
            .map((v) => v.value),
          use_unlabelled_table_headers: useUnlabelledTableHeaders,
          selected_file_ids: selectedFileIds,
          ordering_enabled: isOrderingEnabled,
          order: labelsOrder,
        }),
      })
        .then((res) => {
          const header = res.headers.get("Content-Disposition");
          const parts = header?.split(";");
          filename = parts?.[1]?.split("=")[1] || "download";
          return res.ok
            ? res.blob()
            : Promise.reject(new Error("Failed to download results"));
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useFilePageIds = (modelId: string, fileId: string) => {
  return useQuery<string[]>({
    queryKey: ["pdfPages", fileId],
    queryFn: () => {
      const url = `/api/v2/Inferences/Model/${modelId}/InferenceRequestFiles/${fileId}`;

      return fetch(url)
        .then((res) =>
          res.ok
            ? res.json()
            : Promise.reject(new Error("Failed to fetch pdf pages"))
        )
        .then((json) => json.page_ids);
    },
    enabled: !!fileId,
  });
};

export const useLatestInference = (modelId: string) => {
  const requestConfig: FilesQueryConfig = {
    dataSource: "elasticSearch",
    filters: [],
    page: 1,
    rowsPerPage: 1,
    query: "",
    sortedBy: "uploadedAt",
    sortOrder: "DESC",
    startDate: moment().endOf("day").subtract(1, "month").millisecond(),
    endDate: moment().endOf("day").millisecond(),
  };
  const modelType = useModelType(modelId);

  return useInferences(modelId, requestConfig, modelType);
};

export const useFindFailedInferences = (
  modelId: string,
  startDate: number,
  endDate: number
) => {
  return useQuery<boolean>(
    ["foundFailedInferencesInES", modelId, startDate, endDate],
    () => {
      return fetch(
        `/api/v2/Inferences/Model/${modelId}/ImageLevelInferences/find_failed_docs?start_date=${startDate}&end_date=${endDate}`
      ).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (!res.ok) {
          throw new Error(
            data?.errors?.[0]?.reason || "Failed to fetch failed docs status"
          );
        }
        return data.found_failed_docs_in_date_range;
      });
    }
  );
};
