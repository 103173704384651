export const trackPage = () => {
  if (!window.analytics) return;
  var url = window.location.href;
  var page = url.substring(url.lastIndexOf("#") + 1);
  var route_array = page.split("/");
  if (
    route_array.length > 3 &&
    ["ic", "od", "ocr", "mlc"].includes(route_array[1])
  ) {
    var name = `/${route_array[1]}/${route_array[2]}`;
    window.analytics.page(name, { url: url, path: page });
  } else {
    window.analytics.page(page, { url: url, path: page });
  }
};
