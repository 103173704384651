import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
  palette: {
    primary: {
      main: "#546fff",
    },
    secondary: {
      main: "#D45553",
    },
    error: {
      main: "#D45553",
    },
    warning: {
      main: "#CD7B4D",
    },
    success: {
      main: "#29A176",
    },
    action: {
      disabled: "",
      disabledBackground: "",
    },
  },
  colors: {
    grey1: "#f9f9f9",
    tooltip: "#3E3E3E",
    blue: "#546fff",
    text: "#c5f4ff",
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        "&:disabled": {
          opacity: 0.5,
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:disabled": {
          opacity: 0.5,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#171717",
        color: "#fff",
        fontSize: 12,
        fontWeight: 400,
        maxWidth: "none",
      },
      arrow: {
        color: "#3E3E3E",
      },
    },
  },
});

export default theme;
