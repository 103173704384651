import { useQuery, useMutation, useQueryClient } from "react-query";
import { FeatureFlags, User } from "types/user";
import { toast } from "Utils/toast";
import { queryKeys } from "./queryKeys";

export const isAdmin = (user: User | undefined) => {
  return Boolean(
    user?.UserProfile?.email.endsWith("@nanonets.com") &&
      user?.UserProfile?.identities?.[0].isSocial
  );
};

const isAnnotator = (user: User | undefined) => {
  return Boolean(
    user?.UserProfile?.email.endsWith("@nanotators.com") &&
      user?.UserProfile?.identities?.[0].isSocial
  );
};

export const useUser = () => {
  return useQuery<User>(
    ["user"],
    () => {
      return fetch("/user/jsonprofile/").then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching user profile"))
      );
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useIsCloneFlagEnabled = (email: string) => {
  let payload = {
    email_id: email,
  };
  return useQuery(
    ["is_clone_enabled", email],
    () => {
      return fetch("/api/v2/miscroutes/fetchCloneModelFlag", {
        method: "post",
        credentials: "include",
        body: JSON.stringify(payload),
      })
        .then((res) =>
          res.ok
            ? res.json()
            : Promise.reject(new Error("Error while fetching user profile"))
        )
        .then((body) => body.is_clone_enabled);
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );
};

export const useIsClonedAllowed = (email: string) => {
  const { data: is_clone_enabled } = useIsCloneFlagEnabled(email);

  return is_clone_enabled;
};

export const useIsLoggedIn = () => {
  const { data: user } = useUser();

  return Boolean(user?.EmailId);
};

export const useIsAdmin = () => {
  const { data: user } = useUser();

  return isAdmin(user);
};

export const useIsAnnotator = () => {
  const { data: user } = useUser();

  return isAnnotator(user);
};

export const useIsFreeTrialAvailable = () => {
  const { data: user } = useUser();

  return user?.FreeTrailExhausted === false;
};

export const useDeleteAccount = () => {
  return useMutation<unknown, ErrorEvent>({
    mutationFn: () => {
      return fetch(`/user/DeleteAccount`, {
        method: "DELETE",
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while deleting account");
        }

        return data;
      });
    },
    onSuccess: () => {
      toast.success("Account deleted");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useFeatureFlags = (userEmail?: string) => {
  const user = useUser();
  const isAdmin = useIsAdmin();
  const email = userEmail ?? user.data?.EmailId ?? "";

  return useQuery<FeatureFlags, ErrorEvent>({
    queryKey: queryKeys.featureFlags.user(email),
    queryFn: () => {
      return fetch(`/api/v2/miscroutes/feature-access-to-user?email=${email}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Error while fetching feature flags");
          }
          return res.json();
        })
        .then((data) => {
          return {
            ...data,
            model_edit_history_feature_allowed: isAdmin,
            file_edit_history_feature_allowed: isAdmin,
          };
        });
    },
    enabled: !!email,
  });
};

export const useUpdateFeatureFlags = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    ErrorEvent,
    {
      email: string;
      flags: FeatureFlags;
    }
  >({
    mutationFn: ({ email, flags }) => {
      return fetch(`/api/v2/miscroutes/feature-access-to-user`, {
        method: "post",
        body: JSON.stringify({
          email,
          ...flags,
        }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error("Error while updating user feature flags");
        }
        return res.json();
      });
    },
    onSuccess: (data, { email, flags }) => {
      queryClient.setQueryData(queryKeys.featureFlags.user(email), flags);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
