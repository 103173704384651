import moment from "moment";
import { ESQuery, ESQueryObject, Filter, FilterInputType } from "types/filter";
import { PostprocessingConfig } from "types/workflow/postprocessing";
import logger from "Utils/logger";

export const reviewStatus = Object.freeze({
  all: "all",
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected",
});

export const annotationStatus = Object.freeze({
  all: "all",
  annotated: "Annotated",
  pending: "Not annotated",
});

export const actionTypes = {
  is: "is",
  between: "between",
  contains: "contains",
  before: "before",
  after: "after",
  greater_than: "greater than",
  smaller_than: "smaller than",
  today: "Today",
  last_7_days: "Last 7 days",
  last_30_days: "Last 30 days",
  exists: "exists",
  doesnt_exist: "doesn't exist",
};

export const inputTypes: Record<string, FilterInputType> = {
  dropdown: "dropdown",
  date: "date",
  text: "text",
  int: "int",
  float: "float",
};

export const globalFields: Filter[] = [
  {
    type: "global",
    name: "Uploaded date",
    actions_supported: [
      actionTypes.today,
      actionTypes.last_7_days,
      actionTypes.last_30_days,
      actionTypes.is,
      actionTypes.between,
    ],
    value: "global-created",
    id: "created",
    action: actionTypes.last_30_days,
    text: "",
    input_type: "date",
    start: new Date(),
    end: new Date(),
  },
  {
    type: "global",
    name: "Updated at",
    actions_supported: [
      actionTypes.today,
      actionTypes.last_7_days,
      actionTypes.last_30_days,
      actionTypes.is,
      actionTypes.between,
    ],
    value: "global-updated",
    id: "updated_at",
    action: actionTypes.last_30_days,
    text: "",
    input_type: "date",
    start: new Date(),
    end: new Date(),
  },
  {
    type: "global",
    name: "Verified at",
    actions_supported: [
      actionTypes.today,
      actionTypes.last_7_days,
      actionTypes.last_30_days,
      actionTypes.is,
      actionTypes.between,
    ],
    value: "global-verified",
    id: "verified_at",
    action: actionTypes.last_30_days,
    text: "",
    input_type: "date",
    start: new Date(),
    end: new Date(),
  },
  {
    type: "global",
    name: "File name",
    actions_supported: [actionTypes.contains, actionTypes.is],
    value: "global-filename",
    id: "filename",
    action: actionTypes.is,
    text: "",
    input_type: "text",
  },
  {
    type: "global",
    name: "Validation Status",
    actions_supported: ["Rules passed", "Rules failed"],
    value: "global-validation_status",
    id: "validation_status",
    action: "Rules passed",
    text: "",
    input_type: "dropdown",
    screenType: "test",
  },
  {
    type: "global",
    name: "Review status",
    actions_supported: [
      reviewStatus.approved,
      reviewStatus.pending,
      reviewStatus.rejected,
    ],
    value: "global-moderated_status",
    id: "is_moderated",
    action: reviewStatus.approved,
    text: "",
    input_type: "dropdown",
    screenType: "test",
  },
  {
    type: "global",
    name: "Annotation status",
    actions_supported: [annotationStatus.annotated, annotationStatus.pending],
    value: "global-moderated_status",
    id: "is_moderated",
    action: annotationStatus.annotated,
    text: "",
    input_type: "dropdown",
    screenType: "annotate",
  },
  {
    type: "global",
    name: "Prediction Status",
    actions_supported: ["success", "pending", "failure"],
    value: "global-image_level_inference.status",
    id: "image_level_inference.status",
    action: "success",
    text: "",
    input_type: "dropdown",
    screenType: "test",
  },
  {
    type: "global",
    name: "Export Status",
    actions_supported: ["Success", "Errored", "Not available"],
    value: "global-image_level_inference.export_status",
    id: "image_level_inference.export_status",
    action: "Errored",
    text: "",
    input_type: "dropdown",
    screenType: "test",
  },
  {
    type: "global",
    name: "Assigned to",
    actions_supported: [],
    value: "global-assignee",
    id: "assignee",
    action: "",
    text: "",
    input_type: "dropdown",
  },
];

export const dummyFilterCondition: Filter = {
  type: "global",
  action: "",
  value: "",
  name: "",
  id: "",
  actions_supported: ["contains"],
  text: "",
  input_type: "text",
  start: new Date(),
  end: new Date(),
};

const containsSpecialCharacters = (s: string) => {
  var format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  return format.test(s);
};

const getESLabelName = (filter: Filter) => {
  let esLabelName = filter.id; // default is filter.id
  if (
    filter.input_type === inputTypes.text ||
    filter.id === "created" ||
    filter.id === "updated_at" ||
    filter.id === "verified_at" ||
    filter.action === actionTypes.exists ||
    filter.action === actionTypes.doesnt_exist
  ) {
    if (filter.type === "label") {
      esLabelName = "field_level_data." + filter.id;
    } else if (filter.type === "column_header") {
      esLabelName = "column_header_data." + filter.id;
    }
  } else {
    esLabelName = "type_converted_data." + filter.id;
  }

  return esLabelName;
};

const getTextQuery = (filter: Filter) => {
  const query = {
    query_string: {
      query:
        filter.action === actionTypes.is
          ? `"${filter.text}"`
          : `*${filter.text}*`,
      default_field: getESLabelName(filter),
    },
  };
  return query;
};

const getListQuery = (filter: Filter) => {
  let query;
  query = {
    query_string: {
      query: `${filter.action}`,
      default_field: filter.id,
    },
  };
  return query;
};

const getDateQuery = (filter: Filter) => {
  let startDate;
  let endDate;
  let query: ESQuery | undefined = undefined;
  if (!filter.start) {
    filter.start = moment().startOf("day").toDate();
  }
  if (!filter.end) {
    filter.end = moment().endOf("day").toDate();
  }
  switch (filter.action) {
    case actionTypes.today:
      startDate = moment().startOf("day").toDate();
      endDate = moment().endOf("day").toDate();
      break;
    case actionTypes.last_7_days:
      startDate = moment().subtract(6, "days").startOf("day").toDate();
      endDate = moment().endOf("day").toDate();
      break;
    case actionTypes.last_30_days:
      startDate = moment().subtract(29, "days").startOf("day").toDate();
      endDate = moment().endOf("day").toDate();
      break;
    case actionTypes.is:
      startDate = moment(filter.start).startOf("day").toDate();
      endDate = moment(filter.start).endOf("day").toDate();
      break;
    case actionTypes.between:
      startDate = moment(filter.start).startOf("day").toDate();
      endDate = moment(filter.end).endOf("day").toDate();
      break;
    case actionTypes.before:
      startDate = null;
      endDate = moment(filter.start).startOf("day").toDate();
      break;
    case actionTypes.after:
      startDate = moment(filter.start).endOf("day").toDate();
      endDate = null;
      break;
    default:
      break;
  }
  if (startDate || endDate) {
    query = {
      range: {},
    };
    const esLabelName = getESLabelName(filter);
    query.range[esLabelName] = { format: "epoch_millis" };
    startDate && (query.range[esLabelName].gte = startDate.getTime());
    endDate && (query.range[esLabelName].lte = endDate.getTime());
  }
  return query;
};

const getNumberQuery = (filter: Filter) => {
  let start, end;
  let query: ESQuery | undefined = undefined;

  switch (filter.action) {
    case actionTypes.is:
      start = filter.start;
      end = filter.start;
      break;
    case actionTypes.between:
      start = filter.start;
      end = filter.end;
      break;
    case actionTypes.greater_than:
      start = filter.start;
      break;
    case actionTypes.smaller_than:
      end = filter.start;
      break;
    default:
      break;
  }
  if (start || end) {
    query = {
      range: {},
    };
    const esLabelName = getESLabelName(filter);
    query.range[esLabelName] = {};
    if (filter.input_type === inputTypes.float) {
      start && (query.range[esLabelName].gte = parseFloat(start.toString()));
      end && (query.range[esLabelName].lte = parseFloat(end.toString()));
    } else {
      start && (query.range[esLabelName].gte = parseInt(start.toString()));
      end && (query.range[esLabelName].lte = parseInt(end.toString()));
    }
  }

  return query;
};

// ES filter to only load the first page from pdf files
const firstPageFilter = {
  query_string: {
    query: 0,
    default_field: "image_level_inference.page",
  },
};

export const buildRawEsQuery = ({
  searchText,
  page,
  rowsPerPage,
  filters,
  sortedBy,
  sortOrder,
  documentLevelSearch = false,
  modelId,
}: {
  searchText?: string;
  page: number;
  rowsPerPage: number;
  filters: Filter[];
  sortedBy?: string;
  sortOrder?: "ASC" | "DESC";
  documentLevelSearch?: boolean;
  modelId: string;
}) => {
  let combined: ESQuery[] = [];
  // adding modelID as part of the query as we are combining the data of multiple models into one index
  // this won't have any backwards compatibility issues either while data is being synced
  if (!documentLevelSearch) {
    combined = [firstPageFilter];
  }
  combined.push({
    query_string: {
      query: modelId,
      default_field: "model_id",
    },
  });
  let combined_must_not: ESQuery[] = [];
  let searchTextQuery = [];
  if (searchText) {
    let query = {
      query_string: {
        query: containsSpecialCharacters(searchText)
          ? '"' + searchText + '"'
          : "*" + searchText + "*",
        default_field: "full_text",
      },
    };
    combined.push(query);
    let queryFilename = {
      query_string: {
        query: "*" + searchText + "*",
        default_field: "filename",
      },
    };
    searchTextQuery.push(queryFilename);
  }
  filters.forEach((filter) => {
    if (filter.action === actionTypes.doesnt_exist) {
      let query = {
        exists: {
          field: getESLabelName(filter),
        },
      };
      combined_must_not.push(query);
    } else if (filter.action === actionTypes.exists) {
      let query = {
        exists: {
          field: getESLabelName(filter),
        },
      };
      combined.push(query);
    } else if (filter.id === "assignee") {
      const query = {
        query_string: {
          query: `"${filter.action}"`,
          default_field: filter.id,
        },
      };
      combined.push(query);
    } else if (
      filter.id === "is_moderated" &&
      filter.screenType === "annotate"
    ) {
      const query = {
        query_string: {
          query:
            filter.action === annotationStatus.annotated ? "true" : "false",
          default_field: filter.id,
        },
      };
      combined.push(query);
    } else if (filter.id === "is_moderated") {
      if (filter.action === reviewStatus.rejected) {
        combined.push({
          query_string: {
            query: "rejected",
            default_field: "image_level_inference.approval_status",
          },
        });
      } else if (filter.action === reviewStatus.approved) {
        combined.push({
          query_string: {
            query: "true",
            default_field: "image_level_inference.is_moderated",
          },
        });
      } else {
        combined.push({
          query_string: {
            query: "false",
            default_field: "image_level_inference.is_moderated",
          },
        });
        combined_must_not.push({
          query_string: {
            query: "rejected",
            default_field: "image_level_inference.approval_status",
          },
        });
      }
    } else if (filter.id === "validation_status") {
      const query = {
        query_string: {
          query: filter.action === "Rules passed" ? "success" : "failed",
          default_field: filter.id,
        },
      };
      combined.push(query);
    } else if (filter.id === "image_level_inference.export_status") {
      const query = {
        query_string: {
          query:
            filter.action === "Success"
              ? "success"
              : filter.action === "Errored"
              ? "failed"
              : "",
          default_field: filter.id,
        },
      };
      combined.push(query);
    } else if (filter.id) {
      let query;
      switch (filter.input_type) {
        case inputTypes.text:
          query = getTextQuery(filter);
          break;
        case inputTypes.date:
          query = getDateQuery(filter);
          break;
        case inputTypes.int:
        case inputTypes.float:
          query = getNumberQuery(filter);
          break;
        case inputTypes.dropdown:
          query = getListQuery(filter);
          break;
        default:
          // nothing to do here // might need to add later
          break;
      }
      query && combined.push(query);
    }
  });
  let queryJson: ESQueryObject = {
    from: rowsPerPage * (page - 1), // removing pagination since not properly supported in backend
    size: rowsPerPage === -1 ? 2000 : rowsPerPage, // max results, removing pagination since not properly supported in backend
    query: {
      bool: {
        must: combined,
        should: searchTextQuery,
        must_not: combined_must_not,
      },
    },
    aggs: {
      type_count: {
        filter: {
          term: {
            is_moderated: true,
          },
        },
      },
      rejected_count: {
        filter: {
          term: {
            approval_status: "rejected",
          },
        },
      },
    },
  };

  if (sortedBy) {
    let key;
    switch (sortedBy) {
      case "filename":
        key = "filename.keyword";
        break;
      case "assigned_member":
        key = "assignee.keyword";
        break;
      case "uploadedAt":
        key = "created";
        break;
      case "updated_at":
        key = "updated_at";
        break;
      default:
        key = `${sortedBy}.keyword`;
        break;
    }
    queryJson.sort = [
      {
        [key]: {
          order: sortOrder === "DESC" ? "desc" : "asc",
        },
      },
    ];
  }

  return queryJson;
};

export const parseESResponse = (res: { data: any; signed_urls: any }) => {
  let moderated_images_count = 0;
  let unmoderated_images_count = 0;

  let unmoderated_images: any[] = [];
  let moderated_images: any[] = [];
  const all_images: any[] = [];
  let err = null;
  const { data, signed_urls } = res;
  try {
    if (data.hits.total > 0) {
      moderated_images_count = data.aggregations.type_count.doc_count;
      unmoderated_images_count = data.hits.total - moderated_images_count;

      data.hits.hits.forEach(
        (hit: { _source: { image_level_inference: any } }) => {
          const inference = hit._source.image_level_inference;
          all_images.push(inference);
          if (inference.is_moderated) {
            moderated_images.push(inference);
          } else {
            unmoderated_images.push(inference);
          }
        }
      );
    }
  } catch (e) {
    err = e;
    logger.captureException(e);
  }
  const parsedJson = {
    all_images,
    moderated_images,
    unmoderated_images,
    moderated_images_count,
    unmoderated_images_count,
    signed_urls,
    total: data.hits.total,
  };
  return { parsedJson, err };
};

export const parseESResponseAnno = (res: {
  data: any;
  signed_urls: any;
  Meta: any;
}) => {
  let Count = 0;
  let Data: any[] = [];
  let State = "";
  let TextAnnotations: never[] = [];
  let annotated_count = 0;

  let err = null;
  const { data, signed_urls, Meta } = res;
  try {
    if (data.hits.total > 0) {
      Count = data.hits.total;
      annotated_count = data.aggregations.type_count.doc_count;

      data.hits.hits.forEach((hit: { _source: { annotation: any } }) => {
        const annotation = hit._source.annotation;
        const annodata = {
          ...annotation,
          ...annotation.annotation,
          request_file_id: annotation.annotation.id,
        };
        Data.push(annodata);
      });
    }
  } catch (e) {
    err = e;
    logger.captureException(e);
  }
  const parsedJson = {
    Count,
    Data,
    Meta,
    State,
    TextAnnotations,
    annotated_count,
    signed_urls,
  };
  return { parsedJson, err };
};

export const getSupportedActions = (
  labelName: string,
  labelType: string,
  postProcessingConfig: PostprocessingConfig | undefined
) => {
  let supportedActions = [
    actionTypes.contains,
    actionTypes.is,
    actionTypes.exists,
    actionTypes.doesnt_exist,
  ];
  let inputType: FilterInputType = inputTypes.text;

  const map = Object.values(postProcessingConfig?.id_map ?? {}).find(
    (map) =>
      map.name === labelName && map.label_type === labelType && map.source_step
  );

  if (map) {
    const step = postProcessingConfig?.steps[map?.source_step];

    step?.setting?.rules?.forEach((rule) => {
      switch (rule.type) {
        case "parse_date":
        case "date_eu":
        case "date_us":
        case "date_iso":
          supportedActions = [
            actionTypes.is,
            actionTypes.between,
            actionTypes.before,
            actionTypes.after,
            actionTypes.exists,
            actionTypes.doesnt_exist,
          ];
          inputType = inputTypes.date;
          break;
        case "integer":
          supportedActions = [
            actionTypes.is,
            actionTypes.greater_than,
            actionTypes.smaller_than,
            actionTypes.exists,
            actionTypes.doesnt_exist,
          ];
          inputType = inputTypes.int;
          break;
        case "float":
          supportedActions = [
            actionTypes.is,
            actionTypes.greater_than,
            actionTypes.smaller_than,
            actionTypes.exists,
            actionTypes.doesnt_exist,
          ];
          inputType = inputTypes.float;
          break;
        default:
          break;
      }
    });
  }

  return { supportedActions, inputType };
};

export const getDates = (filters: Filter[]) => {
  const dateFilter = filters.find((f: { id: string }) => f.id === "created");
  let startDate, endDate;

  if (dateFilter) {
    const endOfDay = moment().endOf("day");
    switch (dateFilter.action) {
      case actionTypes.today:
        startDate = moment(endOfDay).subtract(1, "day");
        endDate = endOfDay;
        break;
      case actionTypes.last_7_days:
        startDate = moment(endOfDay).subtract(7, "day");
        endDate = endOfDay;
        break;
      case actionTypes.last_30_days:
        startDate = moment(endOfDay).subtract(1, "month");
        endDate = endOfDay;
        break;
      case actionTypes.is:
        startDate = moment(dateFilter.start);
        endDate = moment(dateFilter.start).add(1, "day");
        break;
      default:
        startDate = moment(dateFilter.start);
        endDate = moment(dateFilter.end);
        break;
    }
  }

  return { startDate, endDate };
};
