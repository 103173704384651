import { observable, action, decorate } from "mobx";

export default class UploadStore {
  files = new Map();

  reset() {
    this.files = new Map();
  }

  updateFiles(files) {
    this.files = files;
  }
}

decorate(UploadStore, {
  files: observable,
  reset: action,
  updateFiles: action,
});
