import intercomMock from "./intercom-mock";
import intercomOriginal from "./intercom";
import { hasAccess } from "Utils/access";

let intercom_obj;

if (
  !hasAccess("intercomm") ||
  process.env["REACT_APP_REGION"] === "eu" ||
  process.env["REACT_APP_REGION"] === "us"
) {
  intercom_obj = intercomMock;
} else {
  intercomOriginal.init();
  intercomOriginal.shutdown();
  intercom_obj = intercomOriginal;
}

export default intercom_obj;
