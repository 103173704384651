import {
  InferenceInfo,
  PageLevelInference,
  PageLevelInference2,
} from "types/inference";
import { SignedUrls } from "types/ocr";
import { getTimeInt } from "Utils";
import { v1 } from "uuid";
import { assignIdsToBoxes, getBoxes, rotateBoxes } from "./ocr";

export const convert_PageLevelInference2_to_PageLevelInference = (
  result: PageLevelInference2
): PageLevelInference => {
  return {
    ...result,
    filepath: result.url,
    input: result.original_file_name,
    message: "Success",
    prediction: result.predicted_boxes,
  };
};

export const convertInferenceToOcrResult = (
  result: PageLevelInference,
  signed_urls: SignedUrls | undefined,
  file_url: string
): InferenceInfo => {
  const {
    day_since_epoch,
    filepath,
    hour_of_day,
    id,
    input,
    original_file_name,
    request_file_id,
    rotation,
    size,
  } = result;

  assignIdsToBoxes(result);

  const filename =
    (original_file_name || input || filepath)?.split("/").pop() || "";

  const _request_file_id =
    request_file_id === "00000000-0000-0000-0000-000000000000"
      ? "fake-" + v1()
      : request_file_id;

  let uploadedAt = 0;
  try {
    uploadedAt = getTimeInt(id);
  } catch (e) {
    uploadedAt = (day_since_epoch * 24 + hour_of_day) * 3600 * 1000;
  }

  const updated_at = getTimeInt(result.updated_at);

  const rotateBy = (360 - rotation) % 360;
  let boxes = getBoxes(result);
  if (size && rotateBy) {
    boxes = rotateBoxes(boxes, size, rotateBy);
  }

  return {
    ...result,
    ...signed_urls,
    filename,
    moderated_boxes: boxes,
    request_file_id: _request_file_id,
    signed_urls,
    uploadedAt,
    updated_at,
    original_filename: original_file_name,
    file_url,
  };
};
