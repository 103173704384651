import queryClient from "queryClient";
import { useMutation, useQuery } from "react-query";
import { errors, getModelType, getTimeInt } from "Utils";
import { useFields, useTableHeaders } from "./labels";
import { useUser } from "./user";
import {
  Model,
  ModelChangelog,
  ModelChangelogServer,
  ModelType,
  ModelType2,
} from "types/Model";
import { User } from "types/user";
import { isPretrainedModel } from "Utils";
import { useModelId } from "./misc";
import { useStores } from "./use-stores";
import { queryKeys } from "./queryKeys";
import { toast } from "Utils/toast";

export const useModel = (modelId: string) => {
  const { appStore } = useStores();

  return useQuery<Model, ErrorEvent>(
    ["model", modelId],
    () => {
      return fetch(`/modelDetails/?appId=${modelId}`).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (!res.ok) {
          throw new Error(data?.errors?.[0]?.reason || "Failed to fetch model");
        }

        // update legacy store data
        appStore.setAppId(data.model_id);
        appStore.setModel(data);
        appStore.setModelType(getModelType(data));

        return data;
      });
    },
    { enabled: !!modelId && !isPretrainedModel(modelId) }
  );
};

export const useFreeTrailMetadata = (modelId: string) => {
  return useQuery<any>(["freeTrailMetadata", modelId], () => {
    return fetch(
      `/api/v2/ObjectDetection/Model/${modelId}/FreeTrialExtensionMetadata`
    ).then(async (res) => {
      if (!res.ok)
        throw new Error("Error while fetching model free trial metadata");
      return res.json();
    });
  });
};

export const invalidateModelFreeTrialMetadata = (modelId: string) => {
  queryClient.invalidateQueries(["freeTrailMetadata", modelId]);
};

export const useModelType = (modelId?: string): ModelType2 => {
  const _modelId = useModelId();
  const { data: model } = useModel(modelId || _modelId);

  return getModelType(model);
};

export const useIsPerDocumentProcessing = (modelId: string) => {
  const { data: model } = useModel(modelId);
  return !!model?.metadata?.feature_flags?.isPerDocumentProcessing;
};

export const useModels = (email: string) => {
  return useQuery(
    ["models", email],
    () => {
      return fetch("/user/models", {
        method: "post",
        body: JSON.stringify({
          email,
        }),
      }).then((res) => {
        if (!res.ok) throw new Error("Error while fetching user models");
        return res.json();
      });
    },
    { enabled: !!email }
  );
};

const modelUpdateUrl = (modelId: string, modelType: ModelType) => {
  let url = "";
  switch (modelType) {
    case "classification":
      url = `/api/v2/ImageCategorization/Model/?modelId=${modelId}`;
      break;
    case "multilabelclassification":
      url = `/api/v2/MultiLabelClassification/Model/${modelId}/`;
      break;
    case "similarity":
      url = `/api/v2/Similarity/Model/${modelId}/`;
      break;
    case "custom":
      url = `/api/v2/custom-models/${modelId}`;
      break;
    default:
      url = `/api/v2/ObjectDetection/Model/${modelId}/`;
  }

  return url;
};

const updateModel = (
  modelId: string,
  modelType: ModelType,
  payload: object
) => {
  const url = modelUpdateUrl(modelId, modelType);
  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify(payload),
  }).then(async (res) => {
    const data = await res.json();

    if (res.status === 403) {
      throw new Error(data?.errors?.[0]?.message);
    }

    if (!res.ok) {
      throw new Error("Error while updating model");
    }

    return data;
  });
};

const deleteModel = (modelId: string, modelType: ModelType) => {
  const url = modelUpdateUrl(modelId, modelType);

  return fetch(url, {
    method: "DELETE",
  }).then(async (res) => {
    if (res.ok) return res.statusText;
    const data = await res.json();

    if (res.status === 403) {
      throw new Error(data?.errors?.[0]?.message);
    }

    if (!res.ok) {
      throw new Error("Error while deleting model");
    }

    return data;
  });
};

export const useUpdateModel = () => {
  return useMutation<
    Model,
    ErrorEvent,
    { modelId: string; modelType: ModelType; payload: object }
  >(
    ({ modelId, modelType, payload }) =>
      updateModel(modelId, modelType, payload),
    {
      onSuccess: (data) => {
        toast.success("Model updated");
      },
      onError: (error) => {
        toast.error(error.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["user"]);
      },
    }
  );
};

const createICModel = (payload: any) => {
  return fetch(`/api/v2/ImageCategorization/Model/`, {
    credentials: "include",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then(async (res) => {
    const data = await res.json();

    if (res.status === 403) {
      throw new Error(data?.errors?.[0]?.message);
    }

    if (!res.ok) {
      throw new Error("Error while creating model");
    }

    return data;
  });
};

export const useAddModel = () => {
  return useMutation<Model, ErrorEvent, { payload: any }>(
    ({ payload }) => createICModel(payload),
    {
      onSuccess: (data) => {
        toast.success("Model created");
        queryClient.invalidateQueries(["user"]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useDeleteModel = () => {
  return useMutation<
    any,
    ErrorEvent,
    { modelId: string; modelType: ModelType }
  >(({ modelId, modelType }) => deleteModel(modelId, modelType), {
    onSuccess: (data, { modelId }) => {
      toast.success("Model deleted");

      queryClient.setQueryData<User | undefined>(["user"], (prevUser) => {
        if (!prevUser) return undefined;

        const user = { ...prevUser };
        user.Apps = prevUser.Apps.filter((model) => model.app_id !== modelId);
        return user;
      });
    },
    onError: (error) => {
      toast.error(error.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["user"]);
    },
  });
};

export const invalidateModel = (modelId: string) => {
  queryClient.invalidateQueries(["model", modelId]);
  queryClient.invalidateQueries(["freeTrailMetadata", modelId]);
};

export const useCloneModel = () => {
  const { data: user } = useUser();

  return useMutation<any, ErrorEvent, string>((modelId) => {
    return fetch(`/api/v2/miscroutes/lightclonemodel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        email: user?.EmailId,
        model_id: modelId,
      }),
    })
      .then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while cloning model");
        }

        return data;
      })
      .catch((error) => {
        toast.error(error.message);
      });
  });
};

export const useForceRetrainModel = () => {
  return useMutation<any, any, { modelId: string }>(
    ({ modelId }) => {
      const url = `/api/v2/OCR/Model/${modelId}/Train/?force_retrain=true`;

      return fetch(url, { method: "POST" }).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (res.status === 403) {
          toast.error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw data;
        }

        return data;
      });
    },
    {
      onSuccess: ({ modelId }: { modelId: string }) => {
        invalidateModel(modelId);
      },
    }
  );
};

export const useRetrainModel = () => {
  return useMutation<any, any, { modelId: string }>(
    ({ modelId }) => {
      const url = `/api/v2/OCR/Model/${modelId}/Train/?retrain=true`;

      return fetch(url, { method: "POST" }).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (res.status === 403) {
          toast.error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw data;
        }

        return data;
      });
    },
    {
      onSuccess: (data, { modelId }) => {
        invalidateModel(modelId);
      },
    }
  );
};

export const useTrainModel = (modelId: string) => {
  const { data: user } = useUser();
  const { data: model } = useModel(modelId);
  const fields = useFields(modelId);
  const tableHeaders = useTableHeaders(modelId);

  return useMutation<any, any, null>(
    () => {
      if (fields.length === 0 && tableHeaders.length === 0) {
        throw new Error(errors.no_labels);
      } else if (
        !model?.is_paid &&
        model?.number_of_retrainings_completed &&
        user?.NumberOfRetrainingsAllowed &&
        model?.number_of_retrainings_completed >=
          user?.NumberOfRetrainingsAllowed
      ) {
        throw new Error(errors.training_limit_exceeded);
      }

      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/Train/`, {
        method: "POST",
      }).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (res.status === 403) {
          toast.error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw data;
        }

        return data;
      });
    },
    {
      onSettled: () => {
        invalidateModel(modelId);
        queryClient.invalidateQueries(queryKeys.modelChangelog.model(modelId));
      },
    }
  );
};

export const useUpdateTableExtraction = () => {
  return useMutation<
    any,
    ErrorEvent,
    { modelId: string; extractTables: boolean }
  >(
    ({ modelId, extractTables }) => {
      return fetch(
        `/api/v2/ObjectDetection/Model/${modelId}/ActivateTableExtraction`,
        {
          method: "PATCH",
          body: JSON.stringify({ extract_tables: extractTables }),
        }
      ).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to update table extraction");
        }

        return data;
      });
    },
    {
      onSuccess: (data, { modelId, extractTables }) => {
        if (extractTables) {
          queryClient.invalidateQueries({
            predicate: ({ queryKey }) =>
              queryKey[0] === "annotation" &&
              queryKey[1] === modelId &&
              !!queryKey[2],
          });
        }

        queryClient.setQueryData<Model | undefined>(
          ["model", modelId],
          (prevData) => {
            if (!prevData) return prevData;

            return {
              ...prevData,
              metadata: {
                ...prevData.metadata,
                extract_tables: extractTables,
              },
            };
          }
        );
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useIsModelUpdateBlocked = (modelId: string) => {
  const { data: model } = useModel(modelId);

  return Boolean(
    model?.pretrained_model_metadata.cloned_from_pretrained &&
      model?.pretrained_model_metadata.model_retrained_after_cloning ===
        false &&
      model?.is_paid === false
  );
};

export const useModelChangelog = (modelId: string) => {
  return useQuery<ModelChangelog[], ErrorEvent>({
    queryKey: queryKeys.modelChangelog.model(modelId),
    queryFn: () => {
      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/ModelChangeLogs`)
        .then((res) => {
          if (!res.ok)
            throw new Error("Error while fetching model activity log");
          return res.json();
        })
        .then((json: ModelChangelogServer[]) =>
          json?.map((v) => ({
            ...v,
            modifiedAt: getTimeInt(v.modified_at),
          }))
        );
    },
  });
};
