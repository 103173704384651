import { cloneDeep } from "lodash";
import { defaultServerConfig } from "./rules";
import { labelTypes } from "Utils";
import { paramTypes } from "./rules";

const foundMultipleMatchingRules = (params) => {
  const rules = params.filter((p) => p.type === "additional-columns");
  return rules.length > 1;
};

const foundNonExactMatchRuleParams = (params) => {
  const index = params.findIndex(
    (p) => p.name === "How to match?" && p.value !== "exact-match"
  );
  return index !== -1;
};

const getDbRulesIndices = (params) => {
  let dbRuleParamIndex = -1,
    tableRuleParamIndex = -1,
    columnRuleParamIndex = -1;

  params.forEach((param, index) => {
    if (param.type === paramTypes.GET_DBS) dbRuleParamIndex = index;
    if (param.type === paramTypes.GET_TABLES) tableRuleParamIndex = index;
    if (param.type === paramTypes.GET_COLUMNS) columnRuleParamIndex = index;
  });
  return { dbRuleParamIndex, tableRuleParamIndex, columnRuleParamIndex };
};

const getDBListFields = (modelId, labelType) => {
  return fetch(`/api/v2/Model/${modelId}/labels/?type=${labelType}`, {
    credentials: "include",
    method: "GET",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Error occured while getting db list fields");
    }
    return res.json();
  });
};

const deleteDBListField = (modelId, labelName, labelType) => {
  return fetch(
    `/api/v2/Model/${modelId}/labels/?type=${labelType}&label=${labelName}`,
    {
      credentials: "include",
      method: "DELETE",
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error("Error occured while deleting db list field");
    }
  });
};

const saveDBListField = (modelId, labelName, labelType) => {
  return fetch(
    `/api/v2/Model/${modelId}/labels/?type=${labelType}&label=${labelName}`,
    {
      credentials: "include",
      method: "POST",
    }
  ).then((res) => {
    if (!res.ok) {
      throw new Error("Error occured while saving db list field");
    }
  });
};

const parseServerConfigNew = (
  serverConfig = {},
  labelName,
  isTableHeader = false
) => {
  const label_type = isTableHeader ? labelTypes.table_column : labelTypes.field;
  const key = isTableHeader ? "table_headers" : "fields";
  const _defaultServerConfig = cloneDeep(defaultServerConfig);

  const _config =
    serverConfig?.[key]?.find(
      (fieldConfig) => fieldConfig.label_name === labelName
    ) || {};

  const config = {
    ..._defaultServerConfig,
    ..._config,
  };

  config.label_name = labelName;
  config.label_type = label_type;
  return config;
};

const getPostprocessingConfig = (modelId) => {
  const url = `/api/v2/ObjectDetection/Model/${modelId}/PostprocessingConfig`;
  return fetch(url, {
    credentials: "include",
    method: "GET",
  }).then((res) =>
    res.ok
      ? res.json()
      : Promise.reject(new Error("Failed to fetch postprocessing config"))
  );
};

const savePostprocessingConfig = (modelId, postProcessingConfig) => {
  postProcessingConfig.model_id = modelId;

  const url = `/api/v2/ObjectDetection/Model/${modelId}/PostprocessingConfig`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    body: JSON.stringify(postProcessingConfig),
  }).then((res) => {
    if (!res.ok) throw new Error("Failed to save postprocessing config");
  });
};

export {
  savePostprocessingConfig,
  getPostprocessingConfig,
  parseServerConfigNew,
  getDBListFields,
  deleteDBListField,
  saveDBListField,
  getDbRulesIndices,
  foundNonExactMatchRuleParams,
  foundMultipleMatchingRules,
};
