import * as Sentry from "@sentry/react";
import { setLogger } from "react-query";

const isProduction = process.env.NODE_ENV === "production";
const captureException = isProduction ? Sentry.captureException : console.error;
const captureMessage = isProduction ? Sentry.captureMessage : console.log;

setLogger({
  log: captureMessage,
  warn: captureMessage,
  error: captureException,
});

const logger = {
  captureException,
  captureMessage,
};

export { captureException, captureMessage };
export default logger;
