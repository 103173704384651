export const inferenceFilesCountKeys = {
  all: (modelId: string) => ["inferenceFileCounts", modelId],
};

export const annotationFilesCountKeys = {
  all: (modelId: string) => ["annotationFileCounts", modelId],
};

export const exportRunHistoryKeys = {
  file: (fileId: string) => ["exportRunHistory", { fileId }],
};

export const validationErrorsKeys = {
  file: (modelId: string, fileId: string) => [
    "validationErrors",
    { modelId, fileId },
  ],
};

export const inferenceKeys = {
  file: (modelId: string, fileId: string) => [
    "fileLevelInference",
    { modelId, fileId },
  ],
};

export const fileActivityLogKey = (modelId: string, fileId: string) => [
  "fileActivityLog",
  { modelId, fileId },
];

export const queryKeys = {
  modelChangelog: {
    model: (modelId: string) => ["modelChangelog", modelId],
  },
  reviewStages: {
    model: (modelId: string) => ["reviewStages", modelId],
    byId: (modelId: string, stageId: string) => [
      "reviewStages",
      modelId,
      stageId,
    ],
  },
  export: {
    model: (modelId: string) => ["exportinfo", modelId],
  },
  featureFlags: {
    user: (email: string) => ["featureFlags", email],
  },
};
