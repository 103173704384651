import {
  CircularProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";

export const CustomMenu = withStyles({
  paper: {
    borderRadius: 8,
  },
})(Menu);

export const CustomListItem = withStyles({
  root: {
    color: "#354454",
    height: 48,
  },
})(MenuItem) as typeof MenuItem;

export const CustomListItemIcon = withStyles({
  root: {
    color: "#354454",
    fontSize: 20,
    minWidth: 32,
  },
})(ListItemIcon);

export const CustomListItemText = withStyles({
  root: {
    // color: "#354454",
    fontSize: 14,
  },
})(Typography);

export const Loader = () => <CircularProgress color="inherit" size={20} />;
