function update(profile) {
  console.log("call mock update");
}

function shutdown() {
  console.log("call mock shutdown");
}

function hide() {
  console.log("call mock hide");
}

const mock = {
  update,
  shutdown,
  hide,
};

export default mock;
