import { useMutation, useQuery } from "react-query";
import queryClient from "queryClient";
import { SUPPORTED_INTEGRATIONS } from "Utils/integrations";
import {
  Integration,
  IntegrationInfo,
  IntegrationType,
} from "types/integration";
import { useModel } from "./model";
import { useModelId } from "./misc";
import { useUser } from "./user";
import { toast } from "Utils/toast";

export function getDatabaseDetails(
  integrations: Integration[] | undefined,
  integrationId: string
) {
  const database = integrations?.find((db) => db.id === integrationId);
  return {
    ...database,
    icon: database?.type && SUPPORTED_INTEGRATIONS[database.type].icon,
  };
}

export const useIntegrationDetails = (integrationId: string) => {
  const integrations = useIntegrations();
  return getDatabaseDetails(integrations.data, integrationId);
};

export const useIntegrations = () => {
  // send the modelEmail as a query param to allow the admins to see others' integrations as well
  const modelId = useModelId();
  const model = useModel(modelId || "");
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  const modelEmail = model.data?.email || userEmail;

  return useQuery<Integration[], ErrorEvent>(["integrations", modelEmail], () =>
    fetch(
      `/api/v2/externalIntegrations?email=${encodeURIComponent(
        modelEmail || ""
      )}`
    ).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(new Error("Error while fetching integrations"))
    )
  );
};

export const useTables = (integration_id: string) => {
  const integration = useIntegrationDetails(integration_id);
  const isCsv = integration.type === "csv";

  // send the modelEmail as a query param to allow the admins to see others' integrations as well
  const modelId = useModelId();
  const model = useModel(modelId || "");
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  const modelEmail = model.data?.email || userEmail;

  return useQuery<string[], ErrorEvent>(
    ["integrations", integration_id, modelEmail],
    () => {
      if (isCsv) return [];
      return fetch(
        `/api/v2/externalIntegrations/${integration_id}/tables?email=${encodeURIComponent(
          modelEmail || ""
        )}`
      ).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching tables"))
      );
    },
    {
      enabled: !!integration_id,
    }
  );
};

export const useColumns = (integration_id: string, table_name: string) => {
  // send the modelEmail as a query param to allow the admins to see others' integrations as well
  const modelId = useModelId();
  const model = useModel(modelId || "");
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  const modelEmail = model.data?.email || userEmail;

  return useQuery<string[], ErrorEvent>(
    ["integrations", integration_id, table_name, modelEmail],
    () => {
      return fetch(
        `/api/v2/externalIntegrations/${integration_id}/tableschema?table_name=${table_name}&email=${encodeURIComponent(
          modelEmail || ""
        )}`
      ).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching columns"))
      );
    },
    {
      enabled: !!(integration_id && table_name),
    }
  );
};

export const useAddIntegration = () => {
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  return useMutation<
    Integration,
    ErrorEvent,
    {
      name: string;
      type: IntegrationType;
      info: IntegrationInfo;
    }
  >({
    mutationFn: ({ type, info, name }) => {
      return fetch(`/api/v2/externalIntegrations`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ type, info, name }),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to add integration");
        }

        return data;
      });
    },
    onSuccess: (data, { type, info }) => {
      queryClient.setQueryData<Integration[] | undefined>(
        ["integrations", userEmail],
        (prevData) => {
          return prevData ? [...prevData, data] : prevData;
        }
      );

      if (type === "csv") {
        data.info.fileInfo = info.fileInfo;
      }

      if (type === "quickbooks" || type === "salesforce") {
        toast.info("Integration added, redirecting for Authorization");
      } else {
        toast.success("Integration added");
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useAuthorizeIntegration = () => {
  return useMutation<{ redirectUrl: string }, ErrorEvent, string>({
    mutationFn: (integrationId) => {
      return fetch(`/api/v2/externalIntegrations/${integrationId}/authorize`, {
        method: "POST",
        body: JSON.stringify({
          redirect_url: window.location.href,
        }),
      }).then((res) =>
        res.ok ? res.json() : Promise.reject(new Error("Authorization failed"))
      );
    },
  });
};

export const useUpdateIntegration = () => {
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  return useMutation<
    Integration,
    ErrorEvent,
    {
      integration_id: string;
      name: string;
      info: any;
    }
  >({
    mutationFn: ({ info, name, integration_id }) => {
      return fetch(`/api/v2/externalIntegrations/${integration_id}`, {
        method: "PATCH",
        body: JSON.stringify({ info, name }),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to update integration");
        }

        return data;
      });
    },
    onSuccess: (data, { integration_id }) => {
      toast.success("Integration updated");
      queryClient.setQueryData<Integration[] | undefined>(
        ["integrations", userEmail],
        (prevData) => {
          return prevData
            ? prevData.map((integration) =>
                integration.id === integration_id ? data : integration
              )
            : prevData;
        }
      );
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};

export const useDeleteIntegration = () => {
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  return useMutation<null, ErrorEvent, { integration_id: string }>({
    mutationFn: ({ integration_id }) => {
      return fetch(`/api/v2/externalIntegrations/${integration_id}`, {
        method: "DELETE",
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to delete integration");
        }

        return data;
      });
    },
    onSuccess: (_, { integration_id }) => {
      toast.success("Integration deleted");
      // queryClient.invalidateQueries(["integrations"]);
      queryClient.setQueryData<Integration[] | undefined>(
        ["integrations", userEmail],
        (prevData) => {
          return prevData
            ? prevData.filter(
                (integration) => integration.id !== integration_id
              )
            : prevData;
        }
      );
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
