import { Integration } from "types/integration";
import { PostprocessingStep } from "types/workflow/postprocessing";

export const addCsvDataToPostProcessingConfig = (
  config: PostprocessingStep,
  integrations: Integration[]
): PostprocessingStep => {
  return {
    ...config,
    setting: {
      ...config.setting,
      rules: config.setting.rules.map((rule) => {
        const dbParam = rule.params.find((p) => p.type === "get-dbs");
        const integration = integrations.find(
          ({ id }) => id === dbParam?.value
        );

        return {
          ...rule,
          params: rule.params.map((param) => {
            const updatedParam = { ...param };
            if (param.type === "get-dbs" && integration) {
              updatedParam.integrationType = integration.type;
              updatedParam.tableName = integration.info.postgres_table_name;
            }
            if (
              param.type === "get-tables" &&
              !param.value &&
              integration?.type === "csv"
            ) {
              updatedParam.value = integration.info.postgres_table_name ?? "";
            }
            return updatedParam;
          }),
        };
      }),
    },
  };
};
