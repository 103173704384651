// Use global access management
import { isAdmin } from "hooks/user";
import mainStore from "../stores/mainStore";

export const hasAccess = (feature: string) => {
  // Calculate access based on IS_ON_PREM tag
  const isOnPrem = process.env.REACT_APP_IS_ON_PREM === "true";
  switch (feature) {
    case "intercomm":
    case "analytics_scripts":
    case "sentry":
    case "my_account.billing":
    case "my_account.whitelabel":
    case "my_account.team":
    case "whats_new":
    case "support_requests":
    case "new_model.prebuilt":
    case "upload.need_help":
    case "add_files.cloud":
    case "add_files.email":
    case "export_to.external":
    case "fullview.share":
    case "training.upgrade":
    case "model_versions.cpu_docker":
    case "model_versions.gpu_docker":
    case "extract_data.upgrade":
    case "extract_data.request_demo":
    case "extract_data.assignee":
    case "integrate.docker":
    case "document_type_seperator":
    case "fullview.upgrade":
    case "fullview.request_demo":
    case "my_models.plan":
    case "checkout_page":
    case "home.onboarding":
      return !isOnPrem;
    case "auth.auto_login":
      return isOnPrem;
    case "experiment.metrics":
      return isExperimentMetricsEnabled();
    case "use_cassandra.annotate":
      return true;
    case "use_cassandra.annotate.init":
      return true;
    case "use_cassandra.test":
      return false;
    case "use_cassandra.test.init":
      return true;
    case "new_model.custom":
    case "add_files.my_device":
    default:
      return true;
  }
};

const isExperimentMetricsEnabled = () => {
  const { user } = mainStore;

  const _isAdmin = isAdmin(user);

  const email = user.EmailId;

  if (_isAdmin) return true;
  if (email?.endsWith("@elemica.com")) return true;
  if (email?.endsWith("@entergy.com")) return true;
  if (email?.endsWith("@promised-land.de")) return true;
  if (email?.endsWith("@pocketpills.com")) return true;
  if (email?.endsWith("@se.com")) return true;
  if (email?.endsWith("@usaveinclinics.com")) return true;
  if (email?.endsWith("@spenmo.com")) return true;
  if (email === "rob.sanders@orchard.com") return true;

  return false;
};

export const hasSectionEditAccess = (feature: string) => {
  switch (feature) {
    case "Build":
    case "Workflow":
    case "Settings":
    case "Integrations":
      return isEditAccessAllowed();
    default:
      return true;
  }
};

const isEditAccessAllowed = () => {
  const { user } = mainStore;
  const email = user.EmailId;
  if (email?.endsWith("@makemytrip.com")) return false;
  return true;
};
