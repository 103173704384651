import { observable, action, decorate } from "mobx";

export default class GoogleDriveStore {
  isCancellingUpload = false;

  setCancelStatus = (status) => {
    this.isCancellingUpload = status;
  };
}

decorate(GoogleDriveStore, {
  isCancellingUpload: observable,
  setCancelStatus: action,
});
