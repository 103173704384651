import { Alert } from "@material-ui/lab";
import * as Sentry from "@sentry/react";

function FallbackComponent() {
  return (
    <div className="m-2 flex">
      <Alert severity="error">Something went wrong.</Alert>
    </div>
  );
}

function ErrorBoundary({ children }) {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;

export const withErrorBoundary = (Component) => {
  return (props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};
