import {
  observable,
  computed,
  action,
  reaction,
  runInAction,
  decorate,
} from "mobx";
import { AbortController } from "yetch";
import logger from "Utils/logger";
import MainStore from "./mainStore";
import { trackPage } from "Utils/analytics";
import { ui_config } from "assets/ui-config";
import { useLocalStore } from "mobx-react-lite";
import { isPretrainedModel } from "Utils";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MODEL_WISE_LIMITS = {
  regular_user: {
    od: {
      images: 50,
      annotations: 50,
    },
    ocr: {
      images: 10,
      annotations: 10,
    },
    ic: {
      images: 10,
    },
    mlc: {
      images: 50,
      annotations: 50,
    },
    similarity: {
      images: 50,
    },
    custom: {
      images: 0,
    },
  },
  admin_user: {
    od: {
      images: 1,
      annotations: 1,
    },
    ocr: {
      images: 10,
      annotations: 10,
    },
    ic: {
      images: 1,
    },
    mlc: {
      images: 1,
      annotations: 1,
    },
    similarity: {
      images: 1,
    },
    custom: {
      images: 0,
    },
  },
};

class AppStore {
  alert = {
    open: false,
    severity: "info",
    message: "",
  };
  controller = new (window.AbortController || AbortController)();
  isProTrialStartedDialogOpen = false;
  isProTrialCancelledDialogOpen = false;
  modelType = "";
  appId = "";
  app = {};
  model = {};
  status = "";
  newUploadCount = 0;
  fetchingAnnotations = false;
  fetchingConfirmScreenMetrics = false;
  modelWiseLimits = MODEL_WISE_LIMITS["regular_user"];
  overallF1Score = 0;
  labelF1Scores = {};
  requestModelFlag = false;
  isDrawerExpanded = true;
  isPretrainedModelRetrainingBlockerOpen = false;

  API_CALL_LIMITS = {
    "": 500,
    ocr_fields_10000: 10000,
    ocr_fields_50000: 50000,
    custom: "custom",
  };

  setModel = action((model) => {
    this.app = model;
    this.model = model;
  });

  get hasNestedMenu() {
    try {
      if (
        ui_config[this.model.model_id] &&
        ui_config[this.model.model_id]["menuList"] === true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  get isCustomExport() {
    try {
      if (
        ui_config[this.model.model_id] &&
        ui_config[this.model.model_id]["isCustomExport"] === true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  get labels() {
    return this.app.categories || [];
  }

  get labelNames() {
    if (!this.app.labels) return [];
    return this.app.labels.slice().sort((a, b) => {
      return String(a).toLowerCase() < String(b).toLowerCase() ? -1 : 1;
    });
  }

  get tableCategories() {
    if (!this.app.metadata || !this.app.metadata.table_categories) return [];
    return this.app.metadata.table_categories;
  }

  get fieldsToShow() {
    if (this.app.metadata?.captured_data_view?.fields?.length > 0) {
      const fieldsToShow = this.app.metadata.captured_data_view.fields.filter(
        (l) => {
          return this.labelNames.includes(l);
        }
      );
      return fieldsToShow;
    } else {
      return [];
    }
  }

  get tableHeaders() {
    let table_headers = [];
    this.tableCategories.forEach((c) => {
      if (c.label === "") {
        table_headers = c.headers;
      }
    });
    table_headers = table_headers.filter((header) => header !== "");
    return table_headers;
  }

  get allFields() {
    return [...this.labelNames, ...this.tableHeaders];
  }

  get isModelTrained() {
    return Boolean(this.app.model_state && this.app.model_state >= 5);
  }

  get TestUrls() {
    return this.app.test_urls || [];
  }

  get isModelClonedFromPretrained() {
    return Boolean(
      this.model &&
        this.model.pretrained_model_metadata &&
        this.model.pretrained_model_metadata.cloned_from_pretrained === true
    );
  }

  get isPretrainedTablesModel() {
    return Boolean(
      this.app?.pretrained_model_metadata?.is_table_pretrained_model
    );
  }

  get isUnpaidPretrainedTablesModel() {
    return Boolean(
      this.app?.pretrained_model_metadata?.is_table_pretrained_model &&
        !this.app.is_paid
    );
  }

  get isModelRetrainedAfterCloning() {
    return Boolean(
      this.model &&
        this.model.pretrained_model_metadata &&
        this.model.pretrained_model_metadata.model_retrained_after_cloning ===
          true
    );
  }

  get isModelPretrainedOCR() {
    return (
      this.app &&
      this.app.pretrained_model_metadata &&
      this.app.pretrained_model_metadata.architecture === "pretrained_invoice"
    );
  }

  get isLineItemsEnabled() {
    if (this.app && this.app.metadata) {
      return this.app.metadata.extract_tables || this.app.is_paid;
    } else {
      return null;
    }
  }

  get extractTables() {
    return this.app && this.app.metadata && this.app.metadata.extract_tables;
  }

  setExtractTables = action((extract_tables) => {
    if (this.app && this.app.metadata) {
      this.app.metadata.extract_tables = extract_tables;
    }
  });

  get showCropImagesOnRHS() {
    return (
      this.app && this.app.metadata && this.app.metadata.show_crop_images_on_rhs
    );
  }

  get isAsyncFeatureFlag() {
    return !!this.model?.metadata?.feature_flags?.isAsync;
  }

  openAlert = async (options) => {
    this.closeAlert();
    await sleep(100);
    this.showAlert(options);
  };

  showAlert = action(
    // options = { message, level }
    (options) => {
      if (typeof options === "string") {
        this.alert = {
          open: true,
          message: options,
        };
      } else {
        this.alert = {
          open: true,
          ...options,
        };
      }
    }
  );

  closeAlert = action(() => {
    this.alert = {
      ...this.alert,
      open: false,
    };
  });

  openProTrialStartedDialog = action(
    () => (this.isProTrialStartedDialogOpen = true)
  );

  closeProTrialStartedDialog = action(
    () => (this.isProTrialStartedDialogOpen = false)
  );

  openProTrialCancelledDialog = action(
    () => (this.isProTrialCancelledDialogOpen = true)
  );

  closeProTrialCancelledDialog = action(
    () => (this.isProTrialCancelledDialogOpen = false)
  );

  setLabelF1Scores = action((f1ScoreObj) => (this.labelF1Scores = f1ScoreObj));

  setOverallF1Score = action((f1Score) => (this.overallF1Score = f1Score));

  setAppId = action((appId) => (this.appId = appId));

  setFetchingAnnotations = action((flag) => (this.fetchingAnnotations = flag));

  setFetchingConfirmScreenMetrics = action(
    (flag) => (this.fetchingConfirmScreenMetrics = flag)
  );

  resetApp = action(() => {
    this.appId = "";
    this.app = {};
    this.model = {};
  });

  resetStatus = action(() => (this.status = ""));

  resetUploadCount = action(() => (this.newUploadCount = 0));

  setAsyncUpload = action((async) => (this.asyncUpload = async));

  update_UploadCount = action((count, categoryName) => {
    if (categoryName) {
      const category = this.app.categories.find((v) => v.name === categoryName);
      category.count += count;
    } else {
      this.newUploadCount += count;
    }
  });

  updateModelState = action((model_state) => {
    if (this.app) {
      this.app.model_state = model_state;
    }
  });

  setStatus = action((status) => (this.status = status));

  setDescription = action(
    (description) => (this.model.description = description)
  );

  setFeatureFlags = action(
    (feature_flags) =>
      (this.model.metadata.feature_flags = {
        ...this.model.metadata.feature_flags,
        ...feature_flags,
      })
  );

  get modelDataForUpdate() {
    const model = this.model;
    const data = {
      description: model.description,
      model_accuracy: model.model_accuracy,
      model_graph: model.model_graph,
      metadata: {
        extract_tables: model.metadata ? model.metadata.extract_tables : false,
        entire_page_text: model.metadata
          ? model.metadata.entire_page_text
          : false,
        map_invoice_line_items: model.metadata
          ? model.metadata.map_invoice_line_items
          : false,
        show_crop_images_on_rhs: model.metadata
          ? model.metadata.show_crop_images_on_rhs
          : false,
        feature_flags:
          model.metadata && model.metadata.feature_flags
            ? model.metadata.feature_flags
            : "",
        gcn_override_config:
          model.metadata && model.metadata.gcn_override_config
            ? model.metadata.gcn_override_config
            : "",
      },
    };

    return data;
  }

  setCategories = action((_categories) => {
    if (_categories && _categories.length > 0) {
      this.app.categories.forEach((c1) => {
        const c = _categories.find((c2) => c2.name === c1.name);
        if (c) {
          c1.count = c.count;
        }
      });
    }
  });

  updateCategories = action(
    (_categories) => (this.app.categories = _categories)
  );

  updateTableCategories = action(
    (_table_categories) =>
      (this.app.metadata.table_categories = _table_categories)
  );

  updateSetThreshold = action(
    (_threshold) => (this.app.threshold = _threshold)
  );

  updateLinkedExports = action(
    (_linked_exports) => (this.app.linked_exports = _linked_exports)
  );

  getAppReaction = reaction(
    () => this.appId,
    (model_id) => {
      this.getAppInfo(model_id);
    }
  );

  getAppInfo = (appId, loadSilently) => {
    if (!appId) return;
    if (isPretrainedModel(appId)) return;

    if (appId === this.appId) {
      // if data is requested for same app
      // and fetch is already in progress - return
      if (this.status === "progress") return;
    } else {
      // if data is requested for another app
      // reset app data
      this.resetApp();
    }
    this.appId = appId;
    if (loadSilently !== true) {
      this.setStatus("progress");
      MainStore.setGlobalLoader(true);
    }

    this.controller.abort();
    this.controller = new (window.AbortController || AbortController)();
    fetch(`/modelDetails/?appId=${appId}`, {
      credentials: "include",
      signal: this.controller.signal,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("An error occured while fetching model details");
        }
        return res.json();
      })
      .then((data) => {
        MainStore.setGlobalLoader(false);
        runInAction(() => {
          this.resetStatus();
          data.categories = data.categories.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
          this.app = data;
          this.model = data;
          this.getModelExport();
          // giving an admin user access to admin limits to his model and all other admins
          if (MainStore.isAdmin && data.email.endsWith("@nanonets.com")) {
            this.modelWiseLimits = MODEL_WISE_LIMITS["admin_user"];
          }
        });
      })
      .catch((error) => {
        logger.captureException(error);
        MainStore.setGlobalLoader(false);
        runInAction(() => {
          this.setStatus("error");
        });
      });
  };

  getModelExport = () => {
    fetch(
      `/api/v2/externalIntegrations/model-export-info?modelId=${this.appId}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch model export info"))
      )
      .then((data) => {
        runInAction(() => {
          this.app.linked_exports = data;
          this.model.linked_exports = data;
        });
      })
      .catch(logger.captureException);
  };

  setModelType = (modelType) => {
    this.modelType = modelType;
  };

  toggleDrawer = () => {
    this.isDrawerExpanded = !this.isDrawerExpanded;
  };

  openDrawer = () => {
    this.isDrawerExpanded = true;
  };

  closeDrawer = () => {
    this.isDrawerExpanded = false;
  };

  get isFieldValidationEnabled() {
    return Boolean(this.model && this.model.validation_enabled);
  }
}

decorate(AppStore, {
  alert: observable,
  isProTrialStartedDialogOpen: observable,
  isProTrialCancelledDialogOpen: observable,
  isDrawerExpanded: observable,
  requestModelFlag: observable,
  overallF1Score: observable,
  labelF1Scores: observable,
  model: observable,
  labels: computed,
  labelNames: computed,
  tableCategories: computed,
  fieldsToShow: computed,
  tableHeaders: computed,
  allFields: computed,
  isModelTrained: computed,
  TestUrls: computed,
  isModelClonedFromPretrained: computed,
  isModelRetrainedAfterCloning: computed,
  isUnpaidPretrainedTablesModel: computed,
  isPretrainedTablesModel: computed,
  isModelPretrainedOCR: computed,
  isLineItemsEnabled: computed,
  showCropImagesOnRHS: computed,
  modelType: observable,
  appId: observable,
  app: observable,
  status: observable,
  newUploadCount: observable,
  asyncUpload: observable,
  isAsyncFeatureFlag: computed,
  fetchingAnnotations: observable,
  fetchingConfirmScreenMetrics: observable,
  modelWiseLimits: observable,
  setModelType: action,
  getAppInfo: action,
  toggleDrawer: action,
  openDrawer: action,
  closeDrawer: action,
  getModelExport: action,
  hasNestedMenu: computed,
  isCustomExport: computed,
  extractTables: computed,
  setExtractTables: action,
  isPretrainedModelRetrainingBlockerOpen: observable,
  isFieldValidationEnabled: computed,
});

const appStore = new AppStore();
window.addEventListener("hashchange", trackPage);

export const useAppStore = () => useLocalStore(() => appStore);
export default appStore;
