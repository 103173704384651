import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import { toast } from "Utils/toast";
import { buildRawEsQuery } from "Utils/filter";
import { useUser } from "./user";
import queryClient from "queryClient";
import { EmailSettings } from "types/user";
import { invalidateModelInferences } from "./imageLevelInference";
import { invalidateModel } from "./model";
import { SearchParams } from "types/misc";
import { queryKeys } from "./queryKeys";

export const onLabelChange = (modelId: string) => {
  invalidateModel(modelId);
  invalidateModelInferences(modelId);
  queryClient.invalidateQueries(queryKeys.reviewStages.model(modelId));
  queryClient.invalidateQueries(["postprocessingConfig", modelId]);
  queryClient.invalidateQueries(["exportinfo", modelId]);
};

export type Toggle = {
  on: boolean;
  setOn: () => void;
  setOff: () => void;
  toggle: () => void;
};

export const useToggle = (initialValue = false): Toggle => {
  const [on, setOnState] = React.useState(initialValue);

  const setOn = React.useCallback(() => setOnState(true), []);
  const setOff = React.useCallback(() => setOnState(false), []);
  const toggle = React.useCallback(() => setOnState((o) => !o), []);

  return { on, setOn, setOff, toggle };
};

export function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useUrlSearchParams() {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  return Object.fromEntries(urlSearchParams);
}

export function useModelId() {
  const searchParams = useUrlSearchParams();
  const params = useParams<SearchParams>();
  return params.modelId || searchParams.modelId || "";
}

export const usePublicLink = (
  modelId: string,
  fileId: string,
  shouldFetch: boolean,
  originUrl: string
) => {
  const { data: user } = useUser();

  return useQuery<string, ErrorEvent>(
    ["publicLink", modelId, fileId, shouldFetch],
    () => {
      const baseUrl = originUrl?.includes("eu.nanonets.com")
        ? "https://eu-preview.nanonets.com"
        : "https://preview.nanonets.com";
      return fetch(
        `${baseUrl}/Inferences/Model/${modelId}/ValidationUrl/${fileId}?expires=86400`,
        {
          method: "GET",
          credentials: "omit",
          headers: {
            Authorization:
              "Basic " + Buffer.from(user?.ApiKeys[0] + ":").toString("base64"),
          },
        }
      ).then((res) =>
        res.ok
          ? res.text()
          : Promise.reject(
              new Error("Error while fetching public shareable link")
            )
      );
    },
    {
      enabled: !!(shouldFetch && modelId && fileId),
    }
  );
};

export const getFileCounts = (
  url: string,
  searchText: string,
  filters: any[],
  modelId: string
) => {
  const _filters = filters.filter((f) => f.id !== "is_moderated");

  const rawQueryJson = buildRawEsQuery({
    filters: _filters,
    searchText,
    page: 1,
    rowsPerPage: 0,
    modelId,
  });

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(rawQueryJson),
  })
    .then((res) => {
      if (!res.ok) throw new Error("Failed to get annotation count");
      return res.json();
    })
    .then((data) => {
      const total_count = data?.data?.hits?.total || 0;
      const rejected_count =
        data?.data?.aggregations?.rejected_count?.doc_count || 0;
      const moderated_count =
        data?.data?.aggregations?.type_count?.doc_count || 0;
      const unmoderated_count = total_count - moderated_count - rejected_count;
      return {
        total_count,
        moderated_count,
        unmoderated_count,
        rejected_count,
      };
    });
};

export const useGoogleFileUpload = (modelId: string) => {
  return useMutation<any, ErrorEvent, { folderId: string; folderName: string }>(
    ({ folderId, folderName }) => {
      return fetch(
        `/api/v2/externalStorages/model/${modelId}/storages/gdrive/processInferences`,
        {
          method: "POST",
          body: JSON.stringify({
            folder_id: folderId,
            folder_name: folderName,
          }),
        }
      )
        .then((res) => {
          if (!res.ok) throw new Error("Failed to process");
          return res.json();
        })
        .then(() => {
          toast.success("Uploading files in the background");
          queryClient.invalidateQueries(["gdrivefolders", modelId]);
        });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useGdriveFolders = (modelId: string) => {
  return useQuery(["gdrivefolders", modelId], () =>
    fetch(
      `/api/v2/externalStorages/model/${modelId}/storages/gdrive/FolderDetails`
    ).then((res) =>
      res.ok
        ? res.json()
        : Promise.reject(
            new Error("Failed to fetch google drive folder details")
          )
    )
  );
};

export function useGoogleAuthorization() {
  return useMutation<any, ErrorEvent>(
    () => {
      const url = window.location.href;
      return fetch("/api/v2/externalStorages/user/storages/gdrive/authorize", {
        method: "POST",
        body: JSON.stringify({
          redirect_url: url,
        }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to authorize");
          return res.json();
        })
        .then((data) => {
          window.localStorage.setItem("openGDrive", "yes");
          window.location.href = data.redirectUrl;
        });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
}

export function useGoogleAccountRemove() {
  return useMutation<any, ErrorEvent>(
    () => {
      return fetch("/user/disconnectgdrive", {
        method: "POST",
      })
        .then((res) => {
          if (!res.ok) throw new Error("Failed to authorize");
          return res.json();
        })
        .then(() => {
          toast.success("Google account disconnected");
          queryClient.invalidateQueries(["user"]);
        });
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
}

export const useEmailSettings = () => {
  return useQuery<EmailSettings, ErrorEvent>({
    queryKey: "emailSetting",
    queryFn: () => {
      return fetch("/user/emailSetting").then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch email settings"))
      );
    },
  });
};

export const useSaveEmailSettings = () => {
  const { data: emailSettings } = useEmailSettings();

  return useMutation<any, ErrorEvent, EmailSettings>({
    mutationFn: (payload) => {
      return fetch("/user/emailSetting", {
        method: emailSettings ? "PATCH" : "POST",
        body: JSON.stringify(payload),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to save notification settings");
        }

        return data;
      });
    },
    onSuccess: () => {
      toast.success("Notification settings saved");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });
};
