const operationTypes = {
  postprocessing: "postprocessing",
  formatting: "formatting",
  validation: "validation",
  db_list_setup: "db_list_setup",
};

const paramTypes = {
  TEXT: "text", // single text input
  INT: "int", // number
  FLOAT: "float64", //float
  LIST_TEXT: "list-text", // array of text
  SINGLE_SELECT: "select", // dropdown
  SINGLE_SELECT_RADIO: "select-radio", // radio button list
  MULTI_SELECT: "check", // checkbox
  MULTI_SELECT_LIST: "list-select", // dropdown list
  SINGLE_SELECT_LABEL: "label", // select single from dropdown list of labels
  SINGLE_SELECT_LABEL_WITH_DERIVED_FIELDS: "label-with-derived-fields", // select single from dropdown list of labels & derived fields
  MULTI_SELECT_LABEL: "multi-label", // select multiple from dropdown list of labels
  SINGLE_SELECT_API_RESULTS: "select-api-results", // select single from api results
  GET_DBS: "get-dbs",
  GET_TABLES: "get-tables",
  GET_COLUMNS: "get-columns",
  ADDITIONAL_COLUMNS: "additional-columns",
  REGEX_GROUPS: "regex-groups",
  PYTHON_SCRIPT: "python-script",
  DB_LIST_SETUP: "db_list_setup",
};

const tableHeaderTypes = [
  {
    name: "Default",
    value: "default",
    type: "",
  },
  {
    name: "Select from dropdown",
    value: "select-from-db-list",
    type: "",
  },
];

const fieldTypes = [
  {
    name: "None",
    value: "none",
    type: "",
  },
  {
    name: "Date",
    value: "date",
    type: "default",
    settings: [
      { type: operationTypes.postprocessing, rules: [] },
      {
        type: operationTypes.formatting,
        rules: [{ type: "date_iso", params: [] }],
      },
      { type: operationTypes.validation, rules: [{ type: "valid_date" }] },
    ],
  },
  // {
  //   name: "Text",
  //   value: "text",
  //   type: "default",
  //   settings: [ { type: operationTypes.postprocessing, rules: [ { type: "remove", params: [{name: "Numbers", value: ""},{name: "Alphabets", value: ""}, {name: "Special", value: "checked"}]}]}, { type: operationTypes.formatting, rules: [ { type: "no_formatting", params: []}]}]
  // },
  {
    name: "Numbers",
    value: "number",
    type: "default",
    settings: [
      {
        type: operationTypes.postprocessing,
        rules: [
          {
            type: "remove",
            params: [
              { name: "Numbers", value: "" },
              { name: "Alphabets", value: "checked" },
              { name: "Special", value: "" },
            ],
          },
        ],
      },
      {
        type: operationTypes.formatting,
        rules: [{ type: "no_formatting", params: [] }],
      },
      { type: operationTypes.validation, rules: [] },
    ],
  },
  {
    name: "Custom",
    value: "custom",
    type: "custom",
  },
  {
    name: "Select from dropdown",
    value: "select-from-db-list",
    type: "",
  },
];

const formattingRules = [
  {
    type: fieldTypes[1].value,
    options: [
      {
        name: "",
        value: "",
      },
      {
        name: "No Formatting",
        value: "no_formatting",
      },
      {
        name: "US (mm/dd/yyyy)",
        value: "date_us",
      },
      {
        name: "Europe (dd/mm/yyyy)",
        value: "date_eu",
      },
      {
        name: "ISO (yyyy-mm-dd)",
        value: "date_iso",
      },
    ],
  },
  // {
  //   type: fieldTypes[2].value,
  //   options: [
  //     {
  //       name: "",
  //       value: "",
  //     },
  //     {
  //       name: "No Formatting",
  //       value: "no_formatting",
  //     },
  //     {
  //       name: "Upper Case",
  //       value: "upper",
  //     },
  //     {
  //       name: "Lower Case",
  //       value: "lower",
  //     },
  //   ],
  // },
  {
    type: fieldTypes[2].value,
    options: [
      {
        name: "",
        value: "",
      },
      {
        name: "No Formatting",
        value: "no_formatting",
      },
      {
        name: "Integer",
        value: "integer",
      },
      {
        name: "Float",
        value: "float",
      },
    ],
  },
];

const defaultServerConfig = {
  field_type: "",
  label_name: "",
  label_type: "",
  settings: [
    {
      type: operationTypes.postprocessing,
      rules: [
        {
          type: "",
          params: [],
        },
      ],
    },
    {
      type: operationTypes.formatting,
      rules: [
        {
          type: "",
          params: [],
        },
      ],
    },
    {
      type: operationTypes.validation,
      rules: [
        {
          type: "",
          params: [],
        },
      ],
    },
    {
      type: operationTypes.db_list_setup,
      rules: [
        {
          type: "",
          params: [],
        },
      ],
    },
  ],
};

export {
  fieldTypes,
  tableHeaderTypes,
  operationTypes,
  defaultServerConfig,
  paramTypes,
  formattingRules,
};
