import { MODEL_STATE } from "Utils/route";
import logger from "./logger";
import { uniqBy } from "lodash";
import MODELS from "assets/models.json";
import { toast } from "./toast";

export const errors = {
  no_labels: "Model has no labels",
  less_header_anno: "Draw atleast 10 examples for each table header",
  less_field_anno: "Draw atleast 10 examples for each label",
  labels_changed:
    "Labels have changed since last training, cannot retrain with test data",
  less_annotations: "Not enough annotations to train model",
  less_distinct_annotations: "Not enough distinct annotations to train model",
  less_annotated_images:
    "Less number of images required than needed for training",
  training_limit_exceeded: "You have exceeded your quota of free retrainings",
};

export async function updateModel(payload, url) {
  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      credentials: "same-origin",
    });
    const data = await response.json();

    if (response.status === 403) {
      throw new Error(data?.errors?.[0]?.message);
    }

    if (!response.ok) {
      throw new Error("failure");
    }
    return data;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while updating model");
    throw error;
  }
}

export async function updateModelById({ id, type, payload }) {
  let url;
  switch (type) {
    case "classification":
      url = `/api/v2/ImageCategorization/Model/?modelId=${id}`;
      break;
    case "multilabelclassification":
      url = `/api/v2/MultiLabelClassification/Model/${id}/`;
      break;
    case "similarity":
      url = `/api/v2/Similarity/Model/${id}/`;
      break;
    default:
      url = `/api/v2/ObjectDetection/Model/${id}/`;
  }
  try {
    const res = await updateModel(payload, url);
    return res;
  } catch (error) {
    logger.captureException(error);
    throw error;
  }
}

export function saveModeration(payload, modelId, errorCallback, resCallback) {
  fetch(`/api/v2/Inferences/Model/${modelId}/ImageLevelInference`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "same-origin",
  })
    .then((res) => {
      if (res.ok && resCallback) {
        res.json().then(resCallback);
      }
    })
    .catch((err) => {
      if (errorCallback) {
        errorCallback();
      }
    });
}

export async function saveReview(payload, modelId) {
  try {
    await fetch(`/api/v2/Inferences/Model/${modelId}/Update`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      credentials: "same-origin",
    });
  } catch (error) {
    logger.captureException(error);
    console.log("Error while saving review");
    throw error;
  }
}

export async function fetchPredictions(
  modelId,
  startDayOfInterval,
  currentBatchDay,
  currentBatchHour,
  labels,
  scoreLowerLimit,
  scoreUpperLimit
) {
  try {
    let url = `/api/v2/Inferences/Model/${modelId}/?start_day_interval=${startDayOfInterval}&current_batch_day=${currentBatchDay}&current_batch_hour=${currentBatchHour}&scoreLowerLimit=${scoreLowerLimit}&scoreUpperLimit=${scoreUpperLimit}`;
    for (var index in labels) {
      if (index === labels.length - 1) {
        url += `labels[]=${labels[index]}`;
      } else {
        url += `labels[]=${labels[index]}&`;
      }
    }
    const response = await fetch(url, "GET", {
      credentials: "include",
      method: "GET",
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while fetching predictions");
    throw error;
  }
}

export async function requestAnnotations(message, modelId) {
  try {
    await fetch("/user/request-annotations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ message, model_id: modelId }),
      credentials: "same-origin",
    });
  } catch (error) {
    logger.captureException(error);
    console.log("Error while sending slack message");
    throw error;
  }
}

export async function addAPIKey() {
  try {
    const response = await fetch("/user/NewApiKey/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      credentials: "same-origin",
    });
    const data = await response.json();

    if (response.status === 403) {
      throw new Error(data?.errors?.[0]?.message || data?.message);
    }

    if (!response.ok) {
      throw new Error("Error while adding API Key");
    }

    return data;
  } catch (error) {
    logger.captureException(error);
    toast.error(error.message);
  }
}

export async function deleteAPIKey(apiKey) {
  try {
    const response = await fetch("/user/DeleteApiKey/?apiKey=" + apiKey, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
      credentials: "same-origin",
    });
    const data = await response.json();

    if (response.status === 403) {
      throw new Error(data?.errors?.[0]?.message || data?.message);
    }

    if (!response.ok) {
      throw new Error("Error while deleting API Key");
    }

    return data;
  } catch (error) {
    logger.captureException(error);
    toast.error(error.message);
  }
}

export function constructURLEncodedFormData(data) {
  let formBody = [];
  for (var property in data) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(data[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

export async function buildAndUploadImage(modelId, isCPU) {
  try {
    const tag = isCPU ? "cpu" : "gpu";
    const response = await fetch(
      `http://brahma.nanonets.com:8001/objectdetection/build`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "omit",
        body: JSON.stringify({ tag, modelId }),
      }
    );
    const resp = await response.json();
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error triggering docker image upload");
    throw error;
  }
}

export function isValidImageURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
}

export function getPendingTime(modelId, modelType) {
  let url = "/api/v2/ObjectDetection/Model/" + modelId + "/PendingTime/";
  if (modelType === modelTypes.ic) {
    url = `/api/v2/ImageCategorization/PendingTime/?modelId=${modelId}`;
  }
  return fetch(url, {
    credentials: "include",
    method: "GET",
  });
}

export function getExperimentSteps(
  modelId,
  requestType,
  imagePaginationMarker,
  modelType
) {
  let url = `/api/v2/ObjectDetection/Model/${modelId}/ExperimentSteps/${
    requestType ? `?requestType=${requestType}` : ""
  }`;
  if (imagePaginationMarker) {
    if (requestType) {
      url += `&imagePaginationMarker=${imagePaginationMarker}`;
    } else {
      url += `?imagePaginationMarker=${imagePaginationMarker}`;
    }
  }
  if (modelType === modelTypes.ic) {
    url = `/api/v2/ImageCategorization/ExperimentSteps/?modelId=${modelId}`;
  }
  return fetch(url, {
    credentials: "include",
    method: "GET",
  });
}

export async function getAnnotations(modelId) {
  try {
    let url = `/api/v2/ObjectDetection/Model/${modelId}/Annotations/`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });
    const resp = await response.json();
    if (resp.code && resp.code !== 200) {
      return {};
    }
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while fetching pending time of model");
    throw error;
  }
}

export async function getBrahmaBuildStatus(modelId, tag) {
  try {
    let url = `/api/v2/buildstatus?modelId=${modelId}&tag=${tag}`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });
    const resp = await response.json();
    if (resp.code && resp.code !== 200) {
      return {};
    }
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while fetching pending time of model");
    return {};
    // throw error;
  }
}

export function secondsToTimeString(seconds) {
  var numYears = Math.floor(seconds / 31536000);
  var numDays = Math.floor((seconds % 31536000) / 86400);
  var numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  var numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  var numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;

  var timeString = "";
  if (numYears > 0) {
    timeString += `${numYears} ${numYears === 1 ? "year " : "years "}`;
  }
  if (numDays > 0) {
    timeString += `${numDays} ${numDays === 1 ? "day " : "days "}`;
  }
  if (numHours > 0) {
    timeString += `${numHours} ${numHours === 1 ? "hour " : "hours "}`;
  }
  if (numMinutes > 0) {
    timeString += `${numMinutes} ${numMinutes === 1 ? "minute " : "minutes "}`;
  }
  if (numSeconds > 0) {
    numSeconds = Number(numSeconds.toFixed(0));
    timeString += `${numSeconds} ${numSeconds === 1 ? "second " : "seconds "}`;
  }

  if (!timeString) {
    timeString = "0 seconds";
  }
  return timeString.trim();
}

export function transformAnnotations(fabrics, containerWidth) {
  for (var fabricId in fabrics) {
    const size = fabrics[fabricId].size;
    let scaleRatio = 1;
    if (containerWidth > 0) {
      scaleRatio = containerWidth / size.width;
    }

    for (var objectId in fabrics[fabricId].object) {
      fabrics[fabricId].object[objectId].width *= scaleRatio;
      fabrics[fabricId].object[objectId].height *= scaleRatio;
      fabrics[fabricId].object[objectId].top *= scaleRatio;
      fabrics[fabricId].object[objectId].left *= scaleRatio;
    }
  }
  return fabrics;
}

export async function getMLCAnnotations(modelId) {
  try {
    let url = `/api/v2/MultiLabelClassification/Model/${modelId}/Annotations/`;
    const response = await fetch(url, {
      credentials: "include",
      method: "GET",
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while fetching annotations of model");
    throw error;
  }
}

export async function upsertAnnotation(modelId, annotationId, categories) {
  try {
    let url = `/api/v2/MultiLabelClassification/Model/${modelId}/Annotations/${annotationId}/`;
    const response = await fetch(url, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ categories }),
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while updating annotation");
    throw error;
  }
}

export function fileNameToS3(name) {
  let default_s3_host = "https://s3-us-west-2.amazonaws.com/nanonets/";
  if (process.env.REACT_APP_REGION === "eu") {
    default_s3_host = "https://cdneuimages.nanonets.com/";
  } else if (process.env.REACT_APP_REGION === "us") {
    default_s3_host = "https://cdnusimages.nanonets.com/";
  }
  return (
    "https://" +
    (default_s3_host + name)
      .replace(/^https:\/\//, "")
      .replace(/\/+/g, "/")
      .replace(/\/+$/, "")
  );
}

export function IsAppCustomHosted() {
  return (
    process.env.REACT_APP_REGION === "eu" ||
    process.env.REACT_APP_REGION === "us"
  );
}

export function imgixUrl(url) {
  if (!url) return "";
  let image_host_to_replace = "https://nanonets.imgix.net/$1"; // default
  if (process.env.REACT_APP_REGION === "eu") {
    image_host_to_replace = "https://cdneuimages.nanonets.com/$1";
  } else if (process.env.REACT_APP_REGION === "us") {
    image_host_to_replace = "https://cdnusimages.nanonets.com/$1";
  }
  return url.includes("blob:http")
    ? url
    : url.replace(/.*(uploadedfiles)/, image_host_to_replace);
}

export function compareExperimentSteps(stepA, stepB) {
  if (stepA.step_id < stepB.step_id) return -1;
  if (stepA.step_id > stepB.step_id) return 1;
  return 0;
}

export function getConfirmScreenMessage(modelState) {
  let message = "";
  switch (modelState) {
    case MODEL_STATE.TRAINED:
      message =
        "Your model has finished training! You can see the performance below or click to start testing!";
      break;
    case MODEL_STATE.RETRAINING_IN_PROGRESS:
      message =
        "Retraining in progress! You can see the model's performance below.";
      break;
    default:
      message =
        "Your model is currently training! We'll send you an email when it's finished training.";
  }
  return message;
}

export function getOrSetExperimentDetailsFromLocalStorage(experimentName) {
  let expDetails = window.localStorage.getItem(experimentName);

  if (!expDetails) {
    switch (experimentName) {
      case "exp_show_pretrained":
        expDetails = Math.random() > 0.5 ? "show" : "hide";
        window.localStorage.setItem(experimentName, expDetails);
        break;
      default:
        break;
    }
  }
  return expDetails;
}

export function getTimeInt(uuid_str) {
  if (!uuid_str) return 0;
  const uuid_arr = uuid_str.split("-");
  const time_str = [uuid_arr[2].substring(1), uuid_arr[1], uuid_arr[0]].join(
    ""
  );
  return Math.floor((parseInt(time_str, 16) - 122192928000000000) / 10000);
}

export async function createSimilarityModel() {
  try {
    const response = await fetch("/api/v2/Similarity/Model/", {
      method: "POST",
      credentials: "include",
    });
    const resp = await response.json();
    return resp;
  } catch (error) {
    logger.captureException(error);
    console.log("Error while creating similarity model");
    throw error;
  }
}

export function ordinalSuffixOf(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function containsNonAsciiCharacters(s) {
  const ascii = /^[ -~]+$/;

  if (!ascii.test(s)) {
    return true;
  }
  return false;
}

export function decodeNonAscii(s1) {
  s1 = unescape(encodeURIComponent(s1));
  let s2 = "";
  for (let i = 0; i < s1.length; i++) {
    if (containsNonAsciiCharacters(s1.charAt(i))) {
      s2 += "\\x" + s1.charCodeAt(i).toString(16);
    } else {
      s2 += s1.charAt(i);
    }
  }
  return s2;
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const modelTypes = {
  custom: "custom",
  ocr: "ocr",
  od: "od",
  ic: "ic",
  mlc: "mlc",
  similarity: "similarity",
};

export const getModelType = (model) => {
  const mapping = {
    custom: modelTypes.custom,
    ocr: modelTypes.ocr,
    localization: modelTypes.od,
    classification: modelTypes.ic,
    multilabelclassification: modelTypes.mlc,
    similarity: modelTypes.similarity,
  };

  return mapping[model?.type];
};

export const validationErrorsCount = (errorsByPage) => {
  let errorsCount = 0;

  Object.values(errorsByPage?.pages || {}).forEach((errors) => {
    errors.forEach((error) => {
      if (error?.cell_errors?.length > 0) {
        error.cell_errors = uniqBy(
          error.cell_errors,
          (v) => v.row + "-" + v.col
        );

        errorsCount += error.cell_errors.length;
      } else {
        errorsCount += 1;
      }
    });
  });

  return errorsCount;
};

export const validationErrorMap = {
  content_length: "Validation failed due to content length mismatch",
  numeric_check: "Validation failed due to numeric field mismatch",
  string_check: "Validation failed due to string field mismatch",
  valid_date: "Validation failed due to invalid date",
  match_database: "Validation failed due to missing db column value",
  match_in_field: "Validation failed due to matching failed",
  validate_future_date: "Validation failed due to date being in the future",
  score_threshold: "Validation failed due to score being less than threshold",
  python_script_validation: "Python script validation failed",
  field_present: "Field not found",
};

export const labelTypes = {
  field: "field",
  table_column: "table_column",
  select_from_db_list: "select-from-db-list",
};

export const labelSources = {
  select_from_db_list: "select-from-db-list",
  organic: "organic",
};

export const getTableHeaders = (model) => {
  return model?.metadata?.table_categories
    ?.find((category) => category.label === "")
    ?.headers?.filter((header) => header !== "");
};

export function modelCreatedTime(uuid_str) {
  if (!uuid_str) {
    throw new Error("uuid_str is not valid");
  }

  try {
    return getTimeInt(uuid_str);
  } catch (e) {
    //else return 7 days before
    return new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  }
}

export function isPretrainedModel(modelId) {
  return (
    MODELS.pretrainedModels.some((m) => m.modelId === modelId) ||
    MODELS.prebuiltWorkflowModels.some((m) => m.modelId === modelId)
  );
}
