import React from "react";
import { inject, observer } from "mobx-react";
import { CircularProgress, styled } from "@material-ui/core";
import { Error as ErrorIcon } from "@material-ui/icons";

const Wrapper = styled("div")({
  position: "absolute",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100vh",
  overflow: "auto",
  display: "flex",
  background: "rgba(255, 255, 255, 0.9)",
});

const Body = styled("div")({
  borderRadius: 8,
  padding: 20,
  textAlign: "center",
  margin: "auto",
  background: "#fff",
});

class AppModal extends React.Component {
  render() {
    const { status, fetchingAnnotations, fetchingConfirmScreenMetrics } =
      this.props.AppStore;

    return (
      (fetchingAnnotations ||
        this.props.UserStore.globalLoader ||
        fetchingConfirmScreenMetrics) && (
        <Wrapper>
          <Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {((this.props.UserStore.globalLoader && status === "") ||
                fetchingAnnotations ||
                status === "progress" ||
                fetchingConfirmScreenMetrics) && <CircularProgress />}
            </div>

            {(fetchingAnnotations || status === "progress") && (
              <>
                <p>Getting Model Details...</p>
              </>
            )}

            {status === "cloning-in-progress" && (
              <>
                <CircularProgress />
                <p>Cloning Model into your account.</p>
              </>
            )}

            {!status && fetchingConfirmScreenMetrics && (
              <>
                <p>Fetching model metrics...</p>
              </>
            )}

            {status === "error" && (
              <>
                <div style={{ fontSize: 40, color: "#B83330" }}>
                  <ErrorIcon fontSize={"inherit"} />
                </div>
                <p>An error occured while getting model.</p>
              </>
            )}
          </Body>
        </Wrapper>
      )
    );
  }
}

export default inject("AppStore", "UserStore")(observer(AppModal));
