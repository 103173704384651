import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { v1 } from "uuid";
import { labelTypes, labelSources } from "Utils";
import { deleteDBListField, saveDBListField } from "Utils/postprocessing";
import logger from "Utils/logger";
import { useModel } from "./model";
import queryClient from "queryClient";
import { onLabelChange } from "hooks/misc";
import { toast } from "Utils/toast";
import { getDatabaseDetails, useIntegrations } from "./integrations";
import { useIsAdmin, useUser } from "./user";
import {
  AddLabelParams,
  DeleteLabelParams,
  RenameLabelParams,
  CustomStep,
  UpdateDbListFieldsParams,
  UpdateLabelParams,
  UpdateLabelSettingsParams,
  Label,
  LabelType,
} from "types/label";
import { Model } from "types/Model";
import { addCsvDataToPostProcessingConfig } from "Utils/labels";
import {
  DependentSteps,
  PostprocessingConfig,
  PostprocessingConfig2,
  PostprocessingStep,
} from "types/workflow/postprocessing";
import { RulesConfig } from "types/workflow/rules";
import { find } from "lodash";
import { postProcessingRules } from "components/workflow/postProcessing/rules";
import posthog from "posthog-js";
import { queryKeys } from "./queryKeys";

export type PartialPostprocessingConfig = Partial<PostprocessingConfig>;

export function useFields(modelId: string) {
  const { data: model } = useModel(modelId);

  const fields: Label[] = useMemo(
    () =>
      model?.categories
        ?.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        ?.map(({ name, count, id }) => ({
          id,
          name,
          value: name,
          count,
          label_type: "field",
        })) || [],
    [model?.categories]
  );

  return fields;
}

export function useTableHeaders(modelId: string) {
  const { data: model } = useModel(modelId);

  const tableHeaders: Label[] = useMemo(() => {
    const obj = model?.metadata?.table_categories?.find(
      (category) => category.label === ""
    );

    return (
      obj?.headers
        ?.filter((header) => header !== "")
        ?.map((name) => ({
          id: obj.id_info?.[name],
          name,
          value: name,
          count: obj.headers_count[name],
          label_type: "table_column",
        })) || []
    );
  }, [model?.metadata?.table_categories]);

  return tableHeaders;
}

export function useDerivedLabels(modelId: string) {
  // Fetching derived fields from id_map
  const { derivedFields } = useFinalFields(modelId);
  return derivedFields;
  /*
  const { data: config } = usePostprocessingConfig(modelId);
  const allLabels = useAllLabels(modelId);

  return useMemo(() => {
    const derivedFields: string[] = [];
    const labelNames = allLabels.map((label) => label.name);

    config?.fields?.forEach((field) => {
      field.settings.forEach((setting) => {
        if (setting.type !== "postprocessing") return;

        setting.rules.forEach((rule) => {
          if (rule.type === "derived_field_database") {
            const param = rule.params.find(
              (param) => param.name === "Name for derived field"
            );

            if (param?.value) {
              // Param value should exist and it shouldn't be null or empty
              derivedFields.push(param.value);
            }
          }

          if (rule.type === "derived_field_regex_groups") {
            rule.params
              .filter((param) => param.name !== "regex")
              .forEach((param) => {
                if (!param.value || labelNames.includes(param.value)) return;

                derivedFields.push(param.value);
              });
          }
        });
      });
    });

    return uniq(derivedFields).sort();
  }, [allLabels, config?.fields]);
  */
}

export function useFinalFields(modelId: string, labelType?: LabelType) {
  const { data: config } = usePostprocessingConfig(modelId);

  return useMemo(() => {
    const finalOutputs = Object.entries(config?.id_map || {}).filter(
      ([output_id, output_info]) => {
        return (
          output_info.final_output &&
          (labelType ? output_info.label_type === labelType : true)
        );
      }
    );

    const originalFields = finalOutputs
      .filter(([output_id, output_info]) => {
        return output_info.original_label === output_id;
      })
      .map(([output_id, output_info]) => output_info.name);

    const derivedFields: string[] = [],
      dbListFields: string[] = [],
      currencyFields: string[] = [];

    finalOutputs
      .filter(([output_id, output_info]) => {
        return output_info.original_label !== output_id;
      })
      .forEach(([output_id, output_info]) => {
        if (
          config?.steps[output_info.source_step]?.setting?.type ===
          "db_list_setup"
        ) {
          dbListFields.push(output_info.name);
        } else if (
          config?.steps[output_info.source_step]?.setting?.rules[0]?.type ===
          "extract_currency"
        ) {
          currencyFields.push(output_info.name);
        } else {
          derivedFields.push(output_info.name);
        }
      });

    const byAlpha = (a: string, B: string) =>
      a.toLowerCase() < B.toLowerCase() ? -1 : 1;

    originalFields.sort(byAlpha);
    derivedFields.sort(byAlpha);
    dbListFields.sort(byAlpha);
    currencyFields.sort(byAlpha);

    return {
      originalFields,
      derivedFields,
      dbListFields,
      currencyFields,
      hasFinalOutputs: finalOutputs.length > 0,
      finalOutputs,
    };
  }, [config?.id_map, config?.steps, labelType]);
}

export const useFinalFieldNames = (modelId: string, labelType?: LabelType) => {
  const { finalOutputs } = useFinalFields(modelId, labelType);

  const finalFieldNames = useMemo(() => {
    return Array.from(new Set(finalOutputs.map(([, { name }]) => name)));
  }, [finalOutputs]);
  return finalFieldNames;
};

export const useFinalTableHeaderNames = (modelId: string) => {
  return useFinalFieldNames(modelId, "table_column");
};

export const useDBListFields = (modelId: string, isTableHeader: boolean) => {
  return useQuery<Label[]>(["dbListFields", modelId, isTableHeader], () =>
    fetch(
      `/api/v2/Model/${modelId}/labels/?type=${
        isTableHeader ? labelTypes.table_column : labelTypes.field
      }`
    )
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Failed to fetch db list fields"))
      )
      .then((data: string[]) => {
        const label_type: LabelType = isTableHeader ? "table_column" : "field";

        return data.map((label) => ({
          id: v1(),
          name: label,
          value: label,
          label_type,
          count: 0,
        }));
      })
  );
};

export const useDBListValues = (
  modelId: string,
  label: string,
  searchText: string,
  isTableHeader: boolean,
  active: boolean
) => {
  const model = useModel(modelId || "");
  const user = useUser();
  const userEmail = user?.data?.EmailId;
  const modelEmail = model.data?.email || userEmail;

  return useQuery<string[], ErrorEvent>({
    queryKey: [
      "dbListValues",
      modelId,
      label,
      searchText,
      isTableHeader,
      active,
    ],
    queryFn: () => {
      if (!active) return [];
      return fetch(
        `/api/v2/Model/${modelId}/labels/${label}/values?query=${searchText}&type=${
          isTableHeader ? labelTypes.table_column : labelTypes.field
        }&email=${encodeURIComponent(modelEmail || "")}&values_to_match=10` // no need to support dynamic values_to_match for now
      ).then((res) => {
        return res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching db list Values"));
      });
    },
  });
};

export const useCurrencyCodes = () => {
  return useQuery<string[], ErrorEvent>(["currencyCodes"], () => {
    return fetch(`/api/v2/miscroutes/currencyCodes`).then((res) => {
      return res.ok
        ? res.json()
        : Promise.reject(new Error("Failed to fetch currency codes"));
    });
  });
};

export const useLabelSettings = (
  modelId: string,
  labelName: string,
  isTableHeader: boolean
) => {
  return useQuery(
    ["labelSettings", modelId, labelName, isTableHeader],
    () => {
      fetch(
        `/api/v2/Model/${modelId}/labels/${labelName}/settings?type=${
          isTableHeader ? labelTypes.table_column : labelTypes.field
        }`
      ).then((res) => {
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching db list fields"));
      });
    },
    {
      enabled: !!labelName,
    }
  );
};

export const useUpdateLabelSettings = () => {
  return useMutation<any, ErrorEvent, UpdateLabelSettingsParams>(
    ({ labelSettings, modelId, labelName, isTableHeader }) => {
      const labelType = isTableHeader
        ? labelTypes.table_column
        : labelTypes.field;

      return fetch(
        `/api/v2/Model/${modelId}/labels/${labelName}/settings?type=${labelType}`,
        {
          method: "POST",
          body: JSON.stringify({ ...labelSettings }),
        }
      ).then((res) => {
        if (!res.ok)
          throw new Error("Error while updating labelSettings config");
        return res.json();
      });
    },
    {
      onMutate: ({ labelSettings, modelId, labelName, isTableHeader }) => {
        queryClient.setQueryData(
          ["labelSettings", modelId, labelName, isTableHeader],
          () => {
            return labelSettings;
          }
        );
      },
      onSuccess: () => {
        toast.success("Label settings updated");
      },
      onError: (error) => {
        toast.error(error.message);
      },
      onSettled: (data, error, { modelId, labelName, isTableHeader }) => {
        queryClient.invalidateQueries([
          "labelSettings",
          modelId,
          labelName,
          isTableHeader,
        ]);
      },
    }
  );
};

export function useAllLabels(modelId: string) {
  const labels = useFields(modelId);
  const tableHeaders = useTableHeaders(modelId);

  return useMemo(() => [...labels, ...tableHeaders], [labels, tableHeaders]);
}

export const useUpdateFields = () => {
  return useMutation<Model, ErrorEvent, UpdateLabelParams>(
    ({ modelId, labelNames }) => {
      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/`, {
        method: "PATCH",
        body: JSON.stringify({ categories: labelNames }),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while updating fields");
        }

        return data;
      });
    },
    {
      onMutate: ({ modelId, labelNames }) => {
        queryClient.setQueryData<Model | undefined>(
          ["model", modelId],
          (prevData) => {
            if (!prevData) return undefined;

            const newData = { ...prevData };
            newData.labels = labelNames;
            return newData;
          }
        );
      },
      onSuccess: (data, { modelId }) => {
        toast.success("Labels updated");

        queryClient.setQueryData<Model | undefined>(
          ["model", modelId],
          (prevData) => {
            if (!prevData) return undefined;

            const newData = { ...prevData };
            const newLabels = data.categories.map((v) => v.name);
            newData.categories = data.categories;
            newData.labels = newLabels;
            return newData;
          }
        );

        setTimeout(() => {
          onLabelChange(modelId);
        }, 500);
      },
      onError: (error, { modelId }) => {
        toast.error(error?.message ? error.message : "Failed to update fields");
        queryClient.invalidateQueries(["model", modelId]);
      },
    }
  );
};

export const useUpdateTableHeaders = () => {
  return useMutation<any, ErrorEvent, UpdateLabelParams>(
    ({ modelId, labelNames }) => {
      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/`, {
        method: "PATCH",
        body: JSON.stringify({
          table_categories: [
            {
              label: "",
              headers: labelNames,
            },
          ],
        }),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Error while updating table headers");
        }

        return data;
      });
    },
    {
      onMutate: ({ modelId, labelNames }) => {
        queryClient.setQueryData<Model | undefined>(
          ["model", modelId],
          (prevData) => {
            if (!prevData) return undefined;

            const newData = { ...prevData };
            newData.metadata.table_categories[0].headers = labelNames;
            return newData;
          }
        );
      },
      onSuccess: (data, { modelId }) => {
        toast.success("Table headers updated");

        queryClient.setQueryData<Model | undefined>(
          ["model", modelId],
          (prevData) => {
            if (!prevData) return undefined;

            const newData = { ...prevData };
            newData.metadata.table_categories = data.table_categories;
            return newData;
          }
        );

        setTimeout(() => {
          onLabelChange(modelId);
        }, 500);
      },
      onError: (error, { modelId }) => {
        toast.error(
          error?.message ? error.message : "Failed to update table headers"
        );
        queryClient.invalidateQueries(["model", modelId]);
      },
    }
  );
};

export const useAddLabel = (modelId: string) => {
  const isAdmin = useIsAdmin();
  const { data: model } = useModel(modelId);
  const fields = useFields(modelId);
  const tableHeaders = useTableHeaders(modelId);
  const updateFields = useUpdateFields();
  const updateTableHeaders = useUpdateTableHeaders();
  const user = useUser();

  return useMutation<any, ErrorEvent, AddLabelParams>(
    ({ labelName, isTableHeader = false }) => {
      const labels = isTableHeader ? tableHeaders : fields;
      const updateLabels = isTableHeader ? updateTableHeaders : updateFields;
      const labelNames = labels.map((label) => label.name);
      const _labelName = labelName.trim().replace(/\s|\//g, "_");
      const blockModelUpdate = [3, 4, 6].includes(model?.model_state || 0);
      let errorText = "";

      if (
        !isAdmin &&
        !model?.is_paid &&
        user?.data?.NumberOfFieldsAllowed &&
        labelNames.length >= user?.data?.NumberOfFieldsAllowed
      ) {
        errorText = `Can't add more than ${user?.data?.NumberOfFieldsAllowed} fields on Free plan.`;
      } else if (blockModelUpdate) {
        errorText = `Can't add field while model is training`;
      } else if (_labelName.length === 0) {
        errorText = `Can't add empty labels`;
      } else if (labelNames.includes(_labelName)) {
        errorText = `Label '${_labelName}' already exists`;
      }

      if (errorText) {
        toast.error(errorText);
        throw new Error(errorText);
      }

      return updateLabels.mutateAsync({
        modelId,
        labelNames: [...labelNames, _labelName],
      });
    }
  );
};

export const useDeleteLabel = (modelId: string) => {
  const fields = useFields(modelId);
  const tableHeaders = useTableHeaders(modelId);
  const updateFields = useUpdateFields();
  const updateTableHeaders = useUpdateTableHeaders();

  return useMutation<
    any,
    {
      dependent_workflow_steps: string[];
    },
    DeleteLabelParams
  >(({ labelName, isTableHeader = false }) => {
    const labels = isTableHeader ? tableHeaders : fields;
    const updateLabels = isTableHeader ? updateTableHeaders : updateFields;
    const labelNames = labels.map((label) => label.name);
    const index = labelNames.indexOf(labelName);

    if (index !== -1) {
      labelNames.splice(index, 1);
    }

    return updateLabels.mutateAsync({ modelId, labelNames });
  });
};

export const useUpdateDbListFields = () => {
  return useMutation<any, ErrorEvent, UpdateDbListFieldsParams>(
    ({ modelId, labelName, action, isTableHeader }) => {
      const labelType = isTableHeader
        ? labelTypes.table_column
        : labelTypes.field;
      if (action === "add") {
        return saveDBListField(modelId, labelName, labelType).catch(
          logger.captureException
        );
      } else {
        return deleteDBListField(modelId, labelName, labelType).catch(
          logger.captureException
        );
      }
    },
    {
      onSuccess: (data, { modelId, isTableHeader }) => {
        queryClient.invalidateQueries(["dbListFields", modelId, isTableHeader]);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useRenameLabel = () => {
  return useMutation<any, ErrorEvent, RenameLabelParams>(
    ({
      modelId,
      oldLabelName,
      newLabelName,
      isTableHeader = false,
      labelSource,
    }) => {
      return fetch(`/api/v2/miscroutes/renamelabel?modelId=${modelId}`, {
        method: "post",
        body: JSON.stringify({
          old_label: oldLabelName,
          new_label: newLabelName,
          type: isTableHeader ? labelTypes.table_column : labelTypes.field,
          source: labelSource,
        }),
      }).then(async (res) => {
        let data;
        try {
          data = await res.json();
        } catch (error) {}

        if (!res.ok) {
          throw new Error(
            data?.errors?.[0]?.reason || "Failed to rename label"
          );
        }
        return data;
      });
    },
    {
      onSuccess: (data, { modelId, isTableHeader, labelSource }) => {
        toast.success("Label renamed");
        onLabelChange(modelId);
        if (labelSource === labelSources.select_from_db_list) {
          queryClient.invalidateQueries([
            "dbListFields",
            modelId,
            isTableHeader,
          ]);
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

export const useRulesConfig = () => {
  return useQuery<RulesConfig>(["rulesConfig"], () =>
    fetch("/api/v2/OCR/labelsConfig")
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching labels config"))
      )
      .then((labelsConfig: RulesConfig) => {
        return {
          ...labelsConfig,
          gl_category: {
            ...labelsConfig.db_list_auto_assign,
            name: "General Ledger Categories",
            showAgainstAllLineItems: true,
            showUnderInvoiceDetails: false,
            autoAssign: true,
          },
        };
      })
  );
};

export const useLabelValidations = (modelId: string) => {
  return useQuery(
    ["labelValidations", modelId],
    () => {
      return fetch(
        `/api/v2/ObjectDetection/Model/${modelId}/GetLabelValidations/`
      ).then((res) =>
        res.ok
          ? res.json()
          : Promise.reject(new Error("Error while fetching label validations"))
      );
    },
    {
      enabled: !!modelId,
    }
  );
};

export const usePostprocessingConfig = (modelId: string) => {
  return useQuery<PostprocessingConfig>(
    ["postprocessingConfig", modelId],
    () => {
      return fetch(`/api/v2/ObjectDetection/Model/${modelId}/Steps`).then(
        (res) => {
          if (!res.ok) throw new Error("Failed to fetch post-processing steps");
          return res.json();
        }
      );
    },
    { enabled: !!modelId }
  );
};

export const usePostprocessingConfig2 = (modelId: string) => {
  const postprocessingConfig = usePostprocessingConfig(modelId);
  const integrations = useIntegrations();

  const data: PostprocessingConfig2 | undefined = useMemo(() => {
    if (!integrations.data || !postprocessingConfig.data) return;

    const data: PostprocessingConfig2 = {
      ...postprocessingConfig.data,
      steps: {},
    };

    const getCustomStep = (step: PostprocessingStep) => {
      const rule = step.setting?.rules[0];

      let integrationId = "";

      rule.params.forEach((param) => {
        if (param.type === "get-dbs") {
          integrationId = param.value || "";
        }
      });

      const { icon: dbIcon, type: dbType } = getDatabaseDetails(
        integrations.data,
        integrationId
      );

      const options: any = {
        type: rule.type,
      };

      if (rule.type === "derived_field_database" && dbType) {
        options.dbType = dbType;
      }

      const customizationType = step?.customizationType;
      if (rule.type === "db_list_auto_assign" && customizationType) {
        options.customizationType = customizationType;
      }

      const postProcessingRule = find(postProcessingRules, options);

      const customStep: CustomStep = {
        ...step,
        name: postProcessingRule?.name || "",
        icon:
          (rule.type === "derived_field_database"
            ? dbIcon
            : postProcessingRule?.icon) || "",
        dbIcon,
        dbType,
        customizationType:
          typeof customizationType === "string" ? customizationType : "",
      };

      return customStep;
    };

    for (const step_id in postprocessingConfig.data.steps) {
      const step = postprocessingConfig.data.steps[step_id];
      data.steps[step_id] = getCustomStep(step);
    }

    return data;
  }, [integrations.data, postprocessingConfig.data]);

  return { ...postprocessingConfig, data };
};

export const usePostprocessingSteps = (modelId: string) => {
  const postprocessingConfig = usePostprocessingConfig2(modelId);

  const data = useMemo(() => {
    if (!postprocessingConfig.data) return;

    const steps = postprocessingConfig.data?.steps;
    const data: CustomStep[] = [];

    postprocessingConfig.data?.steps_order?.forEach((stepId) => {
      const step = steps[stepId];
      if (step.setting.type === "validation") return;
      data.push(step);
    });

    return data;
  }, [postprocessingConfig.data]);

  return { ...postprocessingConfig, data };
};

interface UpdatePostprocessingStepParams {
  modelId: string;
  config: PostprocessingStep;
  onSuccess?: () => void; // todo: remove it later
}

export const useUpdatePostprocessingStep = () => {
  const { data: integrations = [] } = useIntegrations();

  return useMutation<any, ErrorEvent, UpdatePostprocessingStepParams>(
    ({ modelId, config }) => {
      const step = addCsvDataToPostProcessingConfig(config, integrations);
      const payload: Partial<PostprocessingStep> = { ...step };

      if (payload.step_id?.startsWith("fake-")) {
        delete payload.step_id;
      }

      const url = `/api/v2/ObjectDetection/Model/${modelId}/Step`;
      return fetch(url, {
        method: !payload.step_id ? "PUT" : "PATCH",
        body: JSON.stringify(payload),
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw new Error("Failed to update post-processing steps");
        }

        posthog.capture("Workflow Save Action", {
          rule_type: config.setting.rules[0].type,
          model_id: modelId,
        });
        return data;
      });
    },
    {
      onSuccess: (data, { modelId, onSuccess }) => {
        toast.success("Postprocessing step updated");
        onSuccess && onSuccess();

        queryClient.setQueryData<PostprocessingConfig>(
          ["postprocessingConfig", modelId],
          data
        );
        queryClient.invalidateQueries(["finalResultsConfig", modelId]);
        queryClient.invalidateQueries(["exportinfo", modelId]);
        queryClient.invalidateQueries(queryKeys.modelChangelog.model(modelId));
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
};

interface DeletePostprocessingStepParams {
  modelId: string;
  stepId: string;
}

export const useDeletePostprocessingStep = () => {
  return useMutation<
    any,
    {
      dependent_steps: DependentSteps;
    },
    DeletePostprocessingStepParams
  >(
    ({ modelId, stepId }) => {
      const url = `/api/v2/ObjectDetection/Model/${modelId}/Step/${stepId}`;
      return fetch(url, {
        method: "DELETE",
      }).then(async (res) => {
        const data = await res.json();

        if (res.status === 403) {
          throw new Error(data?.errors?.[0]?.message);
        }

        if (!res.ok) {
          throw data;
        }

        return data;
      });
    },
    {
      onSuccess: (data, { modelId }) => {
        toast.success("Post-processing step deleted");

        queryClient.setQueryData<PostprocessingConfig>(
          ["postprocessingConfig", modelId],
          data
        );
        queryClient.invalidateQueries(["finalResultsConfig", modelId]);
        queryClient.invalidateQueries(queryKeys.modelChangelog.model(modelId));
      },
      onError: (error) => {
        toast.error("Error while deleting the step");
      },
    }
  );
};
