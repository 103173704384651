import {
  AccountBalance,
  Apps,
  DateRange,
  DescriptionOutlined,
  FindReplace,
  LooksOne,
  MoneyOff,
  SvgIconComponent,
} from "@material-ui/icons";
import {
  changeCaseIcon,
  closestMatchIcon,
  autoAwesomeBlueIcon,
  joinInnerIcon,
  convertToAsciiIcon,
  csv2Icon,
  dropdownFieldIcon,
  floatIcon,
  integerIcon,
  manageSearchIcon,
  mariaDbIcon,
  mssqlIcon,
  mysqlIcon,
  postgresqlIcon,
  pythonIcon,
  regexIcon,
  playlistRemoveIcon,
  usdCurrencyIcon,
} from "imageUrls";
import { IntegrationType } from "types/integration";
import { PostprocessingRuleType } from "types/workflow/rules";

export type RuleCategoryName =
  | "All steps"
  | "Formatting"
  | "Lookup"
  | "Python"
  | "Accounting";

interface RuleCategory {
  name: RuleCategoryName;
  icon: string | SvgIconComponent;
  description: string;
}

export interface PostprocessingRule {
  category: RuleCategory;
  subCategory?: string;
  type: PostprocessingRuleType;
  name: string;
  description: string;
  icon: string | SvgIconComponent;
  dbType?: IntegrationType;
  customizationType?: string;
}

export const ruleCategories: Record<string, RuleCategory> = {
  all: {
    name: "All steps",
    description: "",
    icon: Apps,
  },
  formatting: {
    name: "Formatting",
    description: "Modify the auto-captured data with actions",
    icon: autoAwesomeBlueIcon,
  },
  lookup: {
    name: "Lookup",
    description: "Get a new field from an external source",
    icon: manageSearchIcon,
  },
  accounting: {
    name: "Accounting",
    description: "Accounting",
    icon: AccountBalance,
  },
  python: {
    name: "Python",
    description: "Write your own step",
    icon: pythonIcon,
  },
};

export const postProcessingRules: PostprocessingRule[] = [
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "parse_date",
    name: "Convert to Date format",
    description: "Change text to a selected date format",
    icon: DateRange,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "db_list_auto_assign",
    name: "Dropdown field",
    description: "Add GL accounts, Vendor names or any custom values",
    icon: dropdownFieldIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "extract_currency",
    name: "Currency detector",
    description: "Extracts currency field as a 3 character code (eg: USD)",
    icon: usdCurrencyIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "remove",
    name: "Remove characters",
    description: "Remove alphabets, numbers or special characters",
    icon: playlistRemoveIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "find_replace",
    name: "Find and Replace",
    description: "Replace instances of any character or phrase",
    icon: FindReplace,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "filter_best",
    name: "Keep only one instance",
    description: "Keep only one of multiple detected results",
    icon: LooksOne,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "concatenate",
    name: "Combine instances",
    description: "Concatenate separated values of data into a single string",
    icon: joinInnerIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "closest_match",
    name: "Change to closest match",
    description:
      "Change text to a value that is a close match of captured data",
    icon: closestMatchIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "General actions",
    type: "change_case",
    name: "Change case",
    description: "Change text to uppercase/lowercase or all caps",
    icon: changeCaseIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Numeric actions",
    type: "integer",
    name: "Convert to integer",
    description: "Remove decimal places from numbers",
    icon: integerIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Numeric actions",
    type: "float",
    name: "Convert to float",
    description: "Add decimal places to numbers",
    icon: floatIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Numeric actions",
    type: "currency_to_number",
    name: "Remove Currency symbols",
    description: "Keep only numbers, decimals and commas in amounts",
    icon: MoneyOff,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Numeric actions",
    type: "convert_to_ascii",
    name: "Convert to ASCII",
    description:
      "Convert a string into American Standard Code for Information Interchange encoding",
    icon: convertToAsciiIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Regex actions",
    type: "match_regex",
    name: "Match Regex",
    description: "Extract the substring matching the regular expression",
    icon: regexIcon,
  },
  {
    category: ruleCategories.formatting,
    subCategory: "Regex actions",
    type: "derived_field_regex_groups",
    name: "Create a new field with Regex",
    description:
      "Derive fields from captured regex groups and assign to variables",
    icon: regexIcon,
  },
  {
    category: ruleCategories.lookup,
    type: "derived_field_database",
    name: "Find in PostgreSQL",
    description: "Find and get additional data from your database",
    icon: postgresqlIcon,
    dbType: "postgresql",
  },
  {
    category: ruleCategories.lookup,
    type: "derived_field_database",
    name: "Find in CSV",
    description: "Find and get additional data from your .csv file",
    icon: csv2Icon,
    dbType: "csv",
  },
  {
    category: ruleCategories.lookup,
    type: "derived_field_database",
    name: "Find in MySQL",
    description: "Find and get additional data from your database",
    icon: mysqlIcon,
    dbType: "sql",
  },
  {
    category: ruleCategories.lookup,
    type: "derived_field_database",
    name: "Find in MSSQL",
    description: "Find and get additional data from your database",
    icon: mssqlIcon,
    dbType: "mssql",
  },
  {
    category: ruleCategories.lookup,
    type: "derived_field_database",
    name: "Find in MariaDB",
    description: "Find and get additional data from your database",
    icon: mariaDbIcon,
    dbType: "mariadb",
  },
  {
    category: ruleCategories.accounting,
    subCategory: "Accounting",
    type: "db_list_auto_assign",
    name: "General Ledger Categories",
    description:
      "Add your own GL accounts, automate categorization based on any captured field",
    icon: DescriptionOutlined,
    customizationType: "gl_category",
  },
  {
    category: ruleCategories.python,
    type: "python_script_postprocessing",
    name: "Python code",
    description: "Create a custom step with our Python code block.",
    icon: pythonIcon,
  },
];
