import * as React from "react";
import { matchPath, NavLink, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { ModelRoute, stagesList } from "Utils/route";
import {
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@material-ui/icons";
import { AccordionDetails, Tooltip, withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { useModel, useModelType } from "hooks/model";
import { useFeatureFlags, useIsAdmin } from "hooks/user";
import { groupBy } from "lodash";
import { hasSectionEditAccess } from "Utils/access";

const Accordion = withStyles({
  root: {
    background: 0,
    boxShadow: "none",
    margin: 0,
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: 0,
    margin: 0,
    padding: 0,
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Nav = ({ modelId }: { modelId: string }) => {
  const modelType = useModelType(modelId);
  const routes = stagesList[modelType] || [];
  const routesGroup = groupBy(routes, "groupName");

  return (
    <nav>
      {Object.keys(routesGroup).map((groupName) => {
        return (
          <RouteGroup
            key={groupName}
            modelId={modelId}
            groupName={groupName}
            routes={routesGroup[groupName]}
          />
        );
      })}
    </nav>
  );
};

type RouteGroupProps = {
  modelId: string;
  groupName: string;
  routes: ModelRoute[];
};

const RouteGroup: React.FC<RouteGroupProps> = ({
  modelId,
  groupName,
  routes,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  //to store session last visited workflow nav for model
  const [lastVisitedWorkflowNav, setLastVisitedWorkflowNav] =
    React.useState("Import");
  const location = useLocation();
  const isAdmin = useIsAdmin();
  const modelType = useModelType(modelId);
  const { data: model } = useModel(modelId);
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  React.useEffect(() => {
    const match = routes.some((route) =>
      matchPath(location.pathname, route.to(modelId).split("?")[0])
    );
    setExpanded(match);
  }, [location.pathname, modelId, routes]);

  React.useEffect(() => {
    if (!groupName) {
      setExpanded(true);
    }
  }, [groupName]);

  const toggleExpand = () => {
    if (groupName === "Workflow" && !expanded) {
      const lastVisitedRoute = routes.find(
        (route) => route.name === lastVisitedWorkflowNav
      );
      const link = lastVisitedRoute?.to(modelId);
      link && history.push(link);
    }
    setExpanded((expanded) => !expanded);
  };

  const getRouteAttributes = (route: ModelRoute) => {
    let hide = false;
    let disabled = model && model?.model_state < route.minModelStateRequired;
    let messageToShowWhenDisabled = "";

    if (
      route.name === "Model Versions" &&
      !model?.is_versioning_allowed &&
      !isAdmin
    ) {
      hide = true;
    }

    if (
      (route.name === "Test" || route.name === "Extract Data") &&
      model?.is_versioning_allowed &&
      model?.is_selected_ui_version_running
    ) {
      disabled = true;
      messageToShowWhenDisabled =
        "Testing is not possible while the selected UI version is still running. Switch to another version to test";
    }

    if (!isAdmin && modelType === "custom" && route.name === "Configure") {
      hide = true;
    }

    if (
      !isAdmin &&
      route.name === "Python Postprocessing" &&
      !model?.postprocessing_enabled
    ) {
      hide = true;
    }

    if (
      !featureFlags.data?.model_edit_history_feature_allowed &&
      route.name === "Model Changelog"
    ) {
      hide = true;
    }

    return {
      hide,
      disabled,
      messageToShowWhenDisabled,
    };
  };

  return (
    <Accordion expanded={groupName ? expanded : true} onChange={toggleExpand}>
      {groupName && hasSectionEditAccess(groupName) ? (
        <AccordionSummary
          id={`group-${groupName}`}
          classes={{
            content: "flex items-center uppercase",
          }}
        >
          {expanded ? (
            <KeyboardArrowDown className="m-2 text-lg" />
          ) : (
            <KeyboardArrowRight className="m-2 text-lg" />
          )}

          <span className="text-xs">{groupName}</span>
          {groupName === "Workflow" && (
            <span className="ml-2 rounded-md border border-solid border-violet-300 bg-violet-50 px-1.5 py-0.5 text-3xs font-bold text-violet-600">
              NEW
            </span>
          )}
        </AccordionSummary>
      ) : (
        <div />
      )}

      <AccordionDetails>
        <div style={{ flex: 1 }}>
          {routes.map((route) => {
            const { hide, disabled, messageToShowWhenDisabled } =
              getRouteAttributes(route);
            if (hide) {
              return null;
            }

            return (
              <NavLink
                id={route.name}
                key={route.name}
                className={(active) =>
                  clsx({
                    "flex cursor-pointer items-center border-0 border-l-2 border-solid border-neutral-200 py-2 px-4 text-xs text-neutral-600 hover:bg-primary hover:bg-opacity-5 hover:text-primary":
                      true,
                    "cursor-not-allowed": disabled,
                    "border-primary text-primary/100": active,
                  })
                }
                onClick={(e) => {
                  disabled && e.preventDefault();
                  if (groupName === "Workflow") {
                    setLastVisitedWorkflowNav(route.name);
                  }
                }}
                to={route.to(modelId)}
              >
                <div
                  className={clsx({
                    "flex items-center": true,
                    "opacity-50": disabled,
                  })}
                >
                  <span>{route.name}</span>
                  {messageToShowWhenDisabled && disabled && (
                    <Tooltip title={messageToShowWhenDisabled}>
                      <InfoOutlined style={{ fontSize: 18, marginLeft: 10 }} />
                    </Tooltip>
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Nav;
